import React from 'react'
import styles from "./FollowUsModal.module.scss"
import { Modal } from '@mui/material'
import CROSS from "../../assets/png/cross.png"
import ytIcon from "../../assets/svg/social-yt.svg";
import inIcon from "../../assets/svg/social-in.svg";
import instaIcon from "../../assets/svg/social-insta.svg";
import twIcon from "../../assets/svg/social-x.svg";
import fbIcon from "../../assets/svg/social-fb.svg";
import { Link } from 'react-router-dom';
const FollowUsModal = ({
    followUs,
    setFollowUs
}) => {
    const openInNewWindow = (url) => {
        window.open(url, "_blank");
    };
    return (
        <>s
            <Modal
                open={followUs}
            >
                <div className={`w-100 h-100 d-flex justify-content-center align-items-center`}>
                    <div className={styles?.followModal}>
                        <div className={styles?.followMainConatiner}>
                            <div className={styles.followheader}>
                                <h3 className={styles?.header}>Follow Us</h3>
                                {/* <button onClick={() => setDelOpen(false)}>Cancel</button> */}
                                <img onClick={() => setFollowUs(false)} src={CROSS} className={styles?.cross} alt='close_icon' />
                            </div>
                            <div className={styles?.innermodal}>
                                <div className={styles.items}>
                                    <div onClick={() =>
                                        openInNewWindow("https://www.linkedin.com/company/qx-lab-ai")
                                    } className={styles.followitem}>
                                        <div className={styles.followitemIcon}>
                                            <img src={inIcon} alt="inIcon" />
                                            <span className={styles?.social_name}>LinkedIn</span>
                                        </div>
                                        <a>Follow</a>
                                    </div>
                                    <div onClick={() =>
                                        openInNewWindow("https://x.com/QxLabAI")
                                    } className={styles.followitem}>
                                        <div className={styles.followitemIcon}>
                                            <img src={twIcon} alt="twIcon" />
                                            <span className={styles?.social_name}>Twitter</span>
                                        </div>
                                        <a>Follow</a>
                                    </div>
                                    <div onClick={() =>
                                        openInNewWindow("https://www.instagram.com/qxlabai/")
                                    } className={styles.followitem}>
                                        <div className={styles.followitemIcon}>
                                            <img src={instaIcon} alt="instaIcon" />
                                            <span className={styles?.social_name}>Instagram</span>
                                        </div>
                                        <a hr>Follow</a>
                                    </div>

                                    <div onClick={() =>
                                        openInNewWindow("https://www.facebook.com/QXLabAI/")
                                    } className={styles.followitem}>
                                        <div className={styles.followitemIcon}>
                                            <img src={fbIcon} alt="fbIcon" />
                                            <span className={styles?.social_name}>Facebook</span>
                                        </div>
                                        <a>Follow</a>
                                    </div>
                                    <div onClick={() =>
                                        openInNewWindow("https://www.youtube.com/@QXLabAI")
                                    } className={styles.followitem}>
                                        <div className={styles.followitemIcon}>
                                            <img src={ytIcon} alt="twIcon" />
                                            <span className={styles?.social_name}>Youtube</span>
                                        </div>
                                        <a>Follow</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FollowUsModal