import React, { useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveLanguage,
  setOpenTranslateModal,
} from "../../store/slices/editorSlice";
import styles from "../../layouts/homelayout/LeftSidebar.module.scss";
import collapse from "../../assets/svg/collapse-toggle.svg";
import generateCode from "../../assets/svg/generate-code.svg";
import chatHistoryIcon from "../../assets/svg/chat-history-icon.svg";
import settingImg from "../../assets/svg/newsetting.svg";
import optimizeCode from "../../assets/svg/optimize-code.svg";
import debugCode from "../../assets/svg/debug-code.svg";
import documentCode from "../../assets/svg/document-code.svg";
import convertCode from "../../assets/svg/convert-code.svg";
import testCaseCode from "../../assets/svg/test-case-code.svg";
import explainCodeIcon from "../../assets/svg/explain-code-new.svg";
import commentsIcon from "../../assets/svg/comments.svg";
import Zoom from "@mui/material/Zoom";
import { toast } from "react-toastify";
import { LightTooltip } from "../../utils";
import logoMain from "../../assets/svg/logoMain.svg"
import followUp from '../../assets/svg/follow-up.svg'
import FollowUsModal from "../modals/FollowUsModal";

const AppSidebarClosed = ({
  settingsSidebar,
  handleGenerateFeature,
  toggleHistory,
  setToggleHistory,
  setToggleSidebar,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { languages, activeLanguage } = useSelector((state) => state.language);
  const [followUs, setFollowUs] = useState(false);
  const activeTabData = activeLanguage.tabs.find((tab) => tab.isActive);
  const [activeTab, setActiveTab] = useState("Generate Code");
  const handleFollowUs = () => {
    setFollowUs(true);
  };
  const handleClick = (title, feature, hasTargetLang) => {
    // setactiveTabData(() => title);
    if (location.pathname === "/code-editor") {
      if (title === "Generate Code") {
        navigate("/");
      } else if (title === "Code Conversion") {
        if (activeTabData.content.length > 0) {
          dispatch(setOpenTranslateModal(true));
        } else {
          toast.error("No code detected");
        }
      } else {
        handleGenerateFeature(feature, hasTargetLang);

        // clickHandler();
        //
      }
    } else {
      if (title !== "Generate Code") {
        navigate("/code-editor");

        if (!activeLanguage.responseValue) {
          const bucket = localStorage.getItem("bucket");
          const parsedBucket = JSON.parse(bucket);

          if (parsedBucket === null) {
            const lastUntitledIndex = activeLanguage?.tabs?.reduce(
              (acc, tab) => {
                const match = tab.lang.name.match(/untitled(\d+)/);
                if (match) {
                  return Math.max(acc, parseInt(match[1], 10));
                }
                return acc;
              },
              0
            );

            const nextUntitledIndex = lastUntitledIndex + 1;
            dispatch(
              setActiveLanguage({
                value: "",
                responseValue: "",
                featureType: "",
                name: languages[0]?.language,
                extension: languages[0]?.extension,
                languageId: languages[0]?.languageId,
                langIcon: languages[0]?.icon,
                tabs: [
                  {
                    id: nextUntitledIndex,
                    content: "",
                    answer: "",
                    isActive: true,
                    lang: {
                      name: `untitled${1}`,
                      extension: languages[0]?.extension,
                      langName: languages[0]?.language,
                      langIcon: languages[0]?.icon,
                    },
                  },
                ],
              })
            );
          }
        }
      }
    }

    // setActiveTab(title);
  };

  return (
    <div
      style={{
        transform: !settingsSidebar ? "translateX(0)" : "translateX(-260px)",
        padding: "1.2rem 0.3rem",
        whiteSpace: "nowrap",
        // overflow: "hidden",
        maxWidth: "55px",
        // transition: "all 0.3s ease-in",
      }}
      className={styles.leftsidebar}
    >
      <div className={`${styles.leftsidebar__topClosed}`}>
        <div className={`${styles.logo_wrapper} ${styles.logo_wrapper_closed}`}>

          <div
            style={{ cursor: "pointer", marginBottom: '1.2rem' }}
          >
            <Link to="/">
              <img
                className={styles.mainLogoIma}
                src={logoMain}
                alt="main"
              />
            </Link>
          </div>
          <LightTooltip
            title="Open Sidebar"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <div
              onClick={() => setToggleSidebar(false)}
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
            >
              <img
                className={styles.collapse_icon}
                src={collapse}
                alt="collapse"
              />
            </div>
          </LightTooltip>
        </div>

        {/* <div className={styles.plus_plan}>
          <span>Upgrade to Plus</span>
        </div> */}

        <div
          className={`${styles.features_wrapper} ${styles.features_wrapper_closed}`}
        >
          <div className={styles.button_wrapper}>
            {!location.pathname.includes("/home") && (
              <LightTooltip
                title="Generate Code"
                placement="right-start"
                TransitionComponent={Zoom}
              >
                <div
                  className={styles.button_wrapper_item}
                  style={{ opacity: activeTab === "Generate Code" ? 1 : 0.7 }}
                  onClick={() => handleClick("Generate Code")}
                >
                  <img src={generateCode} alt="Generate Code" />
                </div>
              </LightTooltip>
            )}

            <LightTooltip
              title="Optimize Code"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Optimize Code" ? 1 : 0.7 }}
                onClick={() =>
                  handleClick("Optimize Code", "OPTIMIZE_CODE", true)
                }
              >
                <img src={optimizeCode} alt="Optimize Code" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Debug Code"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Debug Code" ? 1 : 0.7 }}
                onClick={() => handleClick("Debug Code", "DEBUG_CODE", true)}
              >
                <img src={debugCode} alt="Debug Code" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Document Code"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Document Code" ? 1 : 0.7 }}
                onClick={() =>
                  handleClick("Document Code", "DOCUMENT_CODE", true)
                }
              >
                <img src={documentCode} alt="Document Code" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Write Test Cases"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Write Test Cases" ? 1 : 0.7 }}
                onClick={() =>
                  handleClick("Write Test Cases", "GENERATE_TEST_CASES", true)
                }
              >
                <img src={testCaseCode} alt="Write Test Cases" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Code Conversion"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Code Conversion" ? 1 : 0.7 }}
                onClick={() => handleClick("Code Conversion")}
              >
                <img src={convertCode} alt="Code Conversion" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Explain Code"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{ opacity: activeTab === "Explain Code" ? 1 : 0.7 }}
                onClick={() =>
                  handleClick("Explain Code", "EXPLAIN_CODE", true)
                }
              >
                <img src={explainCodeIcon} alt="Explain Code" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Add Comments To Code"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                style={{
                  opacity: activeTab === "Add Comments To Code" ? 1 : 0.7,
                }}
                onClick={() =>
                  handleClick(
                    "Add Comments To Code",
                    "ADD_COMMENTS_TO_CODE",
                    true
                  )
                }
              >
                <img src={commentsIcon} alt="Add Comments To Code" />
              </div>
            </LightTooltip>
          </div>
        </div>
      </div>

      <div
        className={`${styles.leftsidebar__bottom} ${styles.leftsidebar__bottomClosed}`}
      >
        <div className={`${styles.chat_history} ${styles.chat_history_closed}`}>
          <LightTooltip
            title="Open Chat History"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <div
              className={`${styles.chat_history_header} `}
              onClick={() => {
                setToggleSidebar(false);
                setToggleHistory(!toggleHistory);
              }}
            >
              <img src={chatHistoryIcon} alt="Chat history" />
            </div>
          </LightTooltip>
        </div>
        <div className={styles?.bottom_closed}>
          <div
            className={`${styles.settings_wrapper} ${styles.settings_wrapper_closed}`}
          >
            <LightTooltip
              title="Settings"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={`${styles.settings_wrapper_item}`}
                onClick={() => navigate("setting/profile")}
              >
                <img src={settingImg} alt="setting" />
              </div>
            </LightTooltip>


          </div>
          <div
            className={`${styles.settings_wrapper} ${styles.settings_wrapper_closed}`}
          >
            <LightTooltip
              title="Follow Us"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.settings_wrapper_item}
                onClick={handleFollowUs}
              >
                <img src={followUp} alt="follow-us" />
              </div>
            </LightTooltip>
          </div>
        </div>


      </div>
      {followUs && (
        <FollowUsModal followUs={followUs} setFollowUs={setFollowUs} />
      )}
    </div>
  );
};

export default AppSidebarClosed;
