//auth
export const LOGIN = "/user/login";
export const LOGOUT = "/api/auth/logout";
export const REGISTER = "/user/register";
export const VERIFY_ACCOUNT = "/user/verify-account";
export const RESEND_OTP = "/user/resend-verification-code";
export const USER_FORGOT_PASSWORD = "/user/forgot-password";
export const USER_RESET_PASSWORD = "/user/reset-password";
export const USER_SOCIAL_LOGIN = "/user/social";
export const LINKEDIN_LOGIN = "/user/social/linkedin/user-info";
export const REFRESH_TOKEN = "/user/refresh-token"
export const FEEDBACK = "/app/feedback"
export const USER_ONBOARD_DETAILS = "/user/profile"

///profile endpoints
export const PROFILE = "/user/profile"
export const IMAGE_PROFILE = "/user/profile/image"
export const PROFILE_SEND_VERIFICATION_CODE = "/user/profile/send-verification-code"
export const PROFILE_CHANGE_PASSWORD = "/user/profile/change-password"
export const PROFILE_DELETE_ACCOUNT = "/user/profile/delete-account"
