import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import styles from "./Input.module.scss";
import ErrorText from "../errortext/ErrorText";
// import TextError from '../shared/TextError'
// import { TextField } from '@mui/material'
import emailImage from '../../../assets/svg/emailsvg.svg'

const Input = (props) => {
  const { label, name, icon,setShowError, placeholder, isRequired, type, img, ...rest } = props;
  const { errors, touched ,setFieldTouched } = useFormikContext();

  const hasError = touched[name] && errors[name];

  const handleInputChange =(e)=>{
    setShowError(false)
  }



  return (
    <div className={styles.inputControl}>
      <Field name={name}>
        {({ field }) => {
          return (
            <input
              {...field}
              {...rest}
              id={name}
              type={type || "text"}
              className={`${styles.inputField} ${hasError ? styles.error : ""}`}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange(e);
              }}             
            />
          );
        }}
      </Field>
      <img src={emailImage} alt="emailImage" />

      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default Input;
