

import { useDispatch, useSelector } from "react-redux";
import Chat from "../../../components/conversation/Chat";
import styles from "../../../components/conversation/Conversation.module.scss";
import ResponseButtons from "../../../components/conversation/ResponseButtons";
import TextInput from "../../../components/conversation/TextInput";
import { useEffect, useState } from "react";
import { setConversationId, setLastAnswerId, setLastChatId, setLoading, storeChats, loading } from "../../../store/slices/conversationSlice";
import {
    askQuery,
    fetchData,
    regenerateQuery,
} from "../../../services/coversationService";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { addNewDataToToday, archiveChat, getHistoryById } from "../../../store/slices/historySlice";
import Intro from "../../../components/conversation/Intro";
import ScrollToBottom from 'react-scroll-to-bottom';
import { streamrequest } from "../../../services/streamRequest";
import { toast } from "react-toastify";
import { BiArchiveIn } from "react-icons/bi";
import { idea } from "react-syntax-highlighter/dist/cjs/styles/hljs";
const ArchiveDetails = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { historyid } = useSelector((state) => state?.history)
    const conversation = useSelector((state) => state.conversation)
    const [question, setQuestion] = useState("")
    const [array, setArray] = useState(conversation?.chats)
    const location = useLocation();

    useEffect(() => {
        setArray(conversation?.chats)
    }, [conversation?.chats]);
    useEffect(() => {
        if (!id) {
            dispatch(storeChats([]))
        }
    }, [id])
    useEffect(() => {
        if (historyid && historyid.chats && historyid.chats.length !== 0) {
            dispatch(storeChats(historyid.chats));
        }
    }, [historyid?.chats])
    useEffect(() => {
        if (id) {
            dispatch(getHistoryById(id));
        }
    }, [id])

    const [currentAnswerIndexMap, setCurrentAnswerIndexMap] = useState({});

    const handleIncrement = (itemIndex) => {
        setCurrentAnswerIndexMap((prevMap) => {
            const prevIndex = prevMap[itemIndex] || 0;
            const newIndex = Math.min(
                prevIndex + 1,
                array[itemIndex]?.answers.length - 1
            );
            return { ...prevMap, [itemIndex]: newIndex };
        });
    };

    const handleDecrement = (itemIndex) => {
        setCurrentAnswerIndexMap((prevMap) => {
            const prevIndex = prevMap[itemIndex] || 0;
            const newIndex = Math.max(prevIndex - 1, 0);
            return { ...prevMap, [itemIndex]: newIndex };
        });
    };

    const handleAskQuery = async (e) => {
        if (conversation.loading) {
            return
        }
        e.preventDefault();
        const trimmedQuestion = question.trim();
        if (question.length > 0 && trimmedQuestion && question.length < 2000) {
            dispatch(setLoading(true))
            const newArray = array || [];
            setArray([
                ...newArray,
                {
                    query: question,
                    answers: []
                }
            ]);
            try {
                setQuestion('');
                const payload = {
                    "data": {
                        "conversationId": conversation?.conversationId || id || "",
                        "query": question,
                        "style": "Balanced",
                        "platform": "WEB"
                    }
                };
                const data = await askQuery(payload);
                if (data.response) {
                    const newData = data.response.chats[0]
                    if (!conversation.conversationId && !id) {
                        navigate(`/conversation/${data.response.conversationId}`)
                        dispatch(setConversationId(data.response.conversationId))
                        const adddata = {
                            conversationId: data.response.conversationId,
                            conversation: data.response.chats[0]?.query,
                            createdAt: data.response.chats[0]?.createdAt,
                            style: data.response.chats[0].style,
                        };
                        dispatch(setLastAnswerId(data.response.chats[0].answers[0].answerId))
                        dispatch(setLastChatId(data.response.chats[0].chatId))
                        // setArray([newData])
                        dispatch(addNewDataToToday(adddata))
                        dispatch(storeChats([newData]))
                    } else {
                        dispatch(setLastAnswerId(data.response.chats[0].answers[0].answerId))
                        dispatch(setLastChatId(data.response.chats[0].chatId))
                        // setArray([...array, newData])
                        dispatch(storeChats([...array, newData]))
                    }
                    dispatch(setLoading(false))
                    const newArray = [...array];
                    for (let i = newArray.length - 1; i >= 0; i--) {
                        if (newArray[i].answers.length === 0) {
                            newArray.splice(i, 1);
                            break;
                        }
                    }
                    setArray(newArray);
                }
            } catch (error) {
                const newArray = [...array];
                for (let i = newArray.length - 1; i >= 0; i--) {
                    if (newArray[i].answers.length === 0) {
                        newArray.splice(i, 1);
                        break;
                    }
                }
                setArray(newArray);
                console.error("Error fetching data:", error);
                dispatch(setLoading(false))
            }
        } else {
            toast.error("Question length should not be less than 1 and not more than 2000 words")
        }
    };

    const handleRegenerateQuery = async () => {
        if (conversation.loading) {
            return
        }
        dispatch(setLoading(true))
        const lastElementIndex = array.length - 1;
        const lastElement = array[lastElementIndex];
        let newArray = [...array];
        newArray[newArray.length - 1] = {
            query: lastElement.query,
            answers: [],
            style: lastElement.themeStye,
        };
        setArray(newArray);
        try {
            const payload = {
                "data": {
                    "conversationId": conversation.conversationId || id,
                    "style": "Balanced"
                }
            }
            const data = await regenerateQuery(payload);
            if (data.response) {
                if (data?.response.chats[0]?.answers[0]) {
                    const newAnswer = data?.response.chats[0]?.answers[0]
                    const updatedArray = array.map((item, index) => {
                        if (index === lastElementIndex) {
                            const existingAnswers = item.answers || [];
                            const updatedAnswers = [...existingAnswers, newAnswer];
                            setCurrentAnswerIndexMap((prevMap) => ({
                                ...prevMap,
                                [lastElementIndex]: updatedAnswers.length - 1,
                            }));
                            return {
                                ...item,
                                answers: updatedAnswers,
                            };
                        }
                        return { ...item };
                    });
                    setArray(updatedArray)
                }
                dispatch(setLoading(false))
            }

        } catch (error) {
            const newArray = [...array];
            for (let i = newArray.length - 1; i >= 0; i--) {
                if (newArray[i].answers.length === 0) {
                    newArray.splice(i, 1);
                    break;
                }
            }
            setArray(newArray);
            console.error("Error fetching data:", error);
            dispatch(setLoading(false))
        }
    }

    //--------------------------------------Stream_Response----------------------
    // const handleAskQueryStream = async (e) => {
    //   e.preventDefault();
    //   const newArray = array || [];
    //   setArray([
    //     ...newArray,
    //     {
    //       query: question,
    //       answers: []
    //     }
    //   ]);
    //   try {
    //     const dataVal = {
    //       data: {
    //         conversationId: conversation.conversationId || "",
    //         query: question,
    //         style: "Balanced",
    //         platform: "WEB",
    //       },
    //     };
    //     let reader = await streamrequest(
    //       "POST",
    //       `${process.env.REACT_APP_CODE_BASE_URL
    //       }/stream/query?requestId=WEB_${new Date().getTime()}`,
    //       dataVal,
    //     );
    //     await readResponseBody(reader?.getReader());
    //   } catch (error) {
    //     alert(error)
    //   }
    // }
    // const updateAnswers = (newTextData) => {
    //   setArray((prevData) => {
    //     return prevData.map((item, index) => {
    //       if (index === prevData.length - 1) {
    //         const existingAnswers = item.answers || [];
    //         let combinedAnswer = newTextData.trim();
    //         if (existingAnswers.length > 0) {
    //           existingAnswers.forEach((answerObj) => {
    //             combinedAnswer = `${answerObj.answer.trim()} ${combinedAnswer}`;
    //           });
    //         }
    //         const updatedAnswers = [
    //           { style: "Balanced", answer: combinedAnswer.trim() },
    //         ];
    //         return {
    //           ...item,
    //           answers: updatedAnswers,
    //         };
    //       }
    //       return item;
    //     });
    //   });
    // };
    // const fetchRegenerateData = async () => {
    //   const lastElementIndex = array.length - 1;
    //   const lastElement = array[lastElementIndex];
    //   let newArray = [...array];
    //   newArray[newArray.length - 1] = {
    //     query: lastElement.query,
    //     answers: [],
    //     style: "Balanced",
    //   };
    //   setArray(newArray);
    //   try {
    //     const dataVal = {
    //       data: {
    //         conversationId: conversation.conversationId || id,
    //         style: "Balanced",
    //       },
    //     };

    //     const reader = await streamrequest(
    //       "POST",
    //       `${process.env.REACT_APP_CODE_BASE_URL
    //       }/stream/regenerate?requestId=WEB_${new Date().getTime()}`,
    //       dataVal,
    //     );

    //     await readRegenerateResponseBody(reader?.getReader());
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // let combinedAnswer = "";
    // const updateLastAnswers = (newAnswer) => {
    //   if (newAnswer) {
    //     combinedAnswer += newAnswer;
    //     const lastElementIndex = array.length - 1;
    //     const updatedArray = array.map((item, index) => {
    //       if (index === lastElementIndex) {
    //         const existingAnswers = item.answers || [];
    //         const updatedAnswers = [
    //           ...existingAnswers,
    //           { style: "Balanced", answer: combinedAnswer.trim() },
    //         ];

    //         return {
    //           ...item,
    //           answers: updatedAnswers,
    //         };
    //       }
    //       return { ...item };
    //     });
    //     setArray(updatedArray);
    //   }
    // };
    //--------------------------------------End----------------------

    return (
        <>
            <div className={styles.main}>
                {location.pathname === `/conversation` && array.length < 1 && (
                    <Intro array={array} setQuestion={setQuestion} />
                )}
                {array?.length > 0 && (<Chat array={array}
                    handleRegenerateQuery={handleRegenerateQuery}
                    currentAnswerIndexMap={currentAnswerIndexMap}
                    setCurrentAnswerIndexMap={setCurrentAnswerIndexMap}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    conversation={conversation}
                    archive={true}
                />

                )}
                <TextInput
                    array={array}
                    question={question}
                    setQuestion={setQuestion}
                    handleSubmit={handleAskQuery}
                    archive={true}
                    id={id}
                />

            </div>

        </>
    )

}

export default ArchiveDetails;
