import { Modal } from '@mui/material'
import React from 'react'
import styles from "../../pages/settings/chathistory/Chathistory.module.scss"
import CROSS from "../../assets/png/cross.png"
const LogoutModal = ({
    logout,
    setLogout,
    handleLogout,
    handleLogoutClear
}) => {
    return (
        <>
            <Modal
                open={logout}
            >
                <div className='w-100 h-100  d-flex justify-content-center align-items-center'>
                    <div className={styles?.delModal}>
                        <div className={styles?.delheader}>
                            <h3>Logout</h3>
                            <span></span>
                        </div>
                        <div className={styles?.innermodal}>
                            <h5>Are you sure you want to Logout?</h5>
                            <div className={styles?.btngrp}>
                                <button className={styles?.delbtn2} onClick={() => setLogout(false)}>Cancel</button>
                                <button className={styles?.delbtn} onClick={handleLogoutClear} >Logout</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LogoutModal