import React, { useEffect, useRef, useState } from "react";
import styles from "../../layouts/loginlayout/LoginLayout.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { emailRegex, numberRegex } from "../../utils/regex";
import LoginLayout from "../../layouts/loginlayout/LoginLayout";
import * as Yup from "yup";
import {
    combineClasses,
    onlyNumbersInput,
    requiredMessage,
} from "../../utils/index";
import Button from "../../shared/button/Button";
import { PATH } from "../../utils/constant";
import { ReactComponent as BackIcon } from "../../assets/svg/arrow-left.svg";
import { userResendOtp, userVerify } from "../../services/userService";
import { RESEND_OTP, VERIFY_ACCOUNT } from "../../services/apiEndpoints";
import { toast } from "react-toastify";
import erronIcon from '../../assets/svg/error-icon.svg'

const otpLength = 6;

const OtpVerification = () => {
    const [otp, setOtp] = useState(Array.from({ length: otpLength }));
    const [resendDisabled, setResendDisabled] = useState(true);
    const [countdown, setCountdown] = useState(60);
    const navigate = useNavigate();
    const location = useLocation();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const refArray = Array.from({ length: otpLength }, () =>
        React.createRef(null)
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (otp.some(element => element === undefined)) {
            // toast.error("Otp is required");
            setErrorMessage('Please enter 6 digit Otp')
            setShowError(true)
            return;
        }

        const otpString = otp.join("");
        if (otpString.length < 6) {
            setErrorMessage('Please enter 6 digit Otp')
            setShowError(true);
            return
        };

        let user_id = localStorage.getItem("userId");
        let payload = {
            verificationCode: otpString,
        };
        try {
            let res = await userVerify(payload, {
                "x-user-id": user_id
            });
            if (res?.isVerified) {
                if (res?.tokens) {
                    localStorage.setItem(
                        "access",
                        res?.tokens?.accessToken
                    );
                    localStorage.setItem(
                        "refresh",
                        res?.tokens?.refreshToken
                    );

                }
                if (res?.userDetails) {
                    localStorage.setItem("email", res.userDetails.emailId);
                    localStorage.setItem(
                        "firstName",
                        res.userDetails.firstName
                    );
                    localStorage.setItem(
                        "lastName",
                        res.userDetails.lastName
                    );
                    localStorage.setItem("status", res.userDetails.status);
                    localStorage.setItem("userId", res.userDetails.userId);
                    localStorage.setItem(
                        "profilePhoto",
                        res.userDetails.profilePhoto
                    );
                }
                if (sessionStorage.getItem('isNewUser')) {
					// set newUser true only when the isNewUser
					// and otp verification is completed
					localStorage.setItem('newUser', true);
				}
                navigate("/home");
            } else {
                toast.error("Something went wrong!");
            }
        }
        catch (err) {
            toast.error(err?.message);
            setErrorMessage(err?.message)
            setShowError(true)
        }
    };

    const handleInputChange = (e) => {
        setShowError(false)

        const { value, name } = e.target;
        if (value.length <= 1) {
            otp[name] = value;
            setOtp([...otp]);
            if (value && name < otpLength - 1) {
                refArray[+name + 1].current.focus();
            }
            // else if(!value && !otp[name] && name > 0) {
            //     refArray[+name-1].current.focus()
            // }
        }
    };
    async function handleSendOtp() {
        startCountdown();
        setResendDisabled(true);
        let user_id = localStorage.getItem("userId");
        let payload = {
            emailId: localStorage.getItem("email"),
        };

        try {
            let res = await userResendOtp(payload, {
                "x-user-id": user_id
            });
            if (res?.userDetails) {
                toast.success("OTP sent successfully!");
            } else {
                toast?.error("Something went wrong!")
                setResendDisabled(false);
            }
        } catch (err) {
            toast.error(err?.message);
            setResendDisabled(false);
        }
    }

    const startCountdown = () => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer);
            setResendDisabled(false);
            setCountdown(60);
        }, 60000);
    };
    useEffect(() => {
        refArray[0].current.focus();
        return () => {
            clearInterval(startCountdown);
        };
    }, []);

    useEffect(() => {
        startCountdown()
        setResendDisabled(true);
        return () => {
            clearInterval(startCountdown)
        }
    }, [])

    return (
        <LoginLayout>

            <div className={styles.login_sec}>

                <div className={styles.mainDiv}>
                    {/* <BackIcon onClick={() => navigate(-1)} className={styles.backIcon} /> */}
                    <p className={`${styles.heading} text-center`}>Verify OTP</p>
                    <p className={`${styles.subHeading}`}>A 6-digit OTP has been sent to</p>
                    <p className={`${styles.subHeading} mt-0`}> {localStorage.getItem("email")}</p>
                    {
                        showError && <div className="error-message-div">
                            <span>
                                <img src={erronIcon} />
                                {errorMessage}</span>
                        </div>
                    }
                    <form
                        className={styles.form}
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.inputsContainer}>
                            {Array.from({ length: otpLength }).map((_, i) => {
                                return (
                                    <input
                                        key={i}
                                        name={i}
                                        ref={refArray[i]}
                                        type="number"
                                        value={otp[i]}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => {
                                            onlyNumbersInput(e);
                                            if (e.key === "Backspace" && !otp[i] && i > 0) {
                                                refArray[+i - 1].current.focus();
                                            }
                                        }}
                                        onPaste={(e) => {
                                            const pastedText = e.clipboardData
                                                .getData("Text")
                                                .slice(0, otpLength);
                                            setOtp(pastedText.split(""));
                                            refArray[pastedText.length - 1].current.focus();
                                        }}
                                    />
                                );
                            })}
                        </div>
                        <Button type="submit" className={styles.button}>
                            Verify OTP
                        </Button>
                        {resendDisabled ? (
                            <p className={styles.bottomText}>
                                <span>
                                    {`Resend OTP in ${Math.floor(countdown / 60)}:${("0" + (countdown % 60)).slice(
                                        -2
                                    )}`}
                                </span>
                            </p>
                        ) : (
                            <p className={styles.bottomText}>
                                Didn't get the OTP?{" "}
                                <span
                                    className="link-text"
                                    disabled={resendDisabled}
                                    onClick={() => {
                                        handleSendOtp();
                                    }}
                                >
                                    Click here to Resend 
                                </span>
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </LoginLayout>
    );
};

export default OtpVerification;