import axios from "axios";
const publicRequest = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
})
const requestHandler = (request) => {
    request.headers['x-api-key'] = process.env.REACT_APP_API_KEY;
    if (request.config && request.config.headers && request.config.headers['x-user-id']) {
        request.headers['x-user-id'] = request.config.headers['x-user-id'];
    }
    request.params = {
        ...request.params,
        requestId: `WEB_${new Date().getTime()}`
    };
    return request
}
publicRequest.interceptors.request.use(
    requestHandler
)
export const post = (endPoint, data, config = {}) => {
    return publicRequest.post(endPoint, {
        data: data
    }, config)
}
// export const get = (endPoint) => {
//     return publicRequest.get(endPoint)
// }

// export const put = (endPoint, id, data) => {
//     return publicRequest.put(`${endPoint}/${id}`, data)
// }
// export const deleteRequest = (endPoint, id) => {
//     return publicRequest.delete(`${endPoint}/${id}`)
// }
export default publicRequest





