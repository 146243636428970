import React, { useEffect, useRef, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight, materialLight, solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import styles from "./Conversation.module.scss";
import { useSelector } from 'react-redux';
import ScrollToBottom from 'react-scroll-to-bottom';
import { FaArrowDown } from "react-icons/fa";
import ResponseButtons from './ResponseButtons';
import { MdOutlineDone } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IoCopyOutline } from "react-icons/io5";
import profile from "../../assets/svg/user.svg"
const Chat = ({ array, handleRegenerateQuery, currentAnswerIndexMap,
  setCurrentAnswerIndexMap,
  archive,
  handleDecrement,
  handleIncrement, conversation }) => {
  const { loading } = useSelector((state) => state?.history)
  const scrollRef = useRef()
  const [copiedCodeMap, setCopiedCodeMap] = useState({});
  const [imageUrl, setImageUrl] = useState(
    localStorage.getItem("profilePhoto")
  );
  const handleCopyCode = (response, index) => {
    setCopiedCodeMap((prevCopiedCodeMap) => ({
      ...prevCopiedCodeMap,
      [`${response}_${index}`]: true,
    }));
    setTimeout(() => {
      setCopiedCodeMap((prevCopiedCodeMap) => ({
        ...prevCopiedCodeMap,
        [`${response}_${index}`]: false,
      }));
    }, 2500);
  };


  const TypingData = (item) => {
    return (
      <div className={styles.message}>
        {item?.split("```").map((part, index) => {
          if (index === 0) {
            return <div key={index} style={{ display: "flex", gap: "10px", width: "100%" }}> <div className={styles.answerImage}>
              <img src="https://res.cloudinary.com/db4uhziiy/image/upload/v1709710717/logoBlack_jgj2jg.png" alt="chatImg" />
            </div><p key={index}>{part}</p></div>;
          } else if (index % 2 === 0) {
            return <p key={index}>{part}</p>
          } else {
            const [language, ...codeLines] = part.trim().split("\n");
            const code = codeLines.join("\n");
            return (
              <div key={index} className={styles.typing}>
                <div className={styles.codeheader}>
                  <div className={styles.headerItems}>
                    <p className={styles.language}>{language}</p>
                    <CopyToClipboard
                      text={code}
                      onCopy={() => handleCopyCode(item._id, index)}
                    >
                      {copiedCodeMap[`${item._id}_${index}`] ? (
                        <i><MdOutlineDone size={30} /></i>
                      ) : (
                        <i><IoCopyOutline size={30} /></i>
                      )}
                    </CopyToClipboard>
                  </div>
                  <SyntaxHighlighter language={language} style={oneLight} customStyle={{ borderRadius: "30px" }}>
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  // const handleNextAnswer = (index) => {
  //   setCurrentAnswersIndex(prevState => {
  //     const updatedIndexes = [...prevState];
  //     if (updatedIndexes[index] < array[index].answers.length - 1) {
  //       updatedIndexes[index] += 1;
  //     }
  //     return updatedIndexes;
  //   });
  // };

  // const handlePrevAnswer = (index) => {
  //   setCurrentAnswersIndex(prevState => {
  //     const updatedIndexes = [...prevState];
  //     if (updatedIndexes[index] > 0) {
  //       updatedIndexes[index] -= 1;
  //     }
  //     return updatedIndexes;
  //   });
  // };

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [array]);


  const renderQuestion = (item) => {
    return (
      <div className={styles.questionmessage}>
        {item?.split("```").map((part, index) => {
          if (index % 2 === 0) {
            return <p key={index}>{part}</p>;
          } else {
            const code = part.trim();
            return (
              <div key={index}>
                <div>
                  <SyntaxHighlighter style={oneLight} customStyle={{ borderRadius: "30px" }}>
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <ScrollToBottom className={`${styles.chatContainer}`} followButtonClassName='follow-button-scroll'>
      {
        array?.map((item, index) => {
          // eslint-disable-next-line
          // let isLikedLastItem = array[array.length - 1]?.answers[]?.reaction?.isLiked;
          const currentAnswerIndex = currentAnswerIndexMap[index] || 0;
          let isLikedLastAnswerItem = array[array.length - 1]?.answers[currentAnswerIndex]?.reaction?.isLiked;
          const lastArrayAnswerIndex = array[array.length - 1]?.answers[currentAnswerIndex]?.answerId
          return (
						<div key={index}>
							<div key={index} className={styles.chatMain}>
								<div className={styles.question}>
									{renderQuestion(item.query)}
									{imageUrl ? (
										<div className={styles.questionImage}>
											<img src={imageUrl} alt="user" />
										</div>
									) : (
										<div className={styles.questionImage}>
											<img src={profile} alt="user" />
										</div>
									)}
								</div>
								<div className={styles.answer}>
									{item?.answers?.length > 0 ? (
										<div className={styles.answerText}>
											{/* {TypingData(item?.answers[currentAnswersIndex[index]]?.answer)} */}
											{TypingData(item.answers[currentAnswerIndex]?.answer)}
										</div>
									) : (
										<div className={styles.answerLoader}>
											<img
												className={`${styles.answerImage}`}
												src="https://res.cloudinary.com/db4uhziiy/image/upload/v1709710717/logoBlack_jgj2jg.png"
												alt="chatImg"
											/>
											<span className={styles.loader}></span>
										</div>
									)}
								</div>
								{!archive && (
									<ResponseButtons
										isLikedLastItem={isLikedLastAnswerItem}
										currentAnswerIndex={currentAnswerIndex}
                    lastArrayAnswerIndex={lastArrayAnswerIndex}
										handleRegenerateQuery={handleRegenerateQuery}
										handlePrevAnswer={() => handleDecrement(index)}
										handleNextAnswer={() => handleIncrement(index)}
										array={array}
										item={item}
										index={index}
									/>
								)}
							</div>
						</div>
					);
        }
        )}
    </ScrollToBottom>
  );
};

export default Chat;
