import React, { useState, useEffect } from "react";
import styles from "./ConvertCode.module.scss";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  setOpenTranslateModal,
  setTargetedLanguage,
} from "../../store/slices/editorSlice";
import { toast } from "react-toastify";

const ConvertCodeModal = ({ open, handleClose, handleGenerateFeature }) => {
  let dispatch = useDispatch();

  const { languages, activeLanguage, targetedLanguage } = useSelector(
    (state) => state.language
  );
  const [languageOptions, setLanguageOptions] = useState(null);

  // let matchLang = activeLanguage?.tabs.find(tab => tab?.isActive).lang?.extension;
  // let matchedLanguage = languages.find(language => language?.extension === matchLang);
  let matchLang = activeLanguage?.tabs.find((tab) => tab?.isActive)?.lang
    ?.langName;
  let matchedLanguage = languages?.find(
    (language) => language?.language === matchLang
  );

  const handleLanguageSelect = (e) => {
    const targetedLang = languages.find(
      (language) => language.languageId === e
    );
    dispatch(setTargetedLanguage(targetedLang));
  };

  const handleClickNext = () => {
    handleGenerateFeature("CONVERT_CODE", false);
    dispatch(setOpenTranslateModal(false));
  };

  const handleCancel = () => {
    dispatch(setTargetedLanguage(null));
    handleClose();
  };

  useEffect(()=>{
    dispatch(setTargetedLanguage(null));
  },[])

  return (
    <>
      {matchedLanguage ? (
        <Modal
          open={open}
          //   onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={styles.modal_content}>
            <div className={styles.modal_content_top}>
              <p className={styles.heading}>Convert Code</p>

              <span className={styles.close_modal} onClick={handleCancel}>
                <IoMdClose size={28} />
              </span>
            </div>

            <div className={styles.modal_content_bottom}>
              <div className={styles.source_language}>
                <p className={styles.source_language_label}> From: </p>
                <p className={styles.source_language_wrapper}>
                  <span className={styles.icon_wrapper}>
                    <img src={matchedLanguage?.icon} alt="" />
                  </span>
                  <span className={styles.lang_name}>
                    {matchedLanguage?.language}
                  </span>
                </p>
              </div>

              <div className={styles.source_language}>
                <p className={styles.source_language_label}> To: </p>
                {/* <Select options={languageOptions} /> */}
                {/* <select
                    style={{ width: "100%", padding: "0.7rem 1rem" }}
                    className={styles.source_language_wrapper}
                    onChange={(e) => {
                      handleLanguageSelect(e);
                    }}
                  >
                    {languages &&
                      languages
                        ?.filter(
                          (ele) => ele?.language !== matchedLanguage?.language
                        )
                        .map((language) => (
                          <option
                            key={language?.language}
                            value={language?.languageId}
                          >
                            <img src={language.icon} alt="language-icon" />
                            {language?.language}
                          </option>
                        ))}
                  </select> */}
                <Select
                  isSearchable={false}
                  options={languages
                    ?.filter(
                      (ele) => ele?.language !== matchedLanguage?.language
                    )
                    .map((language) => ({
                      value: language.languageId,
                      label: (
                        <div
                          className={styles.ksmdk}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={language.icon}
                            alt="language-icon"
                            style={{ marginRight: 8, width: 20 }}
                          />
                          {language.language}
                        </div>
                      ),
                    }))}
                  onChange={(selectedOption) =>
                    handleLanguageSelect(selectedOption.value)
                  }
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      padding: "8px",
                      borderRadius: "20px",
                      borderColor: "#d9d9d9",
                      boxShadow: "none",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? "#D9D9D9" : "white",
                      color: state.isSelected ? "black" : "grey",
                      "&:hover": {
                        // color: "white",
                        backgroundColor: "#D9D9D9",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }),
                  }}
                />
              </div>

              <div className={styles.button_wrapper}>
                <button onClick={handleCancel}>Cancel</button>

                <button
                  style={{
                    pointerEvents: !targetedLanguage ? "none" : "auto",
                    opacity: !targetedLanguage ? 0.5 : 1,
                  }}
                  onClick={handleClickNext}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default ConvertCodeModal;
