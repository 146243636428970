import { lazy } from "react";
// import { ReactComponent as HomeIcon } from "assets/svg/home-icon.svg"
// import { ReactComponent as UsersIcon } from "assets/svg/users-icon.svg"
// import { ReactComponent as MastersIcon } from "assets/svg/masters-icon.svg"
// import { ReactComponent as PendingByApproverIcon } from "assets/svg/pending-by-approver.svg"
// import { ReactComponent as ReceiptWalletIcon } from "assets/svg/receipt-wallet.svg"
// import { ReactComponent as RolesIcon } from "assets/svg/roles-icon.svg"
// import { ReactComponent as PermissionsIcon } from "assets/svg/permissions-icon.svg"
// import { ReactComponent as ModuleIcon } from "assets/svg/module-icon.svg"
// import { ReactComponent as AccountsIcon } from "assets/svg/accounts.svg"

import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import VerifyPassword from "../pages/otpverification/VerifyPassword";
import OtpVerification from "../pages/otpverification/OtpVerification";
import ForgotPassword from "../pages/otpverification/ForgotPassword";
import Keepintouch from "../pages/keepintouch/Keepintouch";
import Conversation from "../components/conversation/Conversation";
import Linkedin from "../pages/linkedin/Linkedin";
import { Outlet } from "react-router-dom";
import PreviewChat from "../pages/previewChat/PreviewChat";

import SharedLinks from "../pages/settings/sharedLinks/SharedLinks";
import DownloadVsCode from "../pages/downloadvscode/DownloadVsCode";
import ArchiveDetails from "../pages/settings/archivedetails/ArchiveDetails";
import SettingsOutlet from "../components/SettingsOutlet";

const Home = lazy(() => import("../pages/home/Home"));
const Profile = lazy(() => import("../pages/settings/profile/Profile"));
const Account = lazy(() => import("../pages/settings/account/Account"));
const Chathistory = lazy(() =>
  import("../pages/settings/chathistory/Chathistory")
);
const Feedback = lazy(() => import("../pages/settings/feedback/Feedback"));
const CodeEditor = lazy(() => import("../components/CodeEditor/index"));
const MobileSettings = lazy(() => import("../pages/mobileSettings/MobileSettings"));

export const PATH = {
  publicRoutes: {
    LOGIN: {
      path: "/Login",
      element: Login,
    },
    REGISTER: {
      path: "/register",
      element: Register,
    },
    LINKEDIN: {
      path: "/linkedin",
      element: Linkedin,
    },
    FORGOT_PASSWORD: {
      path: "/forgot-password",
      element: ForgotPassword,
    },
    OTP_VERIFICATION: {
      path: "/otp-verification",
      element: OtpVerification,
    },
    VERIFY_PASSWORD: {
      path: "/verify-password",
      element: VerifyPassword,
    },
    KEEPINTOUCH: {
      path: "/keep-in-touch",
      element: Keepintouch,
    },
    PREVIEW_CHAT: {
      path: "/share/:id",
      element: PreviewChat,
    },

  },
  privateRoutes: {
    HOME: {
      path: "/home",
      element: <Home />,
      pageName: "Home",
      sidebar: {
        show: true,
        // icon: HomeIcon,
      },
    },

    SETTINGS: {
      path: "/setting",
      element: <SettingsOutlet />,
      pageName: "setting",
      children: [
        {
          CHATHISTORY: {
            path: "chathistory",
            element: <Chathistory />,
            pageName: "Chathistory",
          },
          // MYFILES: {
          //   path: "file",
          //   element: <MyFiles />,
          //   pageName: "My Files",
          // },
          DOWNLOADVSCODE: {
            path: "download-vscode",
            element: <DownloadVsCode />,
            pageName: "Download Vs Code",
          },
          Shared: {
            path: "shared-links",
            element: <SharedLinks />,
            pageName: "SharedLinks",
          },
          ARCHIVE_CHAT: {
            path: "archive-details/:id",
            element: <ArchiveDetails />,
          },
          PROFILE: {
            path: "profile",
            element: <Profile />,
            pageName: "Profile",
            sidebar: {
              show: true,
            },
          },

          ACCOUNT: {
            path: "account",
            element: <Account />,
            pageName: "Account",
            sidebar: {
              show: true,
            },
          },
          // BILLING: {
          //   path: "billing",
          //   element: <Account />,
          //   pageName: "Billing",
          //   sidebar: {
          //     show: true,
          //   },
          // },
          CHATHISTORY: {
            path: "chat-history",
            element: <Chathistory />,
            pageName: "ChatHistory",
            sidebar: {
              show: true,
            },
          },
          FEEDBACK: {
            path: "feedback",
            element: <Feedback />,
            pageName: "Feedback",
            sidebar: {
              show: true,
            },
          },
          // CONTACTUS: {
          //   path: "contact-us",
          //   element: <Account />,
          //   pageName: "ContactUs",
          //   sidebar: {
          //     show: true,
          //   },
          // },
        },
      ],
      sidebar: {
        show: true,
        // icon: HomeIcon,
      },
    },

    CODEEDITOR: {
      path: "/code-editor",
      element: <CodeEditor />,
      pageName: "CodeEditor",
      sidebar: {
        show: true,
        // icon: HomeIcon,
      },
    },
    CONVERSATION: {
      path: "/conversation",
      element: <Conversation />,
      pageName: "Conversation",
      sidebar: {
        show: true,
      },
      children: [{
        CONVERSATIONWITHID: {
          path: "/conversation/:id",
          element: <Conversation />,
          pageName: "Conversation",
          sidebar: {
            show: true,
          },
        }
      }]
    },
    MOBILE_SETTINGS: {
      path: "/mobile-settings",
      element: <MobileSettings />,
      pageName: "Settings",
    },
  },
};
