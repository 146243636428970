import commonRequest from "./main";

const API_URL = process.env.REACT_APP_CODE_BASE_URL;
const API_KEY = process.env.REACT_APP_CODE_API_KEY;

const setHeaders = (baseUrl, apiKey) => ({
  baseURL: baseUrl,
  headers: {
    "x-api-key": apiKey,
  },
});

const askQuery = async (payload) => {
  try {
    const response = await commonRequest.post(
      "/query",
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const regenerateQuery = async (payload) => {
  try {
    const response = await commonRequest.post(
      "/regenerate",
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const conversationHistory = async (payload) => {
  try {
    const response = await commonRequest.get(
      "/conversations",
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const conversationHistoryById = async (id) => {
  try {
    const response = await commonRequest.get(
      `/conversations/${id}`,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const deleteConversationById = async (id) => {
  try {
    const response = await commonRequest.delete(
      `/conversations/${id}`,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const renameConversation = async (id, payload) => {
  try {
    const response = await commonRequest.patch(
      `/conversations/${id}`,
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const archiveConversation = async (id, payload) => {
  try {
    const response = await commonRequest.put(
      `/conversations/${id}`,
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const getArchiveConversation = async () => {
  try {
    const response = await commonRequest.get(
      `/conversations/archived/details`,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const pinnedConversation = async (id, payload) => {
  try {
    const response = await commonRequest.post(
      `/conversations/${id}`,
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const getShareConversations = async (id, payload) => {
  try {
    const response = await commonRequest.post(
      `/share/conversations`,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const submitChatFeedback = async (id, payload) => {
  try {
    const response = await commonRequest.post(
      `/conversations/chat-reaction/${id}`,
      payload,
      setHeaders(API_URL, API_KEY)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export {
  askQuery,
  regenerateQuery,
  conversationHistory,
  conversationHistoryById,
  deleteConversationById,
  renameConversation,
  getArchiveConversation,
  archiveConversation,
  pinnedConversation,
  getShareConversations,
  submitChatFeedback,
};
