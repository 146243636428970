import React from 'react';
import { Field, ErrorMessage } from 'formik';
import styles from './Checkbox.module.scss';
import ErrorText from '../errortext/ErrorText';

const Checkbox = ({ label, name, isRequired, ...rest }) => {
  return (
    <div className={styles.checkboxControl}>
      <label className={styles.label}>
        <Field
          type="checkbox"
          name={name}
          className={styles.checkboxField}
          {...rest}
        />
        {label}
        {isRequired && <span className={styles.requiredMark}>*</span>}
      </label>
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default Checkbox;
