import * as React from "react";
import { useState, useEffect } from "react";
import styles from "./WelcomeModal.module.scss";
import { useNavigate } from "react-router-dom";
import ARROW from "../../assets/svg/dot.svg";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import { setActiveLanguage } from "../../store/slices/editorSlice";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
const WelcomeModal = ({ open, handleClose }) => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const { languages, activeLanguage } = useSelector((state) => state.language);

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
    const lastUntitledIndex = activeLanguage.tabs.reduce((acc, tab) => {
      const match = tab.lang.name.match(/untitled(\d+)/);
      if (match) {
        return Math.max(acc, parseInt(match[1], 10));
      }
      return acc;
    }, 0);
    const nextUntitledIndex = lastUntitledIndex + 1;
    dispatch(
      setActiveLanguage({
        name: languages[0]?.language,
        extension: languages[0]?.extension,
        languageId: languages[0]?.languageId,
        langIcon: languages[0]?.icon,
        value: "",
        responseValue: "",
        featureType:"",

        tabs: [
          {
            id: nextUntitledIndex,
            content: "",
            answer: "",
            isActive: true,
            lang: {
              name: `untitled${1}`,
              extension: languages[0]?.extension,
              langName: languages[0]?.language,
              langIcon: languages[0]?.icon,
            },
          },
        ],
      })
    );
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.mainModal}>
        <div className={styles.mainModal_inner}>
          <div className={styles.feature_modal}>
            <div className={styles.header_div}>
              <p>Generate Code</p>
            </div>
            <div className={styles.content_div}>
              <div className={styles.intruction_sec}>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}} /> Learn Coding Easily
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Ask a Query and Get Desired Code
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Fetch Reference Code Seamlessly
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Supports Multiple Programming Languages
                </span>
              </div>
              <button
                onClick={() => handleNavigate("/home")}
                className={styles.explore_btn}
              >
                Explore Now
              </button>
            </div>
          </div>
          <div className={styles.feature_modal}>
            <div className={styles.header_div}>
              <p>Code Editor</p>
            </div>
            <div className={styles.content_div}>
              <div className={styles.intruction_sec}>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Know Your Code Better
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Suggestions to Optimize, Debug, and Document
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Convert Code From One Language to Another
                </span>
                <span>
                  <img src={ARROW} alt="arrow" style={{width: "8px", height: "8px"}}/> Create Documentation For Your Code
                </span>
              </div>
              <button
                onClick={() => handleNavigate("/code-editor")}
                className={styles.explore_btn}
              >
                Start Coding
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
