import axios from 'axios';
import { refreshToken } from './userService';
const token = localStorage.getItem("access");
let errorRequest = [];
let requestedRefreshToken = false
const commonRequest = axios.create({
  // baseURL: 'https://codeqx-user-service-dev.qxlabai.tech',
  headers: {
    'Content-Type': 'application/json',
    "x-access-token": token
  },
  params: { requestId: `WEB_${new Date().getTime()}` },
});
//refresh token
const refreshAccessToken = async () => {
  const refresh = localStorage.getItem('refresh');
  const token = localStorage.getItem('access');
  localStorage.removeItem("access", token);
  try {
    const response = refresh && await refreshToken(
      {
        refreshToken: refresh,
      });
    if (response && response?.tokens) {
      localStorage.removeItem("refresh", refresh);
      const newAccessToken = response?.tokens.accessToken;
      const newRefreshToken = response?.tokens.refreshToken;
      localStorage.setItem('access', newAccessToken);
      localStorage.setItem('refresh', newRefreshToken);
      requestedRefreshToken = false
      return newAccessToken;
    } else {
      localStorage.removeItem("refresh", refresh)
    }
  } catch (error) {
    // console.log('errorrrr', error)
    // if (error?.response?.status === 401) {
    localStorage.removeItem("refresh", refresh)
    // }
  }
};
////
//ADD Interceptor here
commonRequest.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

commonRequest.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  // console.log('error', error)
  if (error?.response?.status === 401) {
    if (!requestedRefreshToken) {
      errorRequest = [];
      requestedRefreshToken = true
      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          error.config.headers['x-access-token'] = newAccessToken;
          errorRequest.forEach(req => {
            req.headers['x-access-token'] = newAccessToken;
            axios.request(req);
          })
          return axios.request(error.config);
        }
      } catch (refreshError) {
        localStorage.clear()
        window.location.href = "/Login"
        // store.dispatch(setSessionExpired({ condtion: true, msg: "Session has been Expired!" }))
      }
    } else {
      if (error.config.url.includes('/refresh-token')) {
        // store.dispatch(setSessionExpired({ condtion: true, msg: "Session has been Expired!" }))
        window.location.href = "/Login"
        localStorage.clear()
      } else {
        if (!errorRequest.find(req => req.url === error.config.url && req.method === error.config.method)) {
          errorRequest.push(error.config);
        }
      }
      // window.location.href = "/login"
      // localStorage.clear()
    }
  }
  return Promise.reject(error);
});
export default commonRequest;