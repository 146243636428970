import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { continueConversation } from "../../services/shareChatService";
import { setConversationId } from "../../store/slices/conversationSlice";
import styles from "../../../src/components/modals/ShareChatModal.module.scss";
import styles2 from "../../components/conversation/Conversation.module.scss";
import profileIcon from "../../assets/png/user.png";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  MdOutlineContentCopy,
  MdOutlineDone,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getToken } from "../../utils";
import {
  oneLight,
  materialLight,
  solarizedlight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { IoCopyOutline } from "react-icons/io5";

const PreviewChat = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [array, setArray] = useState([]);
  const [copiedMap, setCopiedMap] = useState({});
  const [queryCopied, setQueryCopied] = useState({});
  const [userId, setUserId] = useState("");
  const [shareConvId, setShareConvId] = useState("");
  const [conversationIds, setConversationIds] = useState("");

  const [currentAnswerIndexMap, setCurrentAnswerIndexMap] = useState({});
  const [whichStyle, setWhichStyle] = useState("themeStyle");
  const [conversationTitle, setConversationTitle] = useState("");
  const [isFound, setIsFound] = useState(false);
  const [copiedCodeMap, setCopiedCodeMap] = useState({});
  const [imageUrl, setImageUrl] = useState(
    localStorage.getItem("profilePhoto")
  );

  const handleCopyCode = (response, index) => {
    setCopiedCodeMap((prevCopiedCodeMap) => ({
      ...prevCopiedCodeMap,
      [`${response}_${index}`]: true,
    }));
    setTimeout(() => {
      setCopiedCodeMap((prevCopiedCodeMap) => ({
        ...prevCopiedCodeMap,
        [`${response}_${index}`]: false,
      }));
    }, 2500);
  };

  const getConversationDetails = async () => {
    setIsLoading(true);
    setIsFound(() => false);

    try {
      const headers = {
        "x-api-key": process.env.REACT_APP_CODE_API_KEY,
      };

      const requestId = new Date().getTime();

      const response = await axios(
        `${process.env.REACT_APP_CODE_BASE_URL}/share/conversation-details/${params.id}?requestId=WEB_${requestId}`,
        { headers }
      );

      if (response.status === 200) {
        if (response?.data?.data === null) {
          setIsFound(() => true);
          setIsLoading(false);
          return;
        } else {
          setIsFound(() => false);
          setArray(response?.data?.data?.conversation?.chats);
          setConversationTitle(
            response?.data?.data?.conversation?.conversation
          );
          setUserId(response?.data?.data?.userId);
          setConversationIds(
            response?.data?.data?.conversation?.conversationId
          );
          setShareConvId(response?.data?.data?.shareConversationId);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        toast.error("Something went wrong!");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.error?.message);
    }
  };
  const handleIncrement = (itemIndex) => {
    setCurrentAnswerIndexMap((prevMap) => {
      const prevIndex = prevMap[itemIndex] || 0;
      const newIndex = Math.min(
        prevIndex + 1,
        array[itemIndex]?.answers.length - 1
      );
      return { ...prevMap, [itemIndex]: newIndex };
    });
  };

  const handleDecrement = (itemIndex) => {
    setCurrentAnswerIndexMap((prevMap) => {
      const prevIndex = prevMap[itemIndex] || 0;
      const newIndex = Math.max(prevIndex - 1, 0);
      return { ...prevMap, [itemIndex]: newIndex };
    });
  };
  useEffect(() => {
    localStorage.removeItem("conv_id");
    localStorage.removeItem("conv_user_id");

    getConversationDetails();
  }, [params?.id]);

  const handleContinueChat = async () => {
    localStorage.removeItem("conv_id");
    localStorage.removeItem("conv_user_id");

    const data_payload = {
      data: {
        userId: userId,
        shareConversationId: shareConvId,
        // conversationId: conversationIds
      },
    };

    const response = await continueConversation(data_payload);

    if (response.status === 200) {
      if (response?.data?.success === false) {
        toast.error(response?.data?.error?.message);
        dispatch(setConversationId(null));
        navigate("/new-chat");
      } else {
        dispatch(setConversationId(response?.data?.data?.conversation));
        navigate(`/conversation/${response?.data?.data?.conversation}`);
      }
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleGetStarted = () => {
    localStorage.setItem("conv_id", shareConvId);
    localStorage.setItem("conv_user_id", userId);
    navigate("/login");
  };
  const TypingData = (item) => {
    return (
      <div className={styles2.message}>
        {item.split("```").map((part, index) => {
          if (index % 2 === 0) {
            return <p key={index}>{part}</p>;
          } else {
            const [language, ...codeLines] = part.trim().split("\n");
            const code = codeLines.join("\n");
            return (
              <div key={index} className={styles2.typing}>
                <div className={styles2.codeheader}>
                  <div className={styles2.headerItems}>
                    <p className={styles2.language}>{language}</p>
                    <CopyToClipboard
                      text={code}
                      onCopy={() => handleCopyCode(item._id, index)}
                    >
                      {copiedCodeMap[`${item._id}_${index}`] ? (
                        <i>
                          <MdOutlineDone size={30} />
                        </i>
                      ) : (
                        <i>
                          <IoCopyOutline size={30} />
                        </i>
                      )}
                    </CopyToClipboard>
                  </div>
                  <SyntaxHighlighter
                    language={language}
                    style={oneLight}
                    customStyle={{ borderRadius: "30px" }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const renderQuestion = (item) => {
    return (
      <div className={styles2.message}>
        {item?.split("```").map((part, index) => {
          if (index % 2 === 0) {
            return <p key={index}>{part}</p>;
          } else {
            const code = part.trim();
            return (
              <div key={index}>
                <div>
                  <SyntaxHighlighter
                    style={oneLight}
                    customStyle={{ borderRadius: "30px" }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };
  return (
    <>
      <div
        className={styles.sharchat_section}
        style={{ paddingBottom: "3rem" }}
      >
        <div className={styles.info_sec} style={{ marginBottom: "2rem" }}>
          {array &&
            array?.map((item, index) => {
              const currentAnswerIndex = currentAnswerIndexMap[index] || 0;

              return (
                <div key={item?.chatId}>
                  <div name="question" className={styles2?.chatMain}>
                    <div className={`${styles2?.question}`}>
                      {renderQuestion(item.query)}

                      <div className={styles2.questionImage}>
                        <img src={localStorage?.getItem("profilePhoto") || profileIcon} alt="chatImg" />
                      </div>
                    </div>
                  </div>
                  <div className={styles2?.answer}>
                    <div className={styles2.answerImage}>
                      <img
                        src="https://res.cloudinary.com/db4uhziiy/image/upload/v1709710717/logoBlack_jgj2jg.png"
                        alt="chatImg"
                      />
                    </div>

                    <>
                      {item.answers.map((answer, answerIndex) => (
                        <React.Fragment key={answerIndex}>
                          <div className={styles2.answerText}>
                            {currentAnswerIndex === answerIndex &&
                              TypingData(answer.answer)}
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  </div>

                  <div className={styles.answerBottom}>
                    {item.answers.length > 1 && (
                      <>
                        <div className={styles2.switchAnswer}>
                          <button
                            style={{
                              opacity: currentAnswerIndex ? 1 : 0.6,
                            }}
                            className={styles2.switchAnswerButton}
                            onClick={() => handleDecrement(index)}
                          >
                            <MdOutlineKeyboardArrowLeft size={20} /> Prev
                          </button>
                          <span
                            style={{
                              color: "gray",
                              fontSize: "14px",
                            }}
                          >
                            {currentAnswerIndex + 1}/{item?.answers.length}
                          </span>
                          <button
                            style={{
                              opacity:
                                currentAnswerIndex === item.answers.length - 1
                                  ? 0.6
                                  : 1,
                            }}
                            className={styles2.switchAnswerButton}
                            onClick={() => handleIncrement(index)}
                          >
                            Next
                            <MdOutlineKeyboardArrowRight size={20} />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div className={styles.btn_section}> 
        {getToken() ? (
          <button onClick={handleContinueChat} className={styles.action_btn}>
            Continue Chat
          </button>
        ) : (
          <button onClick={handleGetStarted} className={styles.action_btn}>
            Get started with Ask Qx
          </button>
        )}
        </div>
      </div>
    </>
  );
};

export default PreviewChat;
