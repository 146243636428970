import React, { useState } from "react";
import styles from "./Keepintouch.module.scss";
import keepintouchImg from "../../assets/svg/keepintouch-img.svg";
import fbIcon from "../../assets/svg/social-fb.svg";
import inIcon from "../../assets/svg/social-in.svg";
import instaIcon from "../../assets/svg/social-insta.svg";
import twIcon from "../../assets/svg/social-x.svg";
import ytIcon from "../../assets/svg/social-yt.svg";
import { useNavigate } from "react-router-dom";

const Keepintouch = () => {
  const navigate = useNavigate();
  const openInNewWindow = (url) => {
    window.open(url, "_blank");
};
  return (
    <>
      <div className={`${styles.keepintouch} container`}>
        <div className={styles.keepintouch_text}>
          We’re sad to see you go! Follow us
          <br /> to keep in touch.
        </div>
        <div className={styles.keepintouch_image}>
          <img src={keepintouchImg} alt="keepintouch" />
        </div>
        <button onClick={() => navigate("/Login")} className={styles.keepintouch_loginBtn}>
          Back to Login Page
        </button>

        <div className={styles.keepintouch_social_text}>Social media links</div>
        <div className={styles.keepintouch_social}>
          <div onClick={() =>
                openInNewWindow("https://www.linkedin.com/company/qx-lab-ai")
              } className={styles.keepintouch_social_icon}>
            <img src={inIcon} alt="inIcon" />
          </div>
          <div onClick={() =>
                openInNewWindow("https://www.instagram.com/qxlabai/")
              } className={styles.keepintouch_social_icon}>
            <img src={instaIcon} alt="instaIcon" />
          </div>
          <div onClick={() =>
                openInNewWindow("https://www.facebook.com/QXLabAI/")
              } className={styles.keepintouch_social_icon}>
            <img src={fbIcon} alt="fbIcon" />
          </div>
          <div onClick={() =>
                openInNewWindow("https://x.com/QxLabAI")
              } className={styles.keepintouch_social_icon}>
            <img src={twIcon} alt="twIcon" />
          </div>
          <div onClick={() =>
                openInNewWindow("https://www.youtube.com/@QXLabAI")
              } className={styles.keepintouch_social_icon}>
            <img src={ytIcon} alt="ytIcon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Keepintouch;
