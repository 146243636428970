import React from "react";
import googlebtn from "../../assets/svg/newgog.svg";
import linkedin from "../../assets/png/link.png";
import Button from "../../shared/button/Button";
const SocialLoginButton = ({
  linkedInLogin,
  login,
  customLogicBeforeLogin,
  customLogicBeforeLinkedinLogin,
}) => {
  return (
    <>
      <div className="social_login_btn">
        <button
          className="googleloginbtn mt-0"
          onClick={customLogicBeforeLogin}
        >
          <img
            src={googlebtn}
            className="googleIconImg"
            alt="Sign in with Google"
          />
          Google
        </button>
        {/* <button
          className="googleloginbtn mt-0"
          onClick={customLogicBeforeLinkedinLogin}
        >
          <img
            src={linkedin}
            alt="Sign in with Linked In"
            className="googleIconImg"
          />
          Linkedin
        </button> */}
      </div>
    </>
  );
};
export default SocialLoginButton;
