import React, { useEffect, useState } from "react";
import { RiLoopLeftFill } from "react-icons/ri";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import styles from "./Conversation.module.scss";
import { color } from "framer-motion";
import { green } from "@mui/material/colors";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import sucessIcon from "../../assets/svg/sucessIcon.svg"

import { useDispatch, useSelector } from "react-redux";
import { conversationHistoryById, submitChatFeedback } from "../../services/coversationService";
import { MdOutlineKeyboardArrowDown, MdDone } from "react-icons/md";

import { PiSmileySad } from "react-icons/pi";
import { TfiFaceSmile } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { getHistoryById } from "../../store/slices/historySlice";
import { storeChats } from "../../store/slices/conversationSlice";
import OutlineDislike from "../likeDislikeSvgComponent/OutlineDislike"
import OutlineLike from "../likeDislikeSvgComponent/OutlineLike"
const ResponseButtons = ({ array, handleRegenerateQuery, item, index, handleNextAnswer, handlePrevAnswer, currentAnswerIndex, isLikedLastItem, lastArrayAnswerIndex
}) => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const conversation = useSelector(state => state.conversation);
  const [likeClicked, setLikeClicked] = useState(false);
  const [likedResponse, setLikedResponse] = useState(false);
  const [dislikeClicked, setDislikeClicked] = useState(false);
  const [expandDislike, setExpandDislike] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // State to track the selected option
  const [feedbackButtonClick, setFeedbackButtonClicked] = useState(false);
  const [showDislikeSuccess, setShowDislikeSuccess] = useState(false);

  useEffect(() => {
    setFeedbackButtonClicked(false)
    setLikeClicked(false)
    setDislikeClicked(false)
  }, [array])

  const getChatHistory = async () => {
    const data = await conversationHistoryById(id)
    if (data) {
      dispatch(storeChats(data?.conversation.chats))
    }
  }

  const handleInputChange = (e) => {
    setFeedbackText(e.target.value);
    setSelectedOption("")
  };
  const handleLikeClick = () => {
    setDislikeClicked(false);
    setLikeClicked(true);
  };

  const handleDislikeClick = () => {
    // setFeedbackButtonClicked(true)
    setLikeClicked(false);
    setDislikeClicked(true);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setFeedbackText("")
  };

  const handleDislikeExpand = () => (
    setExpandDislike(!expandDislike)
  )

  const handleLikeFeedback = () => {
    setFeedbackButtonClicked(true)
    setDislikeClicked(false)
    if (array.length === 0) {
      return;
    }
    const lastConversation = array[array.length - 1];
    const chatId = lastConversation.chatId;
    const answerId = lastArrayAnswerIndex;
    const payload = {
      data: {
        "chatId": chatId,
        "answerId": answerId,
        "reaction": {
          "isLiked": true,
          "message": "Liked"
        }
      }
    };
    submitChatFeedback(id, payload).then(() => {
      setLikedResponse(true);
      setTimeout(() => {
        getChatHistory()
        setLikedResponse(false);
      }, 1500);
    });
  };


  const handleDislikeFeedback = () => {
    setExpandDislike(false)
    // setDislikeClicked(false);
    setFeedbackButtonClicked(true)

    if (array.length === 0) {
      return;
    }
    const lastConversation = array[array.length - 1];
    const chatId = lastConversation.chatId;
    const answerId = lastArrayAnswerIndex;
    const payload = {
      data: {
        "chatId": chatId,
        "answerId": answerId,
        "reaction": {
          "isLiked": false,
          "message": selectedOption || feedbackText
        }
      }
    }

    submitChatFeedback(id, payload).then(() => {
      setShowDislikeSuccess(true)
      setTimeout(() => {
        getChatHistory()
        setShowDislikeSuccess(false)
      }, 1500);

    })
  }

  return (
    <div className={`${styles.responseButtons} container`} >
      {!conversation.loading && (
        <div className={styles.newChatButtonRegenerate}>
          {index === array.length - 1 && (
            <div
              className={styles.newChatButtonRegenerate_button}
              onClick={handleRegenerateQuery}
            >
              <RiLoopLeftFill size={20} />
              <span>Regenerate Response</span>
            </div>
          )}
          {item.answers.length > 1 && (
            <div className={styles.switchAnswer}>
              <div
                style={{
                  opacity: currentAnswerIndex ? 1 : 0.6
                }}
                className={styles.switchAnswerButton}
                onClick={() => handlePrevAnswer(index)}
              >
                <MdOutlineKeyboardArrowLeft
                  size={20}
                />
                <span>Prev</span>
              </div>
              <span
                style={{
                  color: "gray",
                  fontSize: "14px",
                  boxSizing: "border-box"
                }}
              >
                {currentAnswerIndex + 1}/
                {item?.answers.length}
              </span>
              <div
                style={{
                  opacity:
                    currentAnswerIndex ===
                      item.answers.length - 1
                      ? 0.6
                      : 1
                }}
                className={styles.switchAnswerButton}
                onClick={() => handleNextAnswer(index)}
              >
                <span>Next</span>
                <MdOutlineKeyboardArrowRight
                  size={20}
                />
              </div>

            </div>
          )}
          {item.answers.length < 0 && (
            <div></div>
          )}
        </div>
      )}
      {index === array.length - 1 && !conversation.loading && (
        <div className={styles.feedbackButtons}>
          <button
            disabled={isLikedLastItem !== null || feedbackButtonClick}
            className={isLikedLastItem === true || likeClicked ? `${styles.feedbackButtonLike} ${styles.clicked}` : styles.feedbackButtonLike}
            onClick={handleLikeClick}
            style={{ display: (isLikedLastItem === false || dislikeClicked) ? 'none' : 'flex' }}
          >
            {/* <AiOutlineLike onClick={handleLikeFeedback} size={30} color={isLikedLastItem === true || likeClicked ? "white" : "green"} /> */}
            <OutlineLike onClick={handleLikeFeedback} color={isLikedLastItem === true || likeClicked ? "white" : "green"} />

          </button>
          <button
            disabled={isLikedLastItem !== null || feedbackButtonClick}
            className={isLikedLastItem === false || dislikeClicked ? `${styles.feedbackButtonDislike} ${styles.clicked}` : styles.feedbackButtonDislike}
            onClick={handleDislikeClick}
            style={{ display: (isLikedLastItem === true || likeClicked) ? 'none' : 'flex' }}
          >
            {/* <AiOutlineDislike size={30} color={isLikedLastItem === false || dislikeClicked ? "white" : "red"} /> */}
            <OutlineDislike color={isLikedLastItem === false || dislikeClicked ? "white" : "red"} />

          </button>
          {likeClicked && likedResponse && <div className={styles.likeClickedAction}>
            <div className={styles.iconSucess}>
              <TfiFaceSmile size={30} />
            </div>
            <p>Glad you liked this answer!</p>
          </div>}
          {(dislikeClicked && !feedbackButtonClick) && <div className={styles.disLikeAction}>
            <div className={styles.disLikeActionOne} onClick={handleDislikeExpand}>
              <div className={styles.iconDislike} >
                <PiSmileySad size={40} />
              </div>
              <div className={styles.dislikeText}>
                <h6>Why wasn’t this helpful?</h6>
                <p>Give Feedback <i><MdOutlineKeyboardArrowDown size={30} /></i></p>
              </div>
            </div>
            <div>
              {expandDislike &&
                <div className={styles.disLikeActionTwo}>
                  <textarea
                    placeholder="Describe the problem you are facing. "
                    value={feedbackText}
                    onChange={handleInputChange}
                  />
                  <button onClick={handleDislikeFeedback}>submit</button>
                </div>
              }
            </div>
          </div>}
          {showDislikeSuccess && <div className={styles.disLikeClicked}>
            <div className={styles.disLikeSucess}>
              <MdDone size={30} />
            </div>
            <p>Thanks for your feedback!</p>
          </div>}
          {/* {expandDislike && <div className={styles.expandDislike}>
            <div className={styles.expandDislikeButtons}>
              <p
                style={{ backgroundColor: selectedOption === "Inaccurate" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Inaccurate")}
              >
                Inaccurate
              </p>
              <p
                style={{ backgroundColor: selectedOption === "Inappropriate" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Inappropriate")}
              >
                Inappropriate
              </p>
              <p
                style={{ backgroundColor: selectedOption === "Offensive" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Offensive")}
              >
                Offensive
              </p>
              <p
                style={{ backgroundColor: selectedOption === "Irrelevant" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Irrelevant")}
              >
                Irrelevant
              </p>
              <p
                style={{ backgroundColor: selectedOption === "Wrong" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Wrong")}
              >
                Wrong
              </p>
              <p
                style={{ backgroundColor: selectedOption === "Others" ? "#323332" : "" }}
                onClick={() => handleOptionClick("Others")}
              >
                Others
              </p>
            </div>
            <textarea
              placeholder="Describe what is the problem you’re Facing?"
              value={feedbackText}
              onChange={handleInputChange}
            />
            <button onClick={handleDislikeFeedback}>submit</button>
          </div>} */}
        </div>
      )}
    </div>
  );
};

export default ResponseButtons;
