import React, { useState } from "react";
import { Modal } from "@mui/material";
import { renameChat } from "../../store/slices/historySlice";
import { useDispatch } from "react-redux";
import styles from "../../pages/settings/chathistory/Chathistory.module.scss";
import CROSS from "../../assets/png/cross.png";
import { toast } from "react-toastify";
const RenameModal = ({ open, setModalOpen, conversationId, convo }) => {
  const [chatName, setChatName] = useState(convo);
  const [error, setError] = useState(false); // State to track input validation
  const dispatch = useDispatch();

  const handleChatName = (e) => {
    setChatName(e.target.value);
    setError(false); // Reset error when user types in input
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!chatName.trim()) {
      // Check if input is empty or contains only whitespace
      setError(true);
      return; // Prevent form submission if input is empty
    }
    const payload = {
      data: {
        conversation: chatName,
      },
    };
    dispatch(
      renameChat({
        id: conversationId,
        payload,
      })
    );
    toast.success("Renamed Successfully!");
    setModalOpen(false);
  };

  return (
    <>
      <Modal open={open}>
        <div className="w-100 h-100  d-flex justify-content-center align-items-center">
          <div className={styles?.delModal}>
            <div className={styles?.delheader}>
              <p>Rename Chat History</p>
              <img
                src={CROSS}
                className={styles?.cross}
                alt="delete_icon"
                onClick={() => setModalOpen(false)}
              />
            </div>
            <div className={styles?.innermodal}>
              <form onSubmit={handleSubmit} style={{ width: "90%" }}>
                <div>
                  <input
                    type="text"
                    value={chatName}
                    onChange={handleChatName}
                    className={`${styles?.rename} ${error && styles?.error}`} // Apply error class if there's an error
                    placeholder="Enter name"
                  />
                  {error && <p className="text-danger">Name cannot be empty</p>} {/* Error message */}
                </div>
                <div className={styles?.btngrp}>
                  <button
                    className={styles?.delbtn2}
                    onClick={() => setModalOpen(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={styles?.renamebtn}>
                    Rename
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RenameModal;
