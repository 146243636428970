import React from 'react'
import styles from "./DownloadVsCode.module.scss"
import { useNavigate } from 'react-router'
import GENERATE from "../../assets/svg/Generate.svg"
import OPTIMIZE from "../../assets/svg/Optimize.svg"
import DEBUG from "../../assets/svg/Debug.svg"
import DOCUMENT from "../../assets/svg/DocumentCode.svg"
import TEST from "../../assets/svg/testcase.svg"
import CONVERSION from "../../assets/svg/Conversion.svg"
import EXPLAIN from "../../assets/svg/ExplainCode.svg"
import ADDCOMMENT from "../../assets/svg/addcomment.svg"
import ILLUSTRATE from "../../assets/svg/Illustration.svg"
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_LANDSCAPE_WIDTH, MOBILE_VIEW_MAX_WIDTH } from "../../utils";
import useMediaQueryLandscape from '../../hooks/useMediaQueryLandscape'

function DownloadVsCode() {
    const mobileView = useMediaQuery(MOBILE_VIEW_MAX_WIDTH);
    const navigate = useNavigate()
    const mobileLandscape = useMediaQueryLandscape(`(max-width: ${MOBILE_LANDSCAPE_WIDTH}px) and (orientation: landscape)`)
    return (
        <>
            <div className={`${styles?.parent} p-4`}>
                <div className={`d-flex flex-column gap-5 ${mobileView ? styles?.paddingForBtn_mobile : styles?.paddingForBtn}`}>
                    {!mobileView && !mobileLandscape &&
                        <div className='d-flex align-items-center justify-content-between'>
                            {/* <div className={styles?.header}>
                                Download VS Code
                                <div className={styles?.underline}></div>
                            </div> */}
                            <h6 className={styles.downloadvs__content_title}> VS Code Extension</h6>
                            <div>
                                <a href='https://marketplace.visualstudio.com/items?itemName=QxLabAI.text2code' target='_blank'><button className={styles?.btn} >
                                    Install</button></a>
                            </div>
                        </div>

                    }
                    <p className={styles?.content_header}>Text2Code is an AI development tool designed for developers to enhance their efficiency with custom code snippets and intelligent editing suggestions.
                        <span className={styles?.span}> Simply download the VS Code extension to access these features within your own coding environment.</span></p>

                        <div className={styles.items}>
							<div className={styles?.item}>
								<img src={GENERATE} alt="Generate Code" />
								<p>Generate Code</p>
							</div>
							<div className={styles?.item}>
								<img src={OPTIMIZE} alt="Optimize Code" />
								<p>Optimize Code</p>
							</div>
							<div className={styles?.item}>
								<img src={DEBUG} alt="Debug Code" />
								<p>Debug Code</p>
							</div>
							<div className={styles?.item}>
								<img src={DOCUMENT} alt="Document Code" />
								<p>Document Code</p>
							</div>
							<div className={styles?.item}>
								<img src={TEST} alt="Text Cases" />
								<p>Write Text Cases</p>
							</div>
							<div className={styles?.item}>
								<img src={CONVERSION} alt="Code Conversion" />
								<p>Code Conversion</p>
							</div>
                            <div className={styles?.item}>
								<img src={EXPLAIN} alt="Explain Code" />
								<p>Explain Code</p>
							</div>
							<div className={styles?.item}>
								<img src={ADDCOMMENT} alt="Add Comments To Code" />
								<p>Add Comments To Code</p>
							</div>
						</div>


                    <div className={styles?.img}>
                        <img src={ILLUSTRATE} alt='illustrate' className='w-100 h-100' />
                    </div>
                    {/* {
                        mobileView || mobileLandscape ? <div>
                            <a href='https://marketplace.visualstudio.com/items?itemName=QxLabAI.text2code' target='_blank'><button className={styles?.btn} >
                                Install VS Code</button></a>
                        </div> : null
                    } */}


                </div>

            </div>
        </>
    )
}

export default DownloadVsCode