import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



const initialState = {
  loading: false,
  conversationId: null,
  chats: [],
  themeStyle: "balanced",
  lastChatId: null,
  lastAnswerId: null,
  lastAnswerIsliked: null,
  userImage: localStorage.getItem('profilePhoto')
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setConversationId: (state, action) => {
      state.conversationId = action.payload;
    },
    setLastChatId: (state, action) => {
      state.lastChatId = action.payload
    },
    setLastAnswerId: (state, action) => {
      state.lastAnswerId = action.payload
    },
    storeChats: (state, action) => {
      state.chats = action.payload;
    },
    addQuestion: (state, action) => {
      const { query } = action.payload;
      state.chats.push({
        query: query,
        style: "Balanced",
        answers: [],
      });
    },
    newChatHandler: (state, action) => {
      state.conversationId = null;
      state.lastChatId = null;
      state.lastAnswerId = null;
      state.chats = [];
    },
  },
});

export const { setLoading, setConversationId, setLastChatId, setLastAnswerId, storeChats, addQuestion, newChatHandler } =
  conversationSlice.actions;

export default conversationSlice.reducer;
