import { BsStars } from "react-icons/bs";
import styles from "./Conversation.module.scss";
import { IoIosCode } from "react-icons/io";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getLanguageSuggestions } from "../../services/profileService";
import SkeletonLoader from '../../assets/SkeletonLoader'

const Intro = ({ setQuestion }) => {
  const { activeLanguage, loading } = useSelector(state => state.language);
  const [introData, setIntroData] = useState([])

  useEffect(() => {
    if (activeLanguage.name) {
      getLanguageSuggestions().then((data) => {
        const filteredIntroData = data.languageSuggestions.filter(item => item.language.languageName === activeLanguage.name);
        setIntroData(filteredIntroData);
      });
    }
  }, [activeLanguage.name]);

  const handleSuggestionClick = (item) => {
    setQuestion(item)
  }

  return (
    <>  {loading ? (
      <SkeletonLoader skeletonType="Intro" />
    ) : (
      <>
        <div className={styles.introContainer}>
          <div className={styles.languageBox}>
            <img
              className={styles.introImage}
              src={activeLanguage.icon}
              alt="Intro"
            />
            <h2>{`Welcome to ${activeLanguage.name} Chatbot!`}</h2>
            <p>{`Hi there, I’m an expert in ${activeLanguage.name}. How can I help?`}</p>
          </div>
          <div className={styles.suggestionTitle}>
            <i>
              <BsStars size={20} />
            </i>
            <p>Suggestions</p>
          </div>
          <div className={styles.suggestionsContainer}>
            {introData[0]?.suggestions.map((item, index) => (
              <div key={index} className={styles.suggestionBox} onClick={() => handleSuggestionClick(item.suggestion)}>
                <i><IoIosCode size={15} color="#000000" /></i>
                <p>
                  {item.suggestion}
                </p>
              </div>
            ))}
          </div>
        </div>
      </>
    )}
    </>
  );
};

export default Intro;
