import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteSnippet, getAllLangauges, getSnippet, getSnippetById, postSnippet } from "../../services/editorService";

// export const fetchAllData = createAsyncThunk("fetchData/allData", async () => {
//     try {
//       let response = await fetchData("/getdata");
//       return response?.data?.data?.plans;
//     } catch (err) {
//       toast.error("something went wrong");
//     }
//   });

export const fetchLanguage = createAsyncThunk(
  "language/fetchLanguage",
  async () => {
    try {
      const response = await getAllLangauges();

      if (!response) {
        throw new Error("Failed to fetch data");
      }

      return response;
    } catch (error) {
      throw new Error("Error fetching data");
    }
  }
);

export const snippetPost = createAsyncThunk(
  "snippet/post",
  async (thunkAPI) => {
    const data = await postSnippet(thunkAPI, {
      "Content-Type": " multipart/form-data",
    })

  }
);
export const snippetGet = createAsyncThunk(
  "snippet/get",
  async (thunkAPI) => {
    const data = await getSnippet()
    return data?.savedSnippets

  }
);

export const snippetDelete = createAsyncThunk(
  "snippet/delete",
  async (id, thunkAPI) => {
    const data = await deleteSnippet(id)
    thunkAPI.dispatch(snippetGet())
    // return data?.savedSnippets

  }
);
export const snippetById = createAsyncThunk(
  "snippet/get/id",
  async (id, thunkAPI) => {
    const data = await getSnippetById(id)
    // thunkAPI.dispatch(snippetGet())
    // return data?.savedSnippets

  }
);

const initialState = {
  snippet: [],
  languages: null,
  loading: false,
  isPreview: false,
  featureType: "",
  previewAnswer: "",
  openTranslateModal: false,
  targetedLanguage: null,
  error: null,
  activeLanguage: {
    value: "",
    ref: null,
    responseValue: "",
    featureType: "",
    name: "",
    extension: "",
    icon: "",
    languageId: "",
    langIcon: null,
    tabs: [
      {
        id: 1,
        content: "",
        answer: "",
        featureTypes: "",
        isActive: true,
        lang: {
          name: "",
          extension: "",
          langName: "",
          langIcon: null,
        },
      },
    ],
  },
};

// Define the language slice
export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    getRef: (state, action) => {
      state.ref = action.payload
    },
    setActiveLanguage: (state, action) => {
      state.activeLanguage = { ...state.activeLanguage, ...action.payload };
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },
    addNewFile: (state) => {
      const lastUntitledIndex = state.activeLanguage.tabs.reduce((acc, tab) => {
        // const match = tab.lang.name.match(/untitled(\d+)/);
        // if (match) {
        //   return Math.max(acc, parseInt(match[1], 10));
        // }
        const match = tab.lang.name.match(/(.+?)(\d+)/);
        if (match) {
            return Math.max(acc, parseInt(match[2], 10));
        }
    
        return acc;
      }, 0);
      const nextUntitledIndex = lastUntitledIndex + 1;
      const newTab = {
        id: nextUntitledIndex,
        content: "",
        answer: "",
        featureTypes: '',
        isActive: true,
        lang: {
          name: `untitled${nextUntitledIndex}`,
          extension: state?.activeLanguage?.extension,
          langName: state?.activeLanguage?.name,
          langIcon: state?.activeLanguage?.langIcon,
        },
      };
      state?.activeLanguage?.tabs.push(newTab);
      // state.activeLanguage.activeFileTab = newTab.name;

      state.activeLanguage.tabs.forEach((tab) => {
        if (tab.id !== nextUntitledIndex) {
          tab.isActive = false;
        }
      });
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    removeFileFromArray: (state, action) => {
      const removedTabIndex = state.activeLanguage.tabs.findIndex(
        (tab) => tab.id === action.payload
      );

      if (removedTabIndex !== -1) {
        state.activeLanguage.tabs.splice(removedTabIndex, 1);
        const nextTabIndex = Math.min(
          removedTabIndex,
          state.activeLanguage.tabs.length - 1
        );
        state.activeLanguage.tabs.forEach((tab, index) => {
          tab.isActive = index === nextTabIndex;
        });
        const activeTab = state.activeLanguage.tabs.find((tab) => tab.isActive);
        if (activeTab) {
          state.activeLanguage.value = activeTab.content;
          state.activeLanguage.responseValue = activeTab.answer;
          state.activeLanguage.featureType = activeTab.featureTypes;


          if (activeTab.answer) {
            state.isPreview = true;
          }
        }
      }
      if (state.activeLanguage.tabs.length <= 0) {
        state.activeLanguage.value = "";
        state.activeLanguage.responseValue = "";
        state.activeLanguage.featureType = "";

        state.isPreview = false;
      }
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    setActiveFileTab: (state, action) => {
      state.activeLanguage.tabs = state.activeLanguage.tabs.map((tab) =>
        tab.id === action.payload
          ? { ...tab, isActive: true }
          : { ...tab, isActive: false }
      );
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    setExtension: (state, action) => {
      const {
        id,
        lang: { extension, langName, langIcon },
      } = action.payload;
      state.activeLanguage.tabs = state.activeLanguage.tabs.map((tab) =>
        tab.id === id
          ? {
            ...tab,
            lang: {
              ...tab.lang,
              extension: extension,
              langName: langName,
              langIcon: langIcon,
            },
          }
          : tab
      );
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },
    setActiveTabContent: (state, action) => {
      const { content } = action.payload;
      const activeTab = state.activeLanguage.tabs.find((tab) => tab.isActive);
      if (activeTab) {
        activeTab.content = content;
        state.activeLanguage.value = content;
      }
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    setActiveTabAnswer: (state, action) => {
      const { answer, feature } = action.payload;
      console.log('feature', feature)
      const activeTab = state.activeLanguage.tabs.find((tab) => tab.isActive);
      if (activeTab) {
        activeTab.answer = answer;
        activeTab.featureTypes = feature;
        // activeTab.lang.name = feature
        state.activeLanguage.responseValue = answer;
        state.activeLanguage.featureType = feature;

      }
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    setActiveValue: (state, action) => {
      state.activeLanguage.value = action.payload;
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },

    setActiveResponseValue: (state, action) => {
      state.activeLanguage.responseValue = action.payload;
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));
    },
    setActiveFeature: (state, action) => {
      state.activeLanguage.featureType = action.payload;
      localStorage.setItem("bucket", JSON.stringify(state.activeLanguage));

    },

    setFeatureType: (state, action) => {
      state.featureType = action.payload;
    },

    setIsPreview: (state, action) => {
      state.isPreview = action.payload;
    },

    setPreviewAnswer: (state, action) => {
      state.previewAnswer = action.payload;
    },

    setOpenTranslateModal: (state, action) => {
      state.openTranslateModal = action.payload;
    },
    setTargetedLanguage: (state, action) => {
      state.targetedLanguage = action.payload;
    },
    setActiveTabNameNew: (state, action) => {
      const { name } = action.payload;
      const activeTab = state.activeLanguage.tabs.find((tab) => tab.isActive);
      let str = activeTab.lang.name;
      let newStr = str.replace(/(.*?)(?=\d)/, `${name}`);
      let lat = newStr?.toLowerCase()?.replace(/_/g, '_')?.replace(/\b\w/g, c => c?.toUpperCase());
      console.log('lat', lat)
      activeTab.lang.name = lat

    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLanguage.fulfilled, (state, action) => {
        state.loading = false;
        state.languages = action.payload.languages;
        const bucket = localStorage.getItem("bucket");
        const parsedBucket = JSON.parse(bucket);
        if (!parsedBucket) {
          state.activeLanguage.name = action.payload.languages?.[0]?.language;
          state.activeLanguage.extension = action.payload.languages?.[0]?.extension;
          state.activeLanguage.languageId = action.payload.languages?.[0]?.languageId;
          state.activeLanguage.langIcon = action.payload.languages?.[0]?.icon;
        }
      })
      .addCase(fetchLanguage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(snippetGet.pending, (state) => {
        state.loading = true;
      })
      .addCase(snippetGet.fulfilled, (state, action) => {
        state.loading = false;
        state.snippet = action.payload;
      })
      .addCase(snippetGet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }).addCase(snippetDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(snippetDelete.fulfilled, (state, action) => {
        state.loading = false;
        // state.snippet = action.payload;
      })
      .addCase(snippetDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setActiveLanguage,
  addNewFile,
  getRef,
  removeFileFromArray,
  setActiveFileTab,
  setExtension,
  setActiveTabContent,
  setActiveTabAnswer,
  setActiveValue,
  setActiveResponseValue,
  setFeatureType,
  setIsPreview,
  setPreviewAnswer,
  setOpenTranslateModal,
  setTargetedLanguage,
  setActiveFeature,
  setActiveTabNameNew
} = languageSlice.actions;

export default languageSlice.reducer;
