import React from 'react'
import "./LoaderGif.css"
const LoaderGif = () => {
    return (
        <>
            <div id="loader"></div>

        </>
    )
}

export default LoaderGif