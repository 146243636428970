import React, { useState } from "react";
import styles from "./MobileSidebar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
import FeatureButton from "../../shared/button/FeatureButton";
import generateCode from "../../assets/svg/generate-code.svg";
import optimizeCode from "../../assets/svg/optimize-code.svg";
import debugCode from "../../assets/svg/debug-code.svg";
import documentCode from "../../assets/svg/document-code.svg";
import convertCode from "../../assets/svg/convert-code.svg";
import testCaseCode from "../../assets/svg/test-case-code.svg";
import explainCodeIcon from "../../assets/svg/explain-code-new.svg";
import commentsIcon from "../../assets/svg/comments.svg";
import FollowUsModal from "../modals/FollowUsModal";
import { MdOutlinePersonPin } from "react-icons/md";
import profileIcon from "../../assets/png/user.png";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { setOpenTranslateModal } from "../../store/slices/editorSlice";
import { useDispatch, useSelector } from "react-redux";
const MobileSidebar = ({
  handleGenerateFeature,
  handleCloseSidebar
}) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const { activeLanguage } = useSelector((state) => state.language);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Generate Code");
  const [followUs, setFollowUs] = useState(false);

  const handleFollowUs = () => {
    setFollowUs(true);
  };

  const handleOpenConvertCode = () => {
    dispatch(setOpenTranslateModal(true));
  };
  const activeTabs = activeLanguage.tabs.find((tab) => tab.isActive);

  return (
    <div className={styles.container}>
      <div className={styles.leftsidebar__top}>
        <div className={styles.logo_wrapper}>
          <div className={styles.left_align}>
            <div className={styles.profile_div}>
              <div className={styles.profile_div_image}>
              <img
                src={localStorage?.getItem("profilePhoto") || profileIcon}
                alt=""
              />              </div>
              <div className={styles.profile_div_username}>
                <span className={styles.name}>
                  {localStorage.getItem("firstName")}&nbsp;
                  {localStorage.getItem("lastName")}
                </span>
                {/* <span className={styles.credits}>
                  Credits <b className={styles.credits_bold}> 5000 </b>/4000
                </span> */}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.features_wrapper}>
          <div className={styles.button_wrapper}>
            {!location.pathname.includes("/home") && (
              <FeatureButton
                title="Generate Code"
                icon={generateCode}
                fromSettings={false}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                clickHandler={() => handleCloseSidebar()}
              />
            )}

            <FeatureButton
              title="Optimize Code"
              icon={optimizeCode}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("OPTIMIZE_CODE", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <FeatureButton
              title="Debug Code"
              icon={debugCode}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("DEBUG_CODE", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <FeatureButton
              title="Document Code"
              icon={documentCode}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("DOCUMENT_CODE", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <FeatureButton
              title="Write Test Cases"
              icon={testCaseCode}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("GENERATE_TEST_CASES", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <FeatureButton
              title="Code Conversion"
              icon={convertCode}
              fromSettings={false}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              clickHandler={() => {
                handleCloseSidebar();
                dispatch(setOpenTranslateModal(true));
              }}
            />

            <FeatureButton
              title="Explain Code"
              icon={explainCodeIcon}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("EXPLAIN_CODE", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />

            <FeatureButton
              title="Add Comments To Code"
              icon={commentsIcon}
              fromSettings={false}
              clickHandler={() => {
                handleCloseSidebar();
                handleGenerateFeature("ADD_COMMENTS_TO_CODE", true);
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      </div>

      <div className={styles.leftsidebar__bottom}>
        <div className={styles.settings_wrapper}>
          <div
            className={styles.settings_wrapper_item}
            onClick={() => {
              handleCloseSidebar();
              navigate('/mobile-settings');
            }}
          >
            <HiOutlineCog8Tooth size={26} />
            <span>Settings</span>
          </div>
          <div
            className={styles.settings_wrapper_item}
            onClick={() => {
              handleCloseSidebar();
              handleFollowUs();
            }}
          >
            <MdOutlinePersonPin size={26} />
            <span>Follow Us</span>
          </div>
        </div>
      </div>

      {followUs && (
        <FollowUsModal followUs={followUs} setFollowUs={setFollowUs} />
      )}
    </div>
  );
};

export default MobileSidebar;
