import { CopyToClipboard } from "react-copy-to-clipboard";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FaRegCopy } from "react-icons/fa6";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const MOBILE_VIEW_MAX_WIDTH = 932;
export const TABLET_VIEW_MAX_WIDTH = 800;
export const MOBILE_LANDSCAPE_WIDTH = 926
export const getToken = () => {
  return localStorage.getItem("access");
};

export const requiredMessage = (name) => {
  return `${name} is required!`;
};

export const combineClasses = (...classes) => {
  return classes.join(" ");
};

export const onlyNumbersInput = (e) => {
  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
};

export const TypingData = (item, copiedCodeMap, handleCopyCode, targetRef) => {
  return (
    <div className="answer_message" ref={targetRef}>
      {item?.split("```").map((part, index) => {
        if (index % 2 === 0) {
          return (
            <p key={index} className="answer_message_text">
              {part}
            </p>
          );
        } else {
          const [language, ...codeLines] = part.trim().split("\n");
          const code = codeLines.join("\n");
          return (
            <div key={index} className="typing_code">
              <div className="codeheader">
                {language}
                <CopyToClipboard
                  text={code}
                  onCopy={() => handleCopyCode(item._id, index)}
                >
                  {copiedCodeMap[`${item._id}_${index}`] ? (
                    <button className="copy_button">
                      <FaRegCopy /> Copied!
                    </button>
                  ) : (
                    <button className="copy_button">
                      <FaRegCopy />
                      copy code
                    </button>
                  )}
                </CopyToClipboard>
              </div>
              <SyntaxHighlighter language={language} style={okaidia}>
                {code}
              </SyntaxHighlighter>
            </div>
          );
        }
      })}
    </div>
  );
};

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
