import React, { useState } from "react";
import styles from "../../layouts/homelayout/LeftSidebar.module.scss";
import { Link, useNavigate } from "react-router-dom";
import upArrow from "../../assets/svg/uparrow.svg";
import profileICon from "../../assets/svg/profile-icon.svg";
import accountIcon from "../../assets/svg/account-icon.svg";
import billingIcon from "../../assets/svg/billing-icon.svg";
import chatHistoryIcon from "../../assets/svg/chat-history.svg";
import feedbackIcon from "../../assets/svg/feedback-icon.svg";
import contactUsIcon from "../../assets/svg/contactus-icon.svg";
import shareIcon from "../../assets/svg/share.svg";
import logoutIcon from "../../assets/svg/logout-icon.svg";
import vsCodeIcon from "../../assets/svg/vs-code-icon.svg";
import LogoutModal from "../modals/LogoutModal";
import collapse from "../../assets/svg/collapse-toggle.svg";
import Zoom from "@mui/material/Zoom";
import { LightTooltip } from "../../utils";
import logoMain from "../../assets/svg/logoMain.svg"

const SettingsSidebarClosed = ({ settingsSidebar, setToggleSidebar }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Profile");
  const [logout, setLogout] = useState(false);

  const handleLogout = () => {
    setLogout(true);
  };

  const handleLogoutClear = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div
      style={{
        transform: settingsSidebar ? "translateX(0)" : "translateX(-260px)",
        whiteSpace: "nowrap",
        padding: "1.2rem 0.3rem",
      }}
      className={styles.leftsidebar}
    >
      <div className={styles.leftsidebar__topClosed}>
        <div className={`${styles.logo_wrapper} ${styles.logo_wrapper_closed}`}>

          <div style={{ cursor: "pointer", marginBottom: '1.2rem' }}
          >
            <Link
              to="/"
            // onClick={() => setToggleSidebar(false)}
            >
              <img
                className={styles.mainLogoIma}
                src={logoMain}
                alt="main"
              />
            </Link>
          </div>

          <LightTooltip
            title="Open Sidebar"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <div
              onClick={() => setToggleSidebar(false)}
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
            >
              <img
                className={styles.collapse_icon}
                src={collapse}
                alt="collapse"
              />
            </div>
          </LightTooltip>
        </div>

        <div className={styles.features_wrapper}>
          <div className={styles.button_wrapper}>
            <LightTooltip
              title="Profile"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/profile")}
              >
                <img src={profileICon} alt="Profile" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Account"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/account")}
              >
                <img src={accountIcon} alt="Account" />
              </div>
            </LightTooltip>

            {/* <LightTooltip
              title="Billing"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/billing")}
              >
                <img src={billingIcon} alt="Billing" />
              </div>
            </LightTooltip> */}

            <LightTooltip
              title="Chat History"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/chat-history")}
              >
                <img src={chatHistoryIcon} alt="Chat History" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="Feedback"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/feedback")}
              >
                <img src={feedbackIcon} alt="Feedback" />
              </div>
            </LightTooltip>

            {/* <LightTooltip
              title="Contact Us"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/contact-us")}
              >
                <img src={contactUsIcon} alt="Contact Us" />
              </div>
            </LightTooltip> */}

            <LightTooltip
              title="Manage Shared Links"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/shared-links")}
              >
                <img src={shareIcon} alt="Manage Shared Links" />
              </div>
            </LightTooltip>

            <LightTooltip
              title="VS Code Extension"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                className={styles.button_wrapper_item}
                onClick={() => navigate("/setting/download-vscode")}
              >
                <img src={vsCodeIcon} alt="Download VS Code" />
              </div>
            </LightTooltip>
          </div>
        </div>
      </div>

      <div
        className={`${styles.leftsidebar__bottom} ${styles.leftsidebar__bottomClosed}`}
      >
        <div className={`${styles.chat_history} ${styles.chat_history_closed}`}>
          <LightTooltip
            title="Go Back"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <div
              className={`${styles.chat_history_header} `}
              style={{
                transform: "rotate(-90deg)",
              }}
              onClick={() => {
                navigate("/");
                setActiveTab(() => "Profile");
              }}
            >
              <img src={upArrow} alt="Chat history" />
            </div>
          </LightTooltip>
        </div>

        <div
          className={`${styles.settings_wrapper} ${styles.settings_wrapper_closed}`}
        >
          <LightTooltip
            title="Logout"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <div
              className={`${styles.settings_wrapper_item}`}
              onClick={handleLogout}
            >
              <img src={logoutIcon} alt="Logout" />
            </div>
          </LightTooltip>
        </div>
      </div>
      {logout && (
        <LogoutModal
          logout={logout}
          setLogout={setLogout}
          handleLogout={handleLogout}
          handleLogoutClear={handleLogoutClear}
        />
      )}
    </div>
  );
};

export default SettingsSidebarClosed;
