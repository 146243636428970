import React, { useEffect, useState } from "react";
import styles from "./TopNav.module.scss";
import { IoMdCode, IoMdClose, IoMdMore } from "react-icons/io";
import { PiChatCenteredDotsThin } from "react-icons/pi";
import { RiChatHistoryLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { FaAngleLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setActiveLanguage,
  setExtension,
} from "../../store/slices/editorSlice";
import arrow from "../../assets/svg/arrowNew.svg";
import { newChatHandler } from "../../store/slices/conversationSlice";
import { setHistoryId } from "../../store/slices/historySlice";
import { setTopNavTitle } from "../../store/slices/appSlice";
import logom from "../../assets/svg/logoMain.svg"

const TopNav = ({
  open,
  handleToggleSidebar,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [moreOptions, setMoreOptions] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);

  const { languages, activeLanguage, loading } = useSelector(
    (state) => state.language
  );

  const { topNavTitle } = useSelector(state => state.app);

  const navigate = useNavigate();
  const id = showMoreOptions ? "simple-popover" : undefined;

  const handleMenuTogglerClick = () => {
    handleToggleSidebar?.();
  };

  const handleUpgradeClick = () => {
    console.log("Upgrade to Plus");
  };

  const handleToggleMoreOptions = (e) => {
    setMoreOptions(e?.currentTarget);
    setShowMoreOptions((show) => !show);
    handleToggleSidebar(true);
  };

  const handleOnToggleCodeEditor = () => {
    handleToggleMoreOptions();
    if (location.pathname === "/code-editor") {
      navigate("/conversation");
    } else {
      navigate("/code-editor");
    }
  };

  const handleOnNewChat = () => {
    handleToggleMoreOptions();
    dispatch(newChatHandler())
    dispatch(setHistoryId())
    navigate("/home");
  };

  const handleOnChatHistory = () => {
    handleToggleMoreOptions();
    navigate("/setting/chat-history");
  };

  const openSuggestion = () => {
    setShowSelect(!showSelect);
    handleToggleSidebar(true);
  };
  const handleLanguageSelect = (selectedOption) => {
    const selectedLanguage = languages.find(
      (language) => language?.language === selectedOption
    );

    dispatch(
      setActiveLanguage({
        name: selectedLanguage?.language,
        icon: selectedLanguage?.icon,
        extension: selectedLanguage?.extension,
        languageId: selectedLanguage?.languageId,
        langIcon: selectedLanguage?.icon,
      })
    );

    const activeTab = activeLanguage?.tabs.find((tab) => tab?.isActive);
    if (activeTab) {
      dispatch(
        setExtension({
          ...activeTab,
          lang: {
            name: selectedLanguage?.label,
            extension: selectedLanguage?.extension,
            langName: selectedLanguage?.language,
            langIcon: selectedLanguage?.icon,
          },
        })
      );
    }
    setShowSelect(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSelect) {
        const suggestionDiv = document.querySelector("#selectsec");
        if (suggestionDiv && !suggestionDiv.contains(event.target)) {
          setShowSelect(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSelect]);

  const handleNewChat = () => {
    handleToggleSidebar(true)
    dispatch(newChatHandler())
    dispatch(setHistoryId())
  }

  const handleGoBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    setCanGoBack(location.pathname === '/mobile-settings' || location.pathname.includes('/setting'));
    dispatch(setTopNavTitle(location.pathname));
  }, [location]);

  return (
    <div className={styles.topNav}>
      {!canGoBack && (
        <div
          className={styles.topNav_menuToggler}
          onClick={handleMenuTogglerClick}
        >
          {open ? <IoMdClose /> : <GiHamburgerMenu />}
        </div>
      )}
      {canGoBack && (
        <div className={styles.topNav_menuToggler} onClick={handleGoBack}>
          <FaAngleLeft />
        </div>
      )}
      <div className={styles.topNav_logoContainer}>
        {topNavTitle && (
          <span className={styles.topNav_logoContainer_title}>{topNavTitle}</span>
        )}

        {location.pathname === "/code-editor" ? (
          <div
            className={styles.leftSide}
            onClick={(e) => {
              e.stopPropagation();
              openSuggestion();
            }}
          >
            <div className={styles.select_div}>
              <img
                src={activeLanguage?.langIcon}
                alt="language icon"
                className={styles.lang_icon_img}
              />
              <p>{activeLanguage.name}</p>
            </div>
            <div className={styles.arrow_sec}>
              <img
                src={arrow}
                alt=""
                className={showSelect ? styles.arrow_icon : styles.arrow_before}
              />
            </div>

            {showSelect && (
              <div
                className={styles.suggestion_div}
                id="selectsec"
                onclick={(e) => e.stopPropagation()}
              >
                <div className="inner-sugg">
                  {languages.map((language, index) => (
                    <div
                      className={styles.sugg_text}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLanguageSelect(language.language);
                      }}
                    >
                      <img
                        src={language?.icon}
                        alt="language icon"
                        className={styles.lang_icon_img}
                      />
                      <span key={index}>{language.language}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {!topNavTitle && (
              <Link
                to="/"
                className={styles.topNav_logoContainer}
                onClick={handleNewChat}
              >
                <img src={logom} alt="qx-logo" className={styles.mainLogoImage} />

                <span className={styles.topNav_logoContainer_title_logo}>
                  Text2Code
                </span>
              </Link>
            )}
            {/* {!topNavTitle && (
              <button
                className={styles.topNav_logoContainer_upgradeBtn}
                onClick={handleUpgradeClick}
              >
                Upgrade to Plus
              </button>
            )} */}
          </>
        )}
      </div>
      <div className={styles.topNav_moreOptions}>
        <IoMdMore onClick={handleToggleMoreOptions} />
      </div>

      {showMoreOptions && (
        <>
          <Popover
            id={id}
            open={showMoreOptions}
            anchorEl={moreOptions}
            onClose={handleToggleMoreOptions}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <div className={styles.moreOptions_container}>
              <div
                className={styles.moreOptions_item}
                onClick={handleOnToggleCodeEditor}
              >
                <IoMdCode />
                {location.pathname === "/conversation" ? (
                  <span>Toggle to code editor</span>
                ) : (
                  <span>Toggle to Chat box</span>
                )}
              </div>
              <div
                className={styles.moreOptions_item}
                onClick={handleOnNewChat}
              >
                <PiChatCenteredDotsThin />
                <span>New Chat</span>
              </div>
              <div
                className={styles.moreOptions_item}
                onClick={handleOnChatHistory}
              >
                <RiChatHistoryLine />
                <span>Chat History</span>
              </div>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default TopNav;
