import { useState, useEffect } from "react";
import styles from "./SharedLinks.module.scss";
import { MdOutlineChatBubbleOutline } from "react-icons/md";
// import { MdOutlineChatBubbleOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoIosLink } from "react-icons/io";
// import { Tooltip } from "react-tooltip";
import { RiDeleteBin5Line } from "react-icons/ri";
import deleteIcon from "../../../assets/svg/delete.svg";

import Tooltip from "@mui/material/Tooltip";
// import {
//   deleteAllSharedConversations,
//   deleteSharedConversationLink,
// } from "../../../services/ShareChatService";
import { deleteSharedConversationLink, getAllSharedLinks } from "../../../services/shareChatService";
import { Link } from "react-router-dom";
import { setConversationId } from "../../../store/slices/conversationSlice";
// import ConfirmDialog from "../ConfirmDialog";
// import QuantumLoader from "../../../UI/quantumLoader/QuantumLoader";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { MOBILE_LANDSCAPE_WIDTH, MOBILE_VIEW_MAX_WIDTH } from "../../../utils";
import messageIcon from '../../../assets/svg/Add Comments To Code.svg'
// import deleteIcon from '../../../assets/svg/MdDeleteOutline.svg'
import useMediaQueryLandscape from "../../../hooks/useMediaQueryLandscape";
import DeleteLinkModal from "../../../components/modals/DeleteLinkModal";


const SharedLinks = ({ openSharedLink, setOpenSharedLinks, onClose }) => {
  const mobileView = useMediaQuery(MOBILE_VIEW_MAX_WIDTH);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [sharedLinks, setSharedLinks] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const mobileLandscape = useMediaQueryLandscape(`(max-width: ${MOBILE_LANDSCAPE_WIDTH}px) and (orientation: landscape)`)
  const baseUrl = window.location.origin;
  const [delOpen, setDelOpen] = useState(false);
  const [conversationId, setConversationIds] = useState()
  const [shareConversationId, setShareConversationId] = useState()


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);



  const getAllConversations = async () => {
    setIsLoading(true);
    setNotFound(false);
    const response = await getAllSharedLinks();
    if (response.status === 200) {
      if (response?.data?.data === null) {
        setNotFound(true);
        setIsLoading(false);
        return;
      } else {
        setNotFound(false);
        setSharedLinks(response?.data?.data?.response);
        setIsLoading(false);
      }
      setLoadingApi(false);
    } else {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    // if (openSharedLink) {
    getAllConversations();
    // }
  }, []);

  // const handleDelete = async (convId, shareConvId) => {
  //   // if (loadingApi) {
  //   //   return;
  //   // }
  //   // setLoadingApi(true);
  //   // setDeleteLoading("");

  //   const response = await deleteSharedConversationLink(convId, shareConvId);

  //   if (response.status === 200) {
  //     setDeleteLoading(shareConvId);
  //     getAllConversations();
  //     toast.success("Link deleted Successfully!")
  //   } else {
  //     toast.error("Something went wrong!");
  //     setDeleteLoading("");
  //   }
  // };

  const handleViewSourceChat = (convId, shareConvId) => {
    dispatch(setConversationId(convId));
    localStorage.setItem("source_chat_id", convId);
    window.open(
      `${window.location.origin}/conversation/${convId}`,
      "_blank",
      "noreferrer"
    );
  };

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }

    return text;
  };

  // const handleConfirmDelete = async () => {
  //   if (loadingApi) {
  //     return;
  //   }
  //   setLoadingApi(true);
  //   setDeleteLoading("");
  //   const response = await deleteAllSharedConversation();

  //   if (response.status === 200) {
  //     setDeleteLoading("true");
  //     setConfirmDelete(false);
  //     getAllConversations();
  //   } else {
  //     setDeleteLoading("");
  //     toast.error("Something went wrong!");
  //   }
  // };

  const handleCopyClick = async (id) => {
    setTimeout(async () => {
      await navigator.clipboard.writeText(`${baseUrl}/share/${id}`).then(
        function () {
          toast.success("Link copied successfully");
        },
        function () {
          toast.success("Something went wrong!");
        }
      );
    });
  };

  return (
    <div className={styles.sharedLinks}>
      {!mobileView && (<div className={styles.sharedLinks__content}>
        {!mobileView && !mobileLandscape &&
          <h6 className={styles.sharedLinks__content_title}>Shared Links</h6>
        }
        <div className={styles.table_content}></div>
      </div>)}
      <div className={styles.sharedLinks_tab}>

        <table className={styles.table}>
          {sharedLinks && sharedLinks?.length > 0 && !notFound && (
            <thead>
              <tr className={styles.table_head}>
                <th className={styles.table_head_item}> Name </th>
                <th className={styles.table_head_item}> Date shared </th>
                <th
                  className={styles.table_head_item}
                  style={{ textAlign: 'right' }}
                >
                  Action
                </th>
              </tr>
            </thead>
          )}

          <tbody className={styles.table_body}>
            {notFound && (
              <tr className={styles.no_chats}>
                <td
                  style={{
                    fontSize: "20px",
                    border: "none",
                    paddingTop: "2rem",
                  }}
                >
                  You have no shared links.
                </td>
              </tr>
            )}

            {sharedLinks &&
              sharedLinks?.length > 0 &&
              !notFound &&
              sharedLinks?.map((chat) => {
                const originalDateString = chat?.createdAt;
                const dateObject = new Date(originalDateString);

                const options = {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                };
                const formattedDate = dateObject.toLocaleDateString(
                  "en-US",
                  options
                );

                return (
                  <tr
                    key={chat?.shareConversationId}
                    className={styles.table_body_row}
                    style={{
                      opacity:
                        deleteLoading === chat?.shareConversationId
                          ? 0.5
                          : 1,
                      pointerEvents:
                        deleteLoading === chat?.shareConversationId
                          ? "none"
                          : "auto",
                    }}
                  >
                    <td className={styles.chat_title}>
                      <span>
                        <Link
                          to={`${window.location.origin}/share/${chat?.shareConversationId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#3a88ff",
                            textDecoration: "none",
                          }}
                        >
                          <span
                            style={{
                              verticalAlign: "middle",
                              paddingRight: "6px",
                            }}
                          >
                            <IoIosLink size={16} color={'black'} />
                          </span>

                          {chat?.conversation?.conversation ? (
                            <span className={styles.link_te}>
                              <Tooltip title={chat?.conversation?.conversation}>
                                {shorten(
                                  chat?.conversation?.conversation,
                                  45
                                )}
                              </Tooltip>

                            </span>
                          ) : (
                            <span className={styles.link_te}>
                              <Tooltip title={chat?.conversation?.chats[0]?.query}>
                                {shorten(
                                  chat?.conversation?.chats[0]?.query,
                                  45
                                )}
                              </Tooltip>

                            </span>
                          )}
                        </Link>
                      </span>
                    </td>
                    <td className={styles.date_sec}>{formattedDate}</td>
                    <td className={styles.button_wrapper}>
                      <Tooltip title="Copy Link">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Copy this link"
                          className={styles.button_wrapper_archive}
                          style={{ right: "3.6rem" }}
                          onClick={() =>
                            handleCopyClick(chat?.shareConversationId)
                          }
                        >

                          <IoIosLink size={19} />

                          {/* <img src={messageIcon}/> */}

                        </span>
                      </Tooltip>

                      <Tooltip title="View source chat">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="View source chat"
                          className={styles.button_wrapper_archive}
                          onClick={() =>
                            handleViewSourceChat(
                              chat?.conversation?.conversationId,
                              chat?.shareConversationId
                            )
                          }
                        >
                          <MdOutlineChatBubbleOutline size={19} />
                          {/* <img src={messageIcon}/> */}

                        </span>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Delete shared link"
                          className={styles.button_wrapper_delete}
                          onClick={
                            // () =>
                            // handleDelete(
                            //   chat?.conversation?.conversationId,
                            //   chat?.shareConversationId
                            // )
                            () => {
                              setDelOpen(true)
                              setConversationIds(chat?.conversation?.conversationId)
                              setShareConversationId(chat?.shareConversationId)
                            }
                          }
                        >
                          <img src={deleteIcon} alt="Delete" />

                          {/* <RiDeleteBin5Line size={18} /> */}
                          {/* <img src={deleteIcon}/> */}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {
        delOpen && <DeleteLinkModal
          delOpen={delOpen}
          setDelOpen={setDelOpen}
          conversationId={conversationId}
          shareConversationId={shareConversationId}
          setNotFound={setNotFound}
          setSharedLinks={setSharedLinks}
          setDeleteLoading={setDeleteLoading}
        />
      }
    </div>

  );
};

export default SharedLinks;
