import React, { useState, useEffect, Fragment } from "react";
import styles from "./LeftSidebar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
// import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteHistoryById,
  getHistoryById,
  gethistory,
  renameChat,
} from "../../store/slices/historySlice";

import AppSidebar from "../../components/sidebar/AppSidebar";
import AppSidebarClosed from "../../components/sidebar/AppSidebarClosed";
import SettingsSidebar from "../../components/sidebar/SettingsSidebar";
import SettingsSidebarClosed from "../../components/sidebar/SettingsSidebarClosed";
import { storeChats } from "../../store/slices/conversationSlice";

const LeftSideBar = ({ handleGenerateFeature }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { historyid, convoId } = useSelector((state) => state.history);

  const [chatName, setChatName] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [renameModal, setRenameModal] = useState(false);
  const [toggleHistory, setToggleHistory] = useState(false);
  const [settingsSidebar, setSettingsSidebar] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/setting")) {
      setSettingsSidebar(() => true);
    } else {
      setSettingsSidebar(() => false);
    }
  }, [location.pathname]);

  function handleDeleteChat(id) {
    dispatch(deleteHistoryById(id));
  }

  function handleChatName(e) {
    setChatName(e?.target?.value);
  }

  function handleSubmit(e) {
    e?.preventDefault();
    let payload = {
      data: {
        conversation: chatName,
      },
    };
    dispatch(
      renameChat({
        id: conversationId,
        payload,
      })
    );
    setRenameModal(false);
  }

  useEffect(() => {
    dispatch(gethistory());
  }, []);

  useEffect(() => {
    if (historyid && historyid.chats && historyid.chats.length !== 0) {
      dispatch(storeChats(historyid.chats));
    }
  }, [historyid]);

  // useEffect(() => {
  //   if (convoId) {
  //     dispatch(getHistoryById(convoId));
  //   }
  // }, [convoId]);

  return (
    <>
      {renameModal && (
        <div
          style={{
            backgroundColor: "#00000096",
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "100",
          }}
        >
          <div style={{ margin: "100px 0px" }}>
            <form onSubmit={handleSubmit}>
              <input type="text" value={chatName} onChange={handleChatName} />
              <button type="submit">SUBMIT</button>
            </form>
            <button onClick={() => setRenameModal(false)}>Cancel</button>
          </div>
        </div>
      )}

      <div
        className={styles.sidebar_wrapper}
        style={{
          maxWidth: toggleSidebar ? "55px" : "260px",
          transition: "all 0.2s ease-in-out",
          // padding: toggleSidebar ? "0.5rem" : "1rem",
        }}
      >
        {toggleSidebar ? (
          <SettingsSidebarClosed
            settingsSidebar={settingsSidebar}
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
        ) : (
          <SettingsSidebar
            settingsSidebar={settingsSidebar}
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
        )}

        {toggleSidebar ? (
          <AppSidebarClosed
            settingsSidebar={settingsSidebar}
            handleGenerateFeature={handleGenerateFeature}
            toggleHistory={toggleHistory}
            setToggleHistory={setToggleHistory}
            handleDeleteChat={handleDeleteChat}
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
        ) : (
          <AppSidebar
            settingsSidebar={settingsSidebar}
            handleGenerateFeature={handleGenerateFeature}
            toggleHistory={toggleHistory}
            setToggleHistory={setToggleHistory}
            handleDeleteChat={handleDeleteChat}
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
          />
        )}
      </div>
    </>
  );
};

export default LeftSideBar;
