import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import "./App.css";
import { PATH } from "./utils/constant";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import HomeLayout from "./layouts/homelayout/HomeLayout";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import PreviewChat from "./pages/previewChat/PreviewChat";
function App() {
  const roleWiseRouting = createBrowserRouter([
    //public routes
    ...Object.values(PATH.publicRoutes)
      .filter((route) => route.path !== PATH.publicRoutes.PREVIEW_CHAT.path)
      .map((route) => ({
        path: route.path,
        element: <PublicRoute component={route.element} />,
      })),
    {
      //private routes
      path: "/",
      element: <PrivateRoute component={HomeLayout} />,
      children: [
        ...Object.values(PATH.privateRoutes).map((child) => ({
          path: child.path,
          element: child.element,
        })),
        ...Object.values(PATH.privateRoutes).filter((child) => child?.children?.length !== 0)?.map((child) => (
          {
            path: child.path,
            element: child.element,
            children: [
              ...Object.values(child?.children?.[0] || {})?.map((e) => ({
                path: e?.path,
                element: e?.element
              }))
            ]
          })),
        {
          path: "/",
          element: <Navigate to={PATH.privateRoutes.HOME.path} />,
        },
        {
          path: "*",
          element: <Navigate to={PATH.privateRoutes.HOME.path} />,
        },
      ],
    },
    {
      path: PATH.publicRoutes.PREVIEW_CHAT.path, // Directly render PreviewChat for PREVIEW_CHAT route
      element: <PreviewChat />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  
  return (
    <div className="app">
      <ToastContainer autoClose={1200} position="top-right" />

      <RouterProvider router={roleWiseRouting} />
    </div>
  );
}

export default App;
