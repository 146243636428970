import React, { useEffect, useState } from "react";
import styles from "../../layouts/homelayout/LeftSidebar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import upArrow from "../../assets/svg/uparrow.svg";
import profileICon from "../../assets/svg/profile-icon.svg";
import accountIcon from "../../assets/svg/account-icon.svg";
import billingIcon from "../../assets/svg/billing-icon.svg";
import chatHistoryIcon from "../../assets/svg/chat-history.svg";
import feedbackIcon from "../../assets/svg/feedback-icon.svg";
import contactUsIcon from "../../assets/svg/contactus-icon.svg";
import shareIcon from "../../assets/svg/share.svg";
import logoutIcon from "../../assets/svg/logout-icon.svg";
import vsCodeIcon from "../../assets/svg/vs-code-icon.svg";
import FeatureButton from "../../shared/button/FeatureButton";
import LogoutModal from "../modals/LogoutModal";
import collapse from "../../assets/svg/collapse-toggle.svg";
import Zoom from "@mui/material/Zoom";
import { LightTooltip } from "../../utils";
import { useSelector } from "react-redux";
import logom from "../../assets/svg/logoMain.svg"

import { useDispatch } from "react-redux";
import { clearHistory, setHistory } from "../../store/slices/historySlice";
import { newChatHandler } from "../../store/slices/conversationSlice";
const SettingsSidebar = ({ settingsSidebar, setToggleSidebar }) => {
  const navigate = useNavigate();
  const { loading, userInfo, error } = useSelector((state) => state.profile);

  const location = useLocation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Profile");
  const [logout, setLogout] = useState(false);

  const handleLogout = () => {
    setLogout(true);
  };

  const handleLogoutClear = () => {
    localStorage.clear();
    dispatch(clearHistory());
    dispatch(newChatHandler());
    navigate("/login");
  };
  useEffect(() => {
    if (location?.pathname === "/setting/profile") {
      setActiveTab("Profile");
    } else if (location?.pathname === "/setting/account") {
      setActiveTab("Account");
    } else if (location?.pathname === "/setting/chat-history") {
      setActiveTab("Chat History");
    } else if (location?.pathname === "/setting/feedback") {
      setActiveTab("Feedback");
    } else if (location?.pathname === "/setting/shared-links") {
      setActiveTab("Manage Shared Links");
    } else if (location?.pathname === "/setting/download-vscode") {
      setActiveTab("Download VS Code");
    }
    // Reset active tab to "Profile" whenever the location changes
  }, [location]);

  return (
    <div
      style={{
        transform: settingsSidebar ? "translateX(0)" : "translateX(-260px)",
        whiteSpace: "nowrap",
      }}
      className={styles.leftsidebar}
    >
      <div className={styles.leftsidebar__top}>
        <div className={styles.logo_wrapper}>
          <div
            className={styles.left_align}
          // style={{ display: toggleSidebar ? "none" : "block" }}
          >
            <Link to="/">
              <img src={logom} alt="qx-logo" className={styles.mainLogoImage} />
            </Link>
            <Link to="/" className={styles.sidebar_logo}>

              <span className={styles.sidebar_logo_text}>Text2Code</span>
            </Link>
            {/* <br /> */}
            {/* <Link to="/" className={styles.plus_plans}>
              <span> Upgrade To Plus </span>
            </Link> */}
          </div>
          <div>
            <LightTooltip
              title="Close Sidebar"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                onClick={() => setToggleSidebar(true)}
                style={{ cursor: "pointer" }}
              >
                <img
                  className={styles.collapse_icon}
                  src={collapse}
                  alt="collapse"
                />
              </div>
            </LightTooltip>
          </div>
        </div>

        {/* {
        loading ? 
        (
          <SettingSideBarSkeleton />
        ) 
        : ( */}
        <div className={styles.sidebar_menu}>
          <FeatureButton
            title="Profile"
            icon={profileICon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/profile")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />
          <FeatureButton
            title="Account"
            icon={accountIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/account")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />
          {/* <FeatureButton
            title="Billing"
            icon={billingIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/billing")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          /> */}

          <FeatureButton
            title="Chat History"
            icon={chatHistoryIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/chat-history")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />

          <FeatureButton
            title="Feedback"
            icon={feedbackIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/feedback")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />

          {/* <FeatureButton
              title="Contact Us"
              icon={contactUsIcon}
              fromSettings={true}
              clickHandler={() => navigate("/setting/contact-us")}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              className={styles?.border_none}
            /> */}
          <FeatureButton
            title="Manage Shared Links"
            icon={shareIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/shared-links")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />
          <FeatureButton
            title="VS Code Extension"
            icon={vsCodeIcon}
            fromSettings={true}
            clickHandler={() => navigate("/setting/download-vscode")}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={styles?.border_none}
          />
        </div>
      </div>

      <div className={styles.leftsidebar__bottom}>
        <div className={styles.settings_wrapper}>
          <LightTooltip
            title="Go Back"
            placement="right-start"
            TransitionComponent={Zoom}
          >
            <span
              className={styles.backIcon}
              onClick={() => {
                navigate("/");
                setActiveTab(() => "Profile");
              }}
            >
              <img src={upArrow} alt="back" />
            </span>
          </LightTooltip>

          <div className={styles.logout_button} onClick={handleLogout}>
            <img src={logoutIcon} alt="setting" />
            <span> Logout </span>
          </div>
        </div>
      </div>
      {logout && (
        <LogoutModal
          logout={logout}
          setLogout={setLogout}
          handleLogout={handleLogout}
          handleLogoutClear={handleLogoutClear}
        />
      )}
    </div>
  );
};

export default SettingsSidebar;
