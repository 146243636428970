import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import downArrowIcon from "../../assets/png/down-arrow.png"
import stylesTopBar from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark'
import upArrow from "../../assets/svg/uparrow.svg";
import styles from './skeleton.module.scss';

const SkeletonLoader = ({ skeletonType }) => {
    const featureButtonSkeletonContainer = {
        width: "100%",
        backgroundColor: "transparent",
        border: "solid gray 2px",
        borderRadius: "38px",
        padding: "0.25rem",
        height: "20px",
        opacity: "0.9",
        display: "table",
    }

    const featureButtonSkeletonContainerSettingSidebar = {
        width: "100%",
        backgroundColor: "transparent",
        padding: " 0.25rem",
        height: "24px",
        opacity: "0.9",
        display: "table",
        textAlign: "left",
        marginBottom: ".5rem",
    };

    return (<>
        {skeletonType === "LandingScreen" && <SkeletonTheme baseColor="#E3E3E3">
            <div style={{ display: "flex", width: "100%" }}>
                <div className={styles.stripAndBoxContainer}>
                    <div style={{ width: "100%", marginBottom: "1.75rem" }}>
                        <Skeleton
                            enableAnimation={false}
                            height={"40px"}
                            width={"95%"}
                        />
                    </div>
                    {Array(12)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                style={{ width: "25%", marginBottom: "15px" }}
                                key={index}
                            >
                                <Skeleton
                                    key={index}
                                    height={"140px"}
                                    width={"90%"}
                                    borderRadius={"14px"}
                                    enableAnimation={false}
                                />
                            </div>
                        ))}
                </div>
                <div className={styles.sliderDots} >
                    <Skeleton
                        enableAnimation={false}
                        circle={true}
                        height={"12px"}
                        width={"12px"}
                        style={{ background: "#465A65" }}
                    />
                    <Skeleton
                        enableAnimation={false}
                        circle={true}
                        height={"12px"}
                        width={"12px"}
                    />
                    <Skeleton
                        enableAnimation={false}
                        circle={true}
                        height={"12px"}
                        width={"12px"}
                    />
                </div>
            </div>
        </SkeletonTheme>
        }

        {skeletonType === "Intro" && <SkeletonTheme baseColor="#BEBEBE" highlightColor="#9A9A99" ><div className={styles.skeletonMain}>
            <div style={{ width: "100%" }}>
                <Skeleton height={"107px"} width={"77px"} style={{ marginBottom: ".25rem" }} borderRadius={0} />
                <Skeleton height={"44px"} width={"75%"} style={{ marginBottom: ".25rem" }} borderRadius={0} />
                <Skeleton height={"20px"} width={"45%"} style={{ marginBottom: ".25rem" }} borderRadius={0} />
            </div>
            <div style={{ width: "50%" }}><Skeleton height={"20px"} width={"25%"} borderRadius={0} /></div>
            <div className={styles.suggestionContainer}>{
                Array(4)
                    .fill(0)
                    .map((_, i) => (

                        <div style={{ textAlign: "left", width: "20%" }} key={i}>
                            <div >
                                <Skeleton circle width={40} height={40} />
                            </div>
                            <div >
                                <Skeleton width={"100%"} style={{ marginBottom: ".25rem" }} />
                                <Skeleton width={"100%"} style={{ marginBottom: ".25rem" }} />
                                <Skeleton width={"80%"} style={{ marginBottom: ".25rem" }} />
                                <Skeleton width={"80%"} style={{ marginBottom: ".25rem" }} />
                                <Skeleton width={"50%"} style={{ marginBottom: ".25rem" }} />

                            </div>
                        </div>

                    ))}</div>
        </div>
        </SkeletonTheme>}

        {skeletonType === "ChatHistorySidebar" &&
            <SkeletonTheme baseColor="#9A9A99">
                <div style={{ position: "relative" }}>
                    <div className={styles.chatHistoryContainer}>
                        <div style={{ position: "absolute", top: "4px", right: "12px" }}>
                            <img src={upArrow} alt="arrow" />
                        </div>
                        <div className={styles.chatHistoryContainerItem}>
                            {Array(6).fill(0).map((item, index) => (
                                <div key={index}>
                                    {index === 1 ? (
                                        <div style={{ ...featureButtonSkeletonContainer, backgroundColor: "#323332" }}>
                                            <Skeleton enableAnimation={false} height="10px" width="90%" borderRadius={0} style={{ marginLeft: ".5rem" }} />
                                        </div>
                                    ) : (
                                        index === 0 || index === 2 ? (
                                            <Skeleton enableAnimation={false} height="10px" width="10%" borderRadius={0} style={{ marginLeft: ".5rem" }} />
                                        ) : (
                                            <Skeleton enableAnimation={false} height="10px" width="90%" borderRadius={0} style={{ marginLeft: ".5rem" }} />
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </SkeletonTheme>
        }
        {
            skeletonType === "ChatHistory" && <SkeletonTheme baseColor="#BEBEBE " highlightColor="#9A9A99">
                <div className={styles.mainContainer}>
                    <div style={{ width: "15%", marginLeft: "2rem" }}>
                        <Skeleton
                            borderRadius={0}
                            width={"100%"}
                            style={{ marginBottom: "0.5rem" }}
                        />
                        <div className={styles.barPipe}></div>
                    </div>
                    <div className={styles.chatStripContainer}>
                        <div className={styles.chatStripContainerFirstItem}>
                            <Skeleton borderRadius={0} width={"70%"} />
                        </div>
                        <div style={{ width: "30%", padding: ".25rem" }}>
                            <Skeleton borderRadius={0} width={"70%"} />
                        </div>
                        <div style={{ width: "30%", padding: ".25rem" }}>
                            <Skeleton borderRadius={0} width={"70%"} />
                        </div>
                    </div>
                    <div style={{ width: "15%", marginLeft: "2rem" }}>
                        <Skeleton
                            borderRadius={0}
                            width={"100%"}
                            style={{ marginBottom: "0.5rem" }}
                        />
                    </div>
                    <div className={styles.mainStripContainer}>
                        <div className={styles.mainStripContainerFirstItemContainer}>
                            <div className={styles.mainStripContainerFirstItem}>
                                <span className={styles.pipe}></span>
                                <Skeleton borderRadius={"0"} width={"45%"} />
                            </div>
                            <div className={styles.mainStripContainerSecondItemsContainer}>
                                <div style={{ padding: ".5rem", width: "10%" }}>
                                    <Skeleton borderRadius={"0"} height={"24px"} width={"50%"} />
                                </div>
                                <div
                                    style={{
                                        padding: ".5rem",
                                        width: "10%",
                                        marginRight: "1.75rem",
                                    }}
                                >
                                    <Skeleton borderRadius={"0"} height={"24px"} width={"50%"} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.lastContainer}>
                            <div style={{ padding: ".5rem", textAlign: "left", width: "100%" }}>
                                {Array(14)
                                    .fill(0)
                                    .map((item, index) => {
                                        const isHalfWidth =
                                            index === 2 || index === 7 || index === 11;
                                        const marginToLeft =
                                            index === 2 || index === 7 || index === 11 ? "5px" : "20px";
                                        return (
                                            <React.Fragment key={index}>
                                                <Skeleton
                                                    borderRadius={"0"}
                                                    width={isHalfWidth ? "12%" : "25%"}
                                                    style={{
                                                        marginBottom: "0.65rem",
                                                        marginLeft: marginToLeft,
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </SkeletonTheme>
        }
        {skeletonType === "Profile" && <SkeletonTheme baseColor="#E3E3E3" highlightColor="#9A9A99">
            <div className={styles.mainSkeletonContainerProfile}>
                <div style={{ width: "10%", marginLeft: "4rem" }}>
                    <Skeleton height={"20px"} width={"100%"} />
                </div>

                <div className={styles.mainStripSkeletonProfile}>
                    <div className={styles.mainStripSkeletonProfileFirstContainer}>
                        <div className={styles.mainStripSkeletonProfileFirstContainerFirstItem}>
                            <Skeleton borderRadius={"0"} width={"30%"} />
                        </div>
                        <div className={styles.mainStripSkeletonProfileFirstContainerSeconditem}>
                            <div style={{ width: "100%" }}>
                                <Skeleton borderRadius={"0"} width={"50%"} />
                            </div>
                            <div
                                style={{
                                    marginRight: "3.75rem",
                                    padding: ".5rem",
                                    width: "100%",
                                }}
                            >
                                <Skeleton borderRadius={"0"} width={"50%"} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.skeletonSecondContainerProfile}>
                        <div style={{ padding: ".5rem", textAlign: "left", width: "100%" }}>
                            {Array(4)
                                .fill(0)
                                .map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Skeleton
                                            borderRadius={"0"}
                                            width={"50%"}
                                            style={{ marginBottom: "0.5rem" }}
                                        />
                                    </React.Fragment>
                                ))}
                        </div>

                        <div className={styles.skeletonSecondContainerProfileSecondItem}>
                            <div style={{ width: "100%" }}>
                                {Array(4)
                                    .fill(0)
                                    .map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Skeleton
                                                borderRadius={"0"}
                                                width={"70%"}
                                                style={{ marginBottom: "0.5rem" }}
                                            />
                                        </React.Fragment>
                                    ))}
                            </div>

                            <div style={{ width: "100%" }}>
                                {Array(4)
                                    .fill(0)
                                    .map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Skeleton
                                                borderRadius={"0"}
                                                width={"70%"}
                                                style={{ marginBottom: "0.5rem" }}
                                            />
                                        </React.Fragment>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>}
        {skeletonType === "TopBar" && <SkeletonTheme baseColor="#BEBEBE" highlightColor="#9A9A99" >
            <div className={styles.navBarStyle}>
                <div className={styles.navBarFirstItem}>
                    <Skeleton borderRadius={0} width={"40px"} height={"40px"} />
                    <div style={{ width: "50%" }}>
                        <Skeleton borderRadius={0} width={"90%"} />
                    </div>
                    <div className={stylesTopBar.profile_div_downArrow}
                        variant="contained">
                        <img src={downArrowIcon} alt="downArrow" />
                    </div>
                </div>
                <div className={styles.navBarSecondItem}>
                    <div className={styles.navBarSecodItemFirstChild}>
                        <div >
                            <Skeleton circle width={40} height={40} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
                            <Skeleton borderRadius={0} width={"60%"} style={{ margin: ".25rem" }} />
                            <Skeleton borderRadius={0} width={"60%"} style={{ margin: ".25rem" }} />
                        </div>
                    </div>
                    <div className={styles.navBarSecondItemSecondChild}>
                        <div>
                            <Skeleton circle width={40} height={40} style={{ margin: ".25rem" }} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Skeleton borderRadius={0} width={"60%"} style={{ margin: ".25rem" }} />
                            <Skeleton borderRadius={0} width={"60%"} style={{ margin: ".25rem" }} />
                        </div>
                        <div className={stylesTopBar.profile_div_downArrow} style={{ marginRight: ".5rem" }}
                            variant="contained">
                            <img src={downArrowIcon} alt="downArrow" />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>
        }

        {/* Setting sider bar is not being used in code as of now :// Todo needs to confirm

         {skeletonType === "SettingSidebar" && <SkeletonTheme baseColor="#BEBEBE" highlightColor="#9A9A99">
            <div style={{ width: "100%", marginTop: "2.75rem" }}>
                {Array(8)
                    .fill(0)
                    .map((item, index) => (
                        <div
                            key={index}
                            style={{
                                ...featureButtonSkeletonContainerSettingSidebar,
                                ...(index === 0
                                    ? {
                                        backgroundColor: "#666666",
                                        height: "20px",
                                        paddingLeft: ".75rem",
                                    }
                                    : {}),
                            }}
                        >
                            {index === 0 && <span className={styles.settingSideBarPipe}></span>}
                            <Skeleton
                                height="14px"
                                borderRadius={0}
                                enableAnimation={false}
                                width="70%"
                            />
                        </div>
                    ))}
            </div>
        </SkeletonTheme>} */}
    </>
    )
}

export default SkeletonLoader
