import React, { useState } from "react";
import styles from "../../layouts/loginlayout/LoginLayout.module.scss";
import { useNavigate } from "react-router-dom";
import {
  alphabetsRegex,
  emailRegex,
  mobileRegex,
  numberRegex,
  passwordRegex,
} from "../../utils/regex";
import LoginLayout from "../../layouts/loginlayout/LoginLayout";
import * as Yup from "yup";
import { onlyNumbersInput, requiredMessage } from "../../utils/index";
import { Form, Formik } from "formik";
import FormikControl from "../../components/formikcontrols/FormikControl";
import Button from "../../shared/button/Button";
import { PATH } from "../../utils/constant";
import SocialSignUp from "../../components/sociallogin/SocialLogin";
import { userRegister } from "../../services/userService";
import { REGISTER } from "../../services/apiEndpoints";
import { toast } from "react-toastify";
import erronIcon from "../../assets/svg/error-icon.svg";
import LoaderGif from "../../components/loaderGif/LoaderGif";
import { useEffect } from "react";
import { useRef } from "react";

const Register = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [name, setName] = useState({ fullName: "" });
  const initialValues = {
    name: "",
    email: "",
    password: "",
    // check: false
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(requiredMessage("First Name"))
      .min(3, "Minimum 3 characters required")
      .max(25, "Maximum 25 characters allowed!")
      .matches(alphabetsRegex, "Only letters allowed!"),
    email: Yup.string()
      .required(requiredMessage("Email ID"))
      .matches(emailRegex, "Invalid email format!"),
    password: Yup.string()
      .required(requiredMessage("Password"))
      .min(6, "Password must be at least 6 characters")
      .max(25, "Password must not exceed 25 characters")
      .test(
        "no-spaces",
        "Password must not contain spaces",
        (value) => !/\s/.test(value)
      ),
    // check: Yup.boolean()
    //   .oneOf([true], "Please accept the Terms & Conditions of AskQX.")
  });
  const handleSubmit = async (values) => {
    const fullName = values.name.trim();
    const splitName = fullName.split(" ");
    let firstName = "";
    let lastName = "";

    if (splitName.length === 1) {
      firstName = splitName[0];
    } else {
      firstName = splitName.slice(0, -1).join(" ");
      lastName =
        splitName.slice(-1)[0].charAt(0).toUpperCase() +
        splitName.slice(-1)[0].slice(1);
    }
    let payload = {
      nickName: "",
      firstName: firstName,
      lastName: lastName,
      emailId: values.email,
      password: values.password,
      campaign: {
        utmId: "",
        utmSource: "",
        utmMedium: "",
        utmName: "",
        utmTerm: "",
        utmContent: "",
      },
      platform: "WEB",
    };
    try {
      let res = await userRegister(payload);

      if (res?.isNewUser) {
        sessionStorage.setItem("isNewUser", res?.isNewUser);
      }

      if (res?.userDetails) {
        localStorage.setItem("userId", res?.userDetails?.userId);
        localStorage.setItem("email", values.email);
        navigate("/otp-verification");
      } else {
        toast.error("Something went wrong!");
      }
    } catch (err) {
      // toast.error(err.response?.data?.error?.message);
      setShowError(true);
      setErrorMessage(err?.response?.data?.error?.message);
    }
  };
  const formikRef = useRef(null); // Create a ref

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm(); // Reset the form when component mounts
    }
  }, []);


  return (
    <LoginLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(formik) => {
          return (
            <div className={styles.login_sec}>
              <div className={styles.mainDiv}>
                <p className={`${styles.heading} text-center`}>Sign up</p>
                {/* <p className={styles.subHeading}>Let's sign up here</p> */}
                {showError && (
                  <div className="error-message-div">
                    <span>
                      <img src={erronIcon} />
                      {"This email address is already registered."}
                    </span>
                  </div>
                )}
                <Form className={styles.form} autoComplete="off">
                  <FormikControl
                    name="name"
                    control="input"
                    label="Enter Full Name"
                    placeholder="Your Full Name"
                    isRequired={true}
                    setShowError={setShowError}
                  />
                  <FormikControl
                    name="email"
                    control="input"
                    label="Email ID"
                    placeholder="Your Email"
                    isRequired={true}
                    setShowError={setShowError}
                    autoComplete="off"
                  />
                  <FormikControl
                    name="password"
                    control="password"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    isRequired={true}
                    setShowError={setShowError}
                    autoComplete="off"
                  />
                  {/* <div className={styles.checkbox_div}>
                  <input type="checkbox" />
                  <span>
                    I accept the Terms & Conditions of AskQX.
                  </span>
                </div> */}
                  {/* <FormikControl
                  name="check"
                  control="checkbox"
                  label="Terms & Conditions"
                  isRequired={true}
                /> */}

                  <Button
                    type="submit"
                    className={styles.button}
                    disabled={formik.isSubmitting}
                  >
                    {formik.isSubmitting ? <LoaderGif /> : "Sign Up"}
                  </Button>
                </Form>
                <div className={styles.sperator_div}>
                  <span className={styles.line_text}></span>
                  <span>or</span>
                  <span className={styles.line_text}></span>
                </div>
                <SocialSignUp />
                <div className={styles.terms_sec}>
                  <p className={styles.terms_text}>
                    By Signing up you agree to our&nbsp;
                    <span>
                      {/* <a
                      href="https://askqx.qxlabai.com/terms-of-use.html"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                      Terms of Service
                      {/* </a> */}
                    </span>
                  </p>
                </div>
              </div>
              <p className={styles.bottomText_end}>
                Already have an account?{' '}
                <span
                  className="link-text"
                  onClick={() => navigate(PATH.publicRoutes.LOGIN.path)}
                >
                  Sign in
                </span>
              </p>
            </div>
          );
        }}
      </Formik>
    </LoginLayout>
  );
};

export default Register;
