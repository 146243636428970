import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import SocialLoginButton from "./SociaButton";
import {
  google_api,
  linkedin_api,
  socialLogin,
} from "../../services/userService";
import { LINKEDIN_LOGIN, USER_SOCIAL_LOGIN } from "../../services/apiEndpoints";
import { toast } from "react-toastify";
const SocialSignUp = () => {
  const location = useLocation();
  const path = location.pathname;
  //   const auth = useContext(AuthContext);
  //   const utm_source = localStorage.getItem("utm_source");
  //   paramsExists(location.search);
  const navigate = useNavigate();
  //   let gaEventTracker3 = useAnalyticsEventTracker("google_signup");
  //   let gaEventTracker4 = useAnalyticsEventTracker("linkedin_signup");
  //   let gaEventTracker5 = useAnalyticsEventTracker("google_signup_initiated");
  //   let gaEventTracker6 = useAnalyticsEventTracker("linkedin_signup_initiated");
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "openid email profile",
    onSuccess: (code) => { },
    onError: (error) => {
      console.log(error);
    },
  });

  const customLogicBeforeLogin = async () => {
    // logEvent(analytics, "Sign_In_With_Google", {
    //     time: new Date().getTime(),
    // });
    // logFirebaseEvent(
    //   path === "/login" ? "Sign_In_With_Google" : "Sign_Up_With_Google",
    //   {
    //     time: new Date().getTime(),
    //   }
    // );
    // gaEventTracker5("google_signup_initiated", "google_signup_initiated");
    login();
  };
  const customLogicBeforeLinkedinLogin = () => {
    // logEvent(analytics, "Sign_In_With_LinkedIn", {
    //     time: new Date().getTime(),
    // });
    // logFirebaseEvent(
    //   path === "/login" ? "Sign_In_With_LinkedIn" : "Sign_Up_With_LinkedIn",
    //   {
    //     time: new Date().getTime(),
    //   }
    // );
    // gaEventTracker6("linkedin_signup_initiated", "linkedin_signup_initiated");
    linkedInLogin();
  };

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      const params = {
        access_token: response?.access_token,
      };
      const data = await google_api(params);
      const { email, family_name, given_name, picture, sub } = data;
      try {
        let login = await socialLogin({
          nickName: "",
          firstName: given_name,
          lastName: family_name,
          emailId: email,
          profilePhoto: picture,
          socialId: sub,
          group: "GOOGLE",
          campaign: {
            utmId: "",
            utmSource: "",
            utmMedium: "",
            utmName: "",
            utmTerm: "",
            utmContent: ""
          },
          platform: "WEB",
        });
        if (login?.isNewUser) {
          sessionStorage.setItem("isNewUser", login?.isNewUser);
          localStorage.setItem("newUser", login?.isNewUser);

        }
        if (login?.tokens?.accessToken) {
          localStorage.setItem("access", login?.tokens?.accessToken);
        }
        if (login?.tokens?.refreshToken) {
          localStorage.setItem("refresh", login?.tokens?.refreshToken);
        }
        if (login?.userDetails) {
          toast.success("Successfully Logged in!!")
          localStorage.setItem("email", login.userDetails.emailId);
          localStorage.setItem(
            "firstName",
            login.userDetails.firstName
          );
          localStorage.setItem(
            "lastName",
            login.userDetails.lastName
          );
          localStorage.setItem("status", login.userDetails.status);
          localStorage.setItem("userId", login.userDetails.userId);
          localStorage.setItem(
            "profilePhoto",
            login.userDetails.profilePhoto
          );
          navigate("/home");
        }

      } catch (err) {
        toast?.error(err?.message)
      }
    },
  });
  useEffect(() => {
    const receiveMessage = async (event) => {
      if (event.data) {
        const { code, state } = event.data;
        if (code && state) {
          try {
            let data = await linkedin_api({

              code: code,
              redirectUrl: `${window.location.origin}/linkedin`,

            });
            if (data?.userInfo) {
              const { email, family_name, given_name, picture, sub } =
                data?.userInfo;
              let login = await socialLogin({
                nickName: "",
                firstName: given_name,
                lastName: family_name,
                emailId: email,
                profilePhoto: picture,
                socialId: sub,
                group: "LINKEDIN",
                campaign: {
                  utmId: "",
                  utmSource: "",
                  utmMedium: "",
                  utmName: "",
                  utmTerm: "",
                  utmContent: ""
                },
                platform: "WEB",
              });
              if (login?.isNewUser) {
                sessionStorage.setItem("isNewUser", login?.isNewUser);
                localStorage.setItem("newUser", login?.isNewUser);

              }
              if (login?.tokens?.accessToken) {
                localStorage.setItem("access", login?.tokens?.accessToken);
              }
              if (login?.tokens?.refreshToken) {
                localStorage.setItem("refresh", login?.tokens?.refreshToken);
              }
              if (login?.userDetails) {
                toast.success("Successfully Logged in!!")
                localStorage.setItem("email", login.userDetails.emailId);
                localStorage.setItem(
                  "firstName",
                  login.userDetails.firstName
                );
                localStorage.setItem(
                  "lastName",
                  login.userDetails.lastName
                );
                localStorage.setItem("status", login.userDetails.status);
                localStorage.setItem("userId", login.userDetails.userId);
                localStorage.setItem(
                  "profilePhoto",
                  login.userDetails.profilePhoto
                );
                navigate("/home");
              }

            } else {
              toast.error("Something Went Wrong!!");
              navigate("/register");
            }
            // if (data?.data?.success) {
            //   if (data?.data?.data?.error) {
            //     toast.error(data?.data?.data?.error);
            //   
            //   }
            //   let user_details = data?.data?.data?.userInfo;
            //  
          } catch (err) {
            console.log(err?.message);
            navigate("/register");
          }
        }
      }
    };
    // Add event listener for the 'message' event
    window.addEventListener("message", receiveMessage);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <SocialLoginButton
        customLogicBeforeLogin={customLogicBeforeLogin}
        customLogicBeforeLinkedinLogin={customLogicBeforeLinkedinLogin}
        login={login}
        linkedInLogin={linkedInLogin}
      />
    </>
  );
};
export default SocialSignUp;
