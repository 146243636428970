import React, { useEffect, useState } from "react";
import styles from '../../layouts/loginlayout/LoginLayout.module.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { emailRegex, passwordRegex } from "../../utils/regex";
import LoginLayout from "../../layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, requiredMessage } from "../../utils/index";
import { Form, Formik } from 'formik';
import FormikControl from "../../components/formikcontrols/FormikControl";
import { PATH } from "../../utils/constant";
import Button from "../../shared/button/Button";
import { ReactComponent as VerifiedIcon } from "../../assets/svg/verified.svg"
import { ReactComponent as BackIcon } from "../../assets/svg/arrow-left.svg"
import { userForgotPassword, userResendOtp, userResetPassword } from "../../services/userService";
import { RESEND_OTP, USER_FORGOT_PASSWORD, USER_RESET_PASSWORD } from "../../services/apiEndpoints";
import { toast } from "react-toastify";
import erronIcon from '../../assets/svg/error-icon.svg'

const VerifyPassword = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const passwordInstructions = [
    "contain atleast 1 lowercase character",
    "contain atleast 1 uppercase character",
    "contail atleast 1 special character",
    "be at least 8 characters long",
    "be maximum 20 characters long"
  ]

  const initialValues = {
    otp: "",
    password: "",
    confirm: "",
  }

  const validationSchema = Yup.object({
    otp: Yup.string()
      .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
      .required(requiredMessage('OTP')),
    // password: Yup.string()
    //   .required(requiredMessage("Password"))
    //   .matches(passwordRegex, "Invalid Password Format"),
    password: Yup.string()
    .required(requiredMessage("Password"))
    .min(6, "Password must be at least 6 characters")
    .max(25, "Password must not exceed 25 characters")
    .test(
      "no-spaces",
      "Password must not contain spaces",
      (value) => !/\s/.test(value)
    ),
    confirm: Yup.string()
      .required(requiredMessage("Password"))
      .when("password", ([password], schema) => {
        return schema.test("passwordMatch", "Passwords don't match!", (value) => {
          return value === password
        })
      })
  })

  const login = (values) => {
    // return publicRequest.post(LOGIN, values).then((res) => {
    //     localStorage.setItem("token", res?.data?.token)
    //     localStorage.setItem("currentUser", JSON.stringify(res?.data || {}))
    //     localStorage.setItem("email", values.email)
    //     navigate("/")
    // })
  }

  const handleSubmit = async (values) => {
    let user_id = localStorage.getItem("userId")
    let payload = {
      password: values?.confirm,
      verificationCode: String(values?.otp)
    }
    try {
      let res = await userResetPassword(payload, {
        "x-user-id": user_id
      })
      if (res?.isUpdated) {
        toast.success("Password Updated Successfully")
        navigate("/home")
      } else {
        toast?.error("Something went wrong!")
      }
    } catch (err) {
      // toast.error(err?.message)
      setShowError(true)
      setErrorMessage(err?.message)

    }

  };
  async function handleSendOtp() {
    startCountdown()
    setResendDisabled(true);
    let payload = {
      emailId: localStorage.getItem("email")
    }

    try {
      let res = await userForgotPassword(payload)

      if (res?.userDetails) {
        toast.success("otp sent successfully!")
      }
    } catch (err) {
      toast.error(err?.message)
      setResendDisabled(false);
    }
  }
  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      setResendDisabled(false);
      setCountdown(60);
    }, 60000);
  };
  useEffect(() => {
    startCountdown()
    setResendDisabled(true);
    // refArray[0].current.focus()
    return () => {
      clearInterval(startCountdown)
    }
  }, [])

  return (
    <LoginLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          return (
            <div className={styles.login_sec}>
              <div className={styles.mainDiv}>
                {/* <BackIcon
                onClick={() => navigate(PATH.publicRoutes.FORGOT_PASSWORD.path)}
                className={styles.backIcon}
              /> */}
                <p className={styles.heading}>Verify OTP</p>
                {
                  showError && <div className="error-message-div">
                    <span>
                      <img src={erronIcon} />
                      {errorMessage}</span>
                  </div>
                }
                <Form className={styles.form} autoComplete="off">
                  <FormikControl
                    name="otp"
                    control="input"
                    type="number"
                    label="Verify Otp"
                    placeholder="Verify Otp"
                    isRequired={true}
                    setShowError={setShowError}
                  />
                  <FormikControl
                    name="password"
                    control="password"
                    type="password"
                    label="Password"
                    placeholder="New Password"
                    isRequired={true}
                    setShowError={setShowError}

                  />
                  <FormikControl
                    name="confirm"
                    control="password"
                    type="password"
                    label="Re-enter Password"
                    placeholder="Confirm Password"
                    isRequired={true}
                    setShowError={setShowError}

                  />


                  <Button
                    type="submit"
                    className={styles.button}
                    disabled={formik.isSubmitting}
                  >
                    Reset Password
                  </Button>
                  {
                    resendDisabled ? (
                      <p className={styles.timer_btn}>
                        <span>
                          {`Please wait ${Math.floor(countdown / 60)}:${("0" + (countdown % 60)).slice(
                            -2
                          )} to resend OTP`}
                        </span>
                      </p>
                    ) : (
                      <p className={styles.timer_btn}
                        onClick={() => {
                          handleSendOtp()
                        }}
                      >

                        Didn't get OTP? <span
                          className="link-text"
                          disabled={resendDisabled}

                        >
                          Resend
                        </span>
                      </p>

                    )
                  }
                </Form>
                {/* <div className={styles.passwordNotes}>
                                <strong>Note: </strong>
                                Password must -
                                <ul>
                                    {passwordInstructions.map(instruction => (
                                        <li>{instruction}</li>
                                    ))}
                                </ul>
                            </div> */}
              </div>
            </div>
          )
        }}
      </Formik>
    </LoginLayout>
  );
};

export default VerifyPassword;

