import axios from "axios";
import commonRequest from "./main";


const baseURL = process.env.REACT_APP_CODE_BASE_URL
const apiKey = process.env.REACT_APP_CODE_API_KEY

const setHeaders = (baseUrl, apiKey, header = {}) => ({
  baseURL: baseUrl,
  headers: {
    'x-api-key': apiKey,
    ...header
  },
})

const getAllLangauges = async () => {
  try {
    const response = await commonRequest.get("/language", setHeaders(baseURL, apiKey));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const addFeature = async (payload) => {
  try {
    const response = await commonRequest.post("/feature", payload, setHeaders(baseURL, apiKey));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const postSnippet = async (payload, obj) => {
  try {
    const response = await commonRequest.post("/save-snippet", payload, setHeaders(baseURL, apiKey, obj));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const getSnippet = async (payload, obj) => {
  try {
    const response = await commonRequest.get("/save-snippet", setHeaders(baseURL, apiKey, obj));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const deleteSnippet = async (id) => {
  try {
    const response = await commonRequest.delete(`/save-snippet/${id}`, setHeaders(baseURL, apiKey));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const getSnippetById = async (id) => {
  try {
    const response = await commonRequest.get(`/save-snippet/${id}`, setHeaders(baseURL, apiKey));
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};



export { getAllLangauges, addFeature, postSnippet, getSnippet, getSnippetById, deleteSnippet };
