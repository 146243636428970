import { useState, useEffect } from "react";
import styles from "./Button.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setActiveLanguage,
  setOpenTranslateModal,
} from "../../store/slices/editorSlice";
import { toast } from "react-toastify";

const FeatureButton = ({
  title,
  icon,
  fromSettings,
  clickHandler,
  activeTab,
  setActiveTab,
  className,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { languages, activeLanguage } = useSelector((state) => state.language);

  const activeTabData = activeLanguage.tabs.find((tab) => tab.isActive);

  const handleClick = () => {
    clickHandler();

    // setactiveTabData(() => title);
    if (location.pathname === "/code-editor") {
      if (title === "Generate Code") {
        navigate("/");
      } else if (title === "Convert Code") {
        if (activeTabData?.content?.length > 0) {
          dispatch(setOpenTranslateModal(true));
        } else {
          toast.error("No code detected");
        }
      } else {
        // clickHandler();
      }
    } else {
      if (title !== "Generate Code") {
        navigate("/code-editor");

        if (!activeLanguage.responseValue) {
          const bucket = localStorage.getItem("bucket");
          const parsedBucket = JSON.parse(bucket);

          if (parsedBucket === null) {
            const lastUntitledIndex = activeLanguage?.tabs?.reduce(
              (acc, tab) => {
                const match = tab.lang.name.match(/untitled(\d+)/);
                if (match) {
                  return Math.max(acc, parseInt(match[1], 10));
                }
                return acc;
              },
              0
            );

            const nextUntitledIndex = lastUntitledIndex + 1;
            dispatch(
              setActiveLanguage({
                value: "",
                responseValue: "",
                featureType: "",
                name: languages[0]?.language,
                extension: languages[0]?.extension,
                languageId: languages[0]?.languageId,
                langIcon: languages[0]?.icon,
                tabs: [
                  {
                    id: nextUntitledIndex,
                    content: "",
                    answer: "",
                    isActive: true,
                    lang: {
                      name: `untitled${1}`,
                      extension: languages[0]?.extension,
                      langName: languages[0]?.language,
                      langIcon: languages[0]?.icon,
                    },
                  },
                ],
              })
            );
          }
        }
      }
    }

    // setActiveTab(title);
  };

  const handleNavigate = () => {
    setActiveTab(() => title);
    clickHandler();
  };

  return (
    <button
      className={`${styles.feature_button} ${activeTab === title ? styles.feature_button_active : ""
        } ${className}`}
      onClick={fromSettings ? handleNavigate : handleClick}
    >
      {className ? (
        <span
          style={{
            height: activeTab === title ? "90%" : "0",
            width: activeTab === title ? "4px" : "0",
            marginRight: activeTab === title ? "10px" : "0",
          }}
          className={styles.feature_button_pipe}
        ></span>
      ) : (
        ""
      )}
      <span className={styles.feature_button_icon}>
        <img
          src={icon}
          alt={title}
        // onError={() => this.src=""}
        />
      </span>
      <span className={styles.feature_button_title}>{title}</span>
    </button>
  );
};

export default FeatureButton;
