import React from "react";
import styles from "./LoginLayout.module.scss";
import laptop from "../../assets/png/laptop.png";
import login1 from "../../assets/svg/01-login.svg";
import login2 from "../../assets/svg/02-login.svg";
import login3 from "../../assets/svg/03-login.svg";
import login4 from "../../assets/svg/04-login.svg";
import login5 from "../../assets/svg/05-login.svg";
import login6 from "../../assets/svg/06-login.svg";
import logom from "../../assets/svg/logoMain.svg"
import useMediaQuery from "../../hooks/useMediaQuery";

import { MOBILE_LANDSCAPE_WIDTH, MOBILE_VIEW_MAX_WIDTH } from "../../utils";
import useMediaQueryLandscape from "../../hooks/useMediaQueryLandscape";
const LoginLayout = ({ children }) => {
  const mobileView = useMediaQuery(MOBILE_VIEW_MAX_WIDTH);
  const mobileLandscape = useMediaQueryLandscape(`(max-width: ${MOBILE_LANDSCAPE_WIDTH}px) and (orientation: landscape)`)

  return (
    // <div className={styles.mainContainer}>

    //     {/* <span className={styles.wrapTriangle}>
    //         <span className={styles.triangle}></span>
    //     </span> */}
    //     <div className={styles.childrenContainer}>
    //         {children}
    //     </div>
    // </div>
    <>
      <div className={styles.main_back_iamge}>
        <div className={styles.backImage_sec}>
          <div className="row gx-0 hover-div">
            <div className={`${styles?.common_left_onboard} col-lg-6 col-12`}>

              <div className="d-flex flex-column justify-content-center align-items-center gap-0 p-5">
                <div className={styles.qxlogo_div}>
                  <img src={logom} alt="qx-logo" className={styles.mainLogoImage} />
                </div>
                <div className={styles.carousals_sec}>
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="3"
                        aria-label="Slide 4"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="4"
                        aria-label="Slide 5"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="5"
                        aria-label="Slide 6"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className={styles?.onboard_photo}>
                          <img src={login1} alt="laptop ai" />
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className={styles?.onboard_photo}>
                          <img src={login2} alt="laptop ai" />
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className={styles?.onboard_photo}>
                          <img src={login3} alt="laptop ai" />
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className={styles?.onboard_photo}>
                          <img src={login4} alt="laptop ai" />
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className={styles?.onboard_photo}>
                          <img src={login5} alt="laptop ai" />
                        </div>
                      </div>

                      <div className="carousel-item">
                        <div className={styles?.onboard_photo}>
                          <img src={login6} alt="laptop ai" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles?.common_right_onboard} col-lg-6 col-12`}>

              <div className={styles.child_div}>
                {mobileView || mobileLandscape ?
                  <img src={logom} alt="qx-logo" className={styles.mainLogoImage} /> : null

                }
                <h2>Text2Code</h2>
                {children}
              </div>
              <div className={styles.terms_link}>
                <a
                  className={styles.text_links}
                  href="https://text2code.qxlabai.com/terms-of-use.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and conditions
                </a>
                <a
                  className={styles.text_links}
                  href="https://text2code.qxlabai.com/privacy-policy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
                <a
                  className={styles.text_links}
                  href="https://text2code.qxlabai.com/faq.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQs
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
