import React, { useState } from "react";
import styles from './OnboardingSwitch.module.scss';

const OnboardingSwitch = ({
    options, 
    defaultOption, 
    onSelectOption
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption);

    const onClickOption = (optionValue) => {
        setSelectedOption(optionValue);
        onSelectOption?.(optionValue);
    };

    return (
        <div className={styles.onboarding_switch}>
            {options.map(option => (
                <div
                    key={option.value}
                    className={
                        option.value === selectedOption 
                        ? styles.onboarding_switch_item_selected
                        : styles.onboarding_switch_item
                    } 
                    onClick={() => onClickOption(option.value)}
                >
                    {option.title}
                </div>
            ))}
        </div>
    );
};

export default OnboardingSwitch;
