import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

const ProgressModal = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#00000075",
        zIndex: 100,
      }}
    >
      <Box sx={{ width: "100%" }}>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default ProgressModal;
