import React, { useState } from "react";
// import styles from "./Login.module.scss";
import styles from "../../layouts/loginlayout/LoginLayout.module.scss";
import { useNavigate } from "react-router-dom";
// import toast from "../../shared/toast/toast";
import { emailRegex, passwordRegex } from "../../utils/regex";
import { LOGIN, RESEND_OTP } from "../../services/apiEndpoints";
// import { LOGIN } from "services/apiEndpoints";
// import publicRequest from "services/publicRequest";
import LoginLayout from "../../layouts/loginlayout/LoginLayout";
import * as Yup from "yup";
import { requiredMessage, combineClasses } from "../../utils/index";
import { Form, Formik } from "formik";
import Button from "../../shared/button/Button";
import { PATH } from "../../utils/constant";
import FormikControl from "../../components/formikcontrols/FormikControl";
import SocialSignUp from "../../components/sociallogin/SocialLogin";
import { userLogin, userResendOtp } from "../../services/userService";
import { toast } from "react-toastify";
import erronIcon from "../../assets/svg/error-icon.svg";
import LoaderGif from "../../components/loaderGif/LoaderGif";

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState("");
  const [verify, setVerify] = useState("");
  const [showError, setShowError] = useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(requiredMessage("Email ID"))
      .matches(emailRegex, "Invalid email format!"),
    // password: Yup.string().required(requiredMessage("Password"))
    //   .matches(passwordRegex, "Invalid Password Format")
    password: Yup.string()
      .required(requiredMessage("Password"))
      .min(6, "Password must be at least 6 characters")
      .max(25, "Password must not exceed 25 characters")
      .test(
        "no-spaces",
        "Password must not contain spaces",
        (value) => !/\s/.test(value)
      ),
  });

  const login = (values) => {
    // return publicRequest.post(LOGIN, values).then((res) => {
    //   const { id, token, userRole, permission, isOnlyUser } = res?.data || {}
    //   localStorage.setItem("token", token)
    //   localStorage.setItem("currentUser", JSON.stringify({
    //     token,
    //     role: userRole?.[0]?.roleId,
    //     roleName: userRole?.[0]?.role?.roleName,
    //     isOnlyUser,
    //     permission: permission.map(({ id, permission:perm }) => ({
    //       id,
    //       permissionName: perm.actionName,
    //       entityId: perm.entity.id,
    //       entityName: perm.entity.entityName
    //     })),
    //     userId: id,
    //   }))
    //   localStorage.setItem("email", values.email)
    //   if(userRole?.[0]?.role?.roleName?.toLowerCase() === "admin" || userRole?.[0]?.role?.roleName?.toLowerCase() === "internaluser") {
    //     navigate("/")
    //     localStorage.setItem("currentRole", "admin")
    //   } else if(values.email === "shandharod@yahoo.com") {
    //     navigate("/")
    //     localStorage.setItem("currentRole", "approver")
    //   } else if(isOnlyUser) {
    //     navigate("/")
    //     localStorage.setItem("currentRole", "user")
    //   }
    //   else {
    //     setModalOpen(true)
    //   }
    // })
  };

  const handleSubmit = async (values) => {
    let payload = {
      emailId: values.email,
      password: values.password,
    };
    try {
      // 'first')
      let res = await userLogin(payload);
      if (res?.userDetails) {
        if (res?.userDetails?.status === "PENDING") {
          localStorage.setItem("userId", res?.userDetails?.userId);
          setVerify("user not verified");
        } else {
          if (res?.tokens?.accessToken) {
            localStorage.setItem("access", res?.tokens?.accessToken);
          }
          if (res?.tokens?.refreshToken) {
            localStorage.setItem("refresh", res?.tokens?.refreshToken);
          }
          toast.success("Successfully Logged in!");
          localStorage.setItem("email", res?.userDetails?.emailId);
          localStorage.setItem("firstName", res?.userDetails?.firstName);
          localStorage.setItem("lastName", res?.userDetails.lastName);
          localStorage.setItem("status", res?.userDetails.status);
          localStorage.setItem("userId", res?.userDetails.userId);
          localStorage.setItem("profilePhoto", res?.userDetails.profilePhoto);
          if (res?.userDetails?.planOfUse === null) {
            localStorage.setItem("newUser", true);
          }
          navigate("/home");
        }
      } else {
        toast.error("Something went wrong!!");
      }
    } catch (err) {
      setShowError(true);
    }
  };
  async function handleVerify() {
    let user_id = localStorage.getItem("userId");
    let payload = {
      emailId: localStorage.getItem("email"),
    };
    try {
      let res = await userResendOtp(payload, {
        "x-user-id": user_id,
      });
      if (res?.userDetails) {
        toast.success("otp sent successfully!");
        navigate("/otp-verification");
      } else {
        toast?.error("Something went wrong!");
      }
    } catch (err) {
      toast.error(err?.message);
      // setResendDisabled(false);
    }
  }

  return (
    <>
      <LoginLayout>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          // validateOnChange={false}
          // validateOnBlur={false}
        >
          {(formik) => {
            return (
              <div className={styles.login_sec}>
                {/* <h2>Text2Code</h2> */}

                <div className={styles.mainDiv}>
                  <p className={`${styles.heading} text-center`}>Log in</p>
                  {showError && (
                    <div className="error-message-div">
                      <span>
                        <img src={erronIcon} />
                        Incorrect user name or password
                      </span>
                    </div>
                  )}
                  {verify && (
                    <div className="error-message-div">
                      <span>
                        <img src={erronIcon} />
                        {verify}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleVerify}
                        >
                          <u> Please Verify!</u>
                        </span>{" "}
                      </span>
                    </div>
                  )}

                  <Form className={styles.form} autoComplete="off">
                    <FormikControl
                      name="email"
                      control="input"
                      label="Email ID"
                      placeholder="Your Email"
                      isRequired={true}
                      formik={formik}
                      setShowError={setShowError}
                    />

                    <FormikControl
                      name="password"
                      control="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      isRequired={true}
                      setShowError={setShowError}
                    />
                    {credentials && <span>{credentials}</span>}

                    <Button
                      type="submit"
                      className={styles.button}
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? <LoaderGif /> : "Log In"}
                    </Button>
                    {/* <p className={styles.bottomText}>
                    Don't have an account?{" "}
                    <span
                      className="link-text"
                      onClick={() => navigate(PATH.publicRoutes.REGISTER.path)}
                    >
                      Sign up
                    </span>
                  </p> */}
                    <p
                      className={combineClasses(styles.bottomText, "link-text")}
                      onClick={() =>
                        navigate(PATH.publicRoutes.FORGOT_PASSWORD.path)
                      }
                    >
                      Forgot Password?
                    </p>
                  </Form>
                  <div className={styles.sperator_div}>
                    <span className={styles.line_text}></span>
                    <span>or</span>
                    <span className={styles.line_text}></span>
                  </div>
                  <SocialSignUp />
                  <div className={styles.terms_sec}>
                    <p className={styles.terms_text}>
                      By loggin in you agree to our&nbsp;
                      <span>
                        {/* <a
                          href="https://askqx.qxlabai.com/terms-of-use.html"
                          target="_blank"
                          rel="noreferrer"
                        > */}
                        Terms of Service
                        {/* </a> */}
                      </span>
                    </p>
                  </div>
                </div>
                <p className={styles.bottomText_end}>
                  Don't have an account?{" "}
                  <span
                    className="link-text"
                    onClick={() => navigate(PATH.publicRoutes.REGISTER.path)}
                  >
                    Sign up
                  </span>
                </p>
              </div>
            );
          }}
        </Formik>
      </LoginLayout>
    </>
  );
};

export default Login;
