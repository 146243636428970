import { useState, useEffect, useCallback } from "react";

const useMediaQueryLandscape = (query) => {
    const [matches, setMatches] = useState(false);

    const updateMatches = useCallback((e) => {
        setMatches(e.matches);
    }, []);

    useEffect(() => {
        const media = window.matchMedia(query);
        setMatches(media.matches);
        media.addListener(updateMatches);
        return () => media.removeListener(updateMatches);
    }, [query, updateMatches]);

    return matches;
};

export default useMediaQueryLandscape;
