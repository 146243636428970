import React, { useEffect, useRef, useState } from "react";
import styles from "./TopBar.module.scss";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IoIosCode } from "react-icons/io";
import { BiMessageDots } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import {
  setActiveLanguage,
  setExtension,
  snippetPost,
} from "../../store/slices/editorSlice";
import Select from "react-select";
import {
  newChatHandler,
  setConversationId,
} from "../../store/slices/conversationSlice";
import arrow from "../../assets/svg/arrowNew.svg";

import profileIcon from "../../assets/png/user.png";
import downArrowIcon from "../../assets/png/down-arrow.png";
import helpSupportIcon from "../../assets/svg/helpSupport.svg";
import pricingIcon from "../../assets/svg/pricing.svg";
import vscodeIcon from "../../assets/svg/vs.svg";
import logoutIcon from "../../assets/svg/logout.svg";
import Popover from "@mui/material/Popover";
import LogoutModal from "../modals/LogoutModal";
import { useScreenshot } from "use-react-screenshot";
import SkeletonLoader from "../../assets/SkeletonLoader";
import chatIcon from "../../assets/svg/chat.svg"
import { toast } from "react-toastify";
import { clearHistory } from "../../store/slices/historySlice";
const Topbar = () => {
  const dispatch = useDispatch();
  const { ref } = useSelector((state) => state?.language);
  const [image, takeScreenshot] = useScreenshot();
  const location = useLocation();
  const navigate = useNavigate();
  const conversation = useSelector((state) => state.conversation);
  const { id } = useParams();
  const { languages, activeLanguage, loading } = useSelector(
    (state) => state.language
  );

  //popup
  const [isChatHovered, setIsChatHovered] = useState("");
  const [menuOpen, setMenuOpen] = useState(null);
  const [logout, setLogout] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    localStorage.getItem("profilePhoto")
  );
  const domRef = useRef(null);
  const open = Boolean(menuOpen);

  const popId = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleOpenMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };


  const handleToggle = (pathname) => {
    const bucket = localStorage.getItem("bucket");
    if (!bucket) {
      const lastUntitledIndex = activeLanguage.tabs.reduce((acc, tab) => {
        const match = tab.languages?.[0].name.match(/untitled(\d+)/);
        if (match) {
          return Math.max(acc, parseInt(match[1], 10));
        }
        return acc;
      }, 0);
      const nextUntitledIndex = lastUntitledIndex + 1;
      dispatch(
        setActiveLanguage({
          value: "",
          responseValue: "",
          featureType: "",
          name: languages?.[0].language,
          extension: languages?.[0].extension,
          icon: languages?.[0].icon,
          languageId: languages?.[0].languageId,
          langIcon: languages?.[0].icon,
          tabs: [
            {
              id: nextUntitledIndex,
              content: "",
              answer: "",
              isActive: true,
              lang: {
                name: `untitled${1}`,
                extension: languages?.[0].extension,
                langName: languages?.[0].language,
                langIcon: languages?.[0].icon,
              },
            },
          ],
        })
      );
    }
    if (pathname === "/conversation" || pathname.startsWith("/conversation/")) {
      if (id) {
        dispatch(setConversationId(id));
      }
      navigate("/code-editor");
      // const activeTab = activeLanguage?.tabs.find((tab) => tab?.isActive);
      // if (activeTab) {
      //   // dispatch();
      // }
    } else if (pathname === "/code-editor") {
      if (conversation && conversation.conversationId) {
        navigate(`/conversation/${conversation.conversationId}`);
      } else {
        navigate("/conversation");
      }
    } else if (pathname === "/home") {
      navigate("/code-editor");
    }
  };

  const options = languages?.map((language) => ({
    value: language?.languageId,
    label: language?.language,
    extension: language?.extension,
    languageId: language?.languageId,
  }));

  const handleLanguageSelect = (selectedOption) => {
    const selectedLanguage = languages.find(
      (language) => language?.language === selectedOption
    );

    dispatch(
      setActiveLanguage({
        name: selectedLanguage?.language,
        icon: selectedLanguage?.icon,
        extension: selectedLanguage?.extension,
        languageId: selectedLanguage?.languageId,
        langIcon: selectedLanguage?.icon,
      })
    );

    const activeTab = activeLanguage?.tabs.find((tab) => tab?.isActive);
    if (activeTab) {
      dispatch(
        setExtension({
          ...activeTab,
          lang: {
            name: selectedLanguage?.label,
            extension: selectedLanguage?.extension,
            langName: selectedLanguage?.language,
            langIcon: selectedLanguage?.icon,
          },
        })
      );
    }
    setShowSelect(false);
  };

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  useEffect(() => {
    if (ref) {
      // Retrieve the DOM element using its ID
      const element = document.getElementById(ref);
      if (element) {
        domRef.current = element;
      }
    }
  }, [ref]);

  async function handleSave() {
    let imgData;
    if (domRef.current) {
      imgData = await takeScreenshot(domRef.current);
    }
    const file = DataURIToBlob(imgData);
    let data = JSON.parse(localStorage?.getItem("bucket"));
    let formdata = new FormData();
    formdata.append("sourceCode", data?.value);
    formdata.append("name", data?.name);
    formdata.append("image", file);
    formdata.append("langaugeId", data?.languageId);
    dispatch(snippetPost(formdata));
    toast?.success("Source Code Saved Successfully!!");
  }

  const handleLogout = () => {
    setLogout(true);
  };

  const handleLogoutClear = () => {
    localStorage.clear();
    dispatch(clearHistory());
    dispatch(newChatHandler());
    navigate("/login");
  };

  useEffect(() => {
    const bucket = localStorage.getItem("bucket");
    if (bucket) {
      const parsedBucket = JSON.parse(bucket);
      dispatch(setActiveLanguage(parsedBucket));
    }
  }, [dispatch]);
  const [showSelect, setShowSelect] = useState(false);

  const openSuggestion = () => {
    setShowSelect(!showSelect);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSelect) {
        const suggestionDiv = document.querySelector("#selectsec");
        if (suggestionDiv && !suggestionDiv.contains(event.target)) {
          setShowSelect(false);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSelect]);

  return (
    <>
      {loading ? (
        <SkeletonLoader skeletonType="TopBar" />
      ) : (
        <>
          <div className={styles.topBarContainer}>
            {location.pathname.includes("/conversation") ||
              location.pathname.includes("/conversation/id") ? (
              <div className={styles.leftSides}>
                <img
                  src={activeLanguage?.langIcon}
                  alt="language icon"
                  className={styles.lang_icon}
                />
              </div>
            ) : location.pathname.includes("/home") ? (
              <div></div>
            ) : (
              <div
                className={styles.leftSide}
                onClick={(e) => {
                  e.stopPropagation();
                  openSuggestion();
                }}
              >
                <div className={styles.select_div}>
                  <img
                    src={activeLanguage?.langIcon}
                    alt="language icon"
                    className={styles.lang_icon_img}
                  />
                  <p>{activeLanguage.name}</p>
                </div>
                <div className={styles.arrow_sec}>
                  <img
                    src={arrow}
                    alt=""
                    className={
                      showSelect ? styles.arrow_icon : styles.arrow_before
                    }
                  />
                </div>

                {showSelect && (
                  <div
                    className={styles.suggestion_div}
                    id="selectsec"
                    onclick={(e) => e.stopPropagation()}
                  >
                    <div className="inner-sugg">
                      {languages.map((language, index) => (
                        <div
                          className={styles.sugg_text}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleLanguageSelect(language.language);
                          }}
                        >
                          <img
                            src={language?.icon}
                            alt="language icon"
                            className={styles.lang_icon_img}
                          />
                          <span key={index}>{language.language}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* <img
              src={activeLanguage?.langIcon}
              alt="language icon"
              className={styles.lang_icon_img}
            />
            <select
              onChange={(e) =>
                handleLanguageSelect({
                  language: e.target.value,
                })
              }
              className={styles.selects}
              value={activeLanguage.name}
            >
              {languages?.map((language) => (
                <option key={language.languageId} value={language.language}>
                  <span>
                    {language.language}
                  </span>
                </option>
              ))}
            </select> */}
              </div>
            )}

            <div className={styles.rightSide_flex}>
              <div
                className={styles.rightSide}
                onClick={() => handleToggle(location.pathname)}
              >
                <span
                  className={styles.rightSide_icon}
                  style={{
                    left: location.pathname.includes("/code-editor")
                      ? "0.5rem"
                      : "110px",
                  }}
                >
                  {/* {location.pathname === "/conversation" ? (
              <IoIosCode size={20} color="#000" />
            ) : (
              <BiMessageDots size={20} color="#000" />
            )} */}
                  {location.pathname.includes("/code-editor") ? (
                    <IoIosCode size={20} color="#000" />
                  ) : (
                    // <BiMessageDots size={20} color="#000" />
                    <img src={chatIcon} alt="chat" />
                  )}
                </span>
                <p
                  className={styles.rightSide_text}
                  style={{
                    paddingLeft: location.pathname.includes("/code-editor")
                      ? "50px"
                      : "1rem",
                  }}
                >
                  <span className={styles.rightSide_text_small}>Switch To</span>
                  <span className={styles.rightSide_text_big}>
                    {location.pathname.includes("/code-editor")
                      ? "Chat Box"
                      : "Code Editor"}
                  </span>
                </p>
              </div>
              <div className={styles.profile_div}>
                <div className={styles.profile_div_image} onClick={() => navigate("/setting/profile")}>
                  <img
                    src={localStorage?.getItem("profilePhoto") || profileIcon}
                    alt=""
                  />
                </div>

                <div className={styles.profile_div_username} onClick={() => navigate("/setting/profile")}>
                  <span className={styles.firstname}>
                    {localStorage.getItem("firstName")}&nbsp;
                    {localStorage.getItem("lastName")}
                  </span>

                  {/* <span className={styles.credits}>
                Credits <b className={styles.credits_bold}> 4500 </b>/5000
              </span> */}
                </div>
                <div
                  className={styles.profile_div_downArrow}
                  aria-describedby={popId}
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <img src={downArrowIcon} alt="downArrow" />
                </div>
              </div>
            </div>
          </div>{" "}
        </>
      )}

      <Popover
        id={popId}
        open={open}
        anchorEl={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        className="top_30"
      >
        <div className="menu_wrapper_content profile_popup">
          {/* <p className="menu_wrapper_text profile_popup_text">
            <span className="icon_wrapper">
              <img src={helpSupportIcon} alt="Help&Support" />
            </span>
            Help & Support
          </p> */}
          {/* <p className="menu_wrapper_text profile_popup_text">
            <span className="icon_wrapper">
              <img src={pricingIcon} alt="Pricing" />
            </span>
            Pricing
          </p> */}
          <p
            className="menu_wrapper_text profile_popup_text"
            onClick={() => navigate("/setting/download-vscode")}
          >
            <span className="icon_wrapper">
              <img src={vscodeIcon} alt="" />
            </span>
            VS Code Extension
          </p>
          <p
            className="menu_wrapper_text profile_popup_text"
            onClick={handleLogout}
          >
            <span className="icon_wrapper">
              <img src={logoutIcon} alt="logoutIcon" />
            </span>
            Logout
          </p>
        </div>
      </Popover>
      {logout && (
        <LogoutModal
          logout={logout}
          setLogout={setLogout}
          handleLogout={handleLogout}
          handleLogoutClear={handleLogoutClear}
        />
      )}
    </>
  );
};

export default Topbar;
