import { useRef, useState } from "react";
import styles from "./Conversation.module.scss";
import { LuSend } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { newChatHandler } from "../../store/slices/conversationSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { BiArchiveIn } from "react-icons/bi";
import { archiveChat, setHistoryId } from "../../store/slices/historySlice";
import { toast } from "react-toastify";
import { setIsPreview } from "../../store/slices/editorSlice";
import send from "../../assets/svg/send.svg"

const TextInput = ({
  array,
  question,
  setQuestion,
  handleSubmit,
  id,
  handleRegenerateQuery,
  archive,
}) => {
  const conversation = useSelector((state) => state.conversation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className={styles.bottomArea}>
      {/* <ResponseButtons handleRegenerateQuery={handleRegenerateQuery} /> */}

      {!archive ? (
        <div className={styles.inputArea}>
          {array.length > 0 && !conversation.loading && !isMobile && (
            <div className={`${styles.newChatButton}`}>
              <button
                onClick={() => {
                  dispatch(newChatHandler());
                  dispatch(setHistoryId());
                  dispatch(setIsPreview(false))
                  navigate("/home");
                }}
              >
                New Chat
              </button>
            </div>
          )}
          <div className={styles.inputContainer}>
            <form onSubmit={handleSubmit}>
              <div className={styles.textAreaContainer}>
                <textarea
                  className={styles.textArea}
                  placeholder="Enter a prompt here"
                  value={question}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  ref={textareaRef}
                />
                <button type="submit" className={styles.submitButton}>
                  <img src={send} alt="send" />
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={styles.inputArea_archive}>
          <div className={styles?.arch}>
            <p>
              {" "}
              This conversation is archived. To continue, please unarchive it
              first.
            </p>
            <div className=" w-100">
              <button
                className={styles.archive_btn}
                onClick={() => {
                  dispatch(
                    archiveChat({
                      id: id,
                      payload: {
                        data: {
                          isArchived: false,
                        },
                      },
                    })
                  );
                  toast?.success("Unarchived Successfully!");
                  navigate("/home");
                }}
              >
                Unarchive
                <BiArchiveIn size={20} style={{ color: "white" }} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextInput;
