import axios from "axios";
import { post } from "./publicRequest";
import commonRequest from './main';
import { FEEDBACK, LINKEDIN_LOGIN, LOGIN, REFRESH_TOKEN, REGISTER, RESEND_OTP, USER_FORGOT_PASSWORD, USER_ONBOARD_DETAILS, USER_RESET_PASSWORD, USER_SOCIAL_LOGIN, VERIFY_ACCOUNT } from "./apiEndpoints";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const setHeaders = (baseUrl, apiKey, header = {}) => ({
  baseURL: baseUrl,
  headers: {
    'x-api-key': apiKey,
    ...header
  },
});
let userId = localStorage.getItem("userId");


// export const userLogin = (endpoint, payload) => {
//   return post(endpoint, payload);
// };
export const userLogin = async (payload) => {
  try {
    const response = await commonRequest.post(LOGIN, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response?.data?.error?.message)
    }
  } catch (error) {
    console.log('error', error)
    throw error;
  }
};
// export const userRegister = (endpoint, data) => {
//   return post(endpoint, data);
// };
export const userRegister = async (payload) => {
  try {
    const response = await commonRequest.post(REGISTER, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response?.data?.error?.message)
    }
  } catch (error) {
    throw error;
  }
};
// export const userVerify = (endpoint, data, user_id) => {
//   return post(endpoint, data, {
//     headers: {
//       " x-user-id": userId,
//     },
//   });
// };
export const userVerify = async (payload, obj) => {
  try {
    const response = await commonRequest.post(VERIFY_ACCOUNT, {
      data: payload
    }, setHeaders(API_URL, API_KEY, obj));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};

// export const userResendOtp = (endpoint, data, user_id) => {
//   return post(endpoint, data, {
//     headers: {
//       " x-user-id": user_id,
//     },
//   });
// };
export const userResendOtp = async (payload, obj) => {
  try {
    const response = await commonRequest.post(RESEND_OTP, {
      data: payload
    }, setHeaders(API_URL, API_KEY, obj));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};
// export const userForgotPassword = (endpoint, data, user_id) => {
//   return post(endpoint, data);
// };
export const userForgotPassword = async (payload) => {
  try {
    const response = await commonRequest.post(USER_FORGOT_PASSWORD, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response?.data?.error?.message)
    }
  } catch (error) {
    throw error;
  }
};
// export const userResetPassword = (endpoint, data, user_id) => {
//   return post(endpoint, data, {
//     headers: {
//       " x-user-id": user_id,
//     },
//   });
// };
export const userResetPassword = async (payload, obj) => {
  try {
    const response = await commonRequest.post(USER_RESET_PASSWORD, {
      data: payload
    }, setHeaders(API_URL, API_KEY, obj));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};
// export const socialLogin = (endpoint, data) => {
//   return post(endpoint, data);
// };
export const socialLogin = async (payload) => {
  try {
    const response = await commonRequest.post(USER_SOCIAL_LOGIN, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};
// export const linkedin_api = async (endpoint, data) => {
//   // return await commonrequest("POST", `${BACKEND_URL}/user/social/linkedin/user-info`, datas, apiServicesEnum.USER_SERVICE);
//   return post(endpoint, data);
// };
export const linkedin_api = async (payload) => {
  try {
    const response = await commonRequest.post(LINKEDIN_LOGIN, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};

export const feedback_api = async (payload) => {
  try {
    const response = await commonRequest.post(FEEDBACK, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};
export const refreshToken = async (payload) => {
  try {
    const response = await commonRequest.post(REFRESH_TOKEN, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};

export const userOnboardDetails = async (payload) => {
  try {
    const response = await commonRequest.put(USER_ONBOARD_DETAILS, {
      data: payload
    }, setHeaders(API_URL, API_KEY));
    if (response.data.success) {
      return response.data.data
    } else {
      throw new Error(response.data.error.message)
    }
  } catch (error) {
    throw error;
  }
};

////social apis

export const google_api = async (params) => {
  let data = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
    params: params,
  });
  return data?.data;
};
