import commonRequest from './main';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const setHeaders = (baseUrl, apiKey, header = {}) => ({
    baseURL: baseUrl,
    headers: {
        'x-api-key': apiKey,
        ...header
    },
});
// const token = localStorage.getItem("access");
// console.log('token', token)
// const ApiService = axios.create({
//     baseURL: API_URL,
//     headers: {
//         'Content-Type': 'application/json',
//         'x-api-key': `${API_KEY}`,
//         // "x-access-token": token
//     },
//     params: { requestId: `WEB_${new Date().getTime()}` },
// });
// ApiService.interceptors.request.use(async (config) => {
//     try {
//         // if (config?.headers["Content-Type"]) {
//         //     config.headers["Content-Type"] = 'multipart/form-data';
//         // }
//         const token = localStorage.getItem("access");
//         if (token) {
//             config.headers['x-access-token'] = token;
//         }
//     } catch (error) {
//         console.error("Error while setting token:", error);
//     }
//     return config;
// });
const profileImage = async (payload, obj) => {
    try {
        const response = await commonRequest.patch(`/user/profile/image`, payload, setHeaders(API_URL, API_KEY, obj));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}
const updateProfile = async (payload) => {
    try {
        const response = await commonRequest.patch(`/user/profile`, payload, setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}
const getProfile = async (payload) => {
    try {
        const response = await commonRequest.get(`/user/profile`, setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}
const profileVeriicationCode = async (payload) => {
    try {
        const response = await commonRequest.post(`/user/profile/send-verification-code`, payload, setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}
const changePassword = async (payload) => {
    try {
        const response = await commonRequest.post(`/user/profile/change-password`, payload, setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}
const deleteProfile = async (id) => {
    try {
        const response = await commonRequest.delete(`/user/profile/delete-account/${id}`, setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error;
    }
}

const getLanguageSuggestions = async () => {
    try {
        const response = await commonRequest.get("/app/suggestions", setHeaders(API_URL, API_KEY));
        if (response.data.success) {
            return response.data.data
        } else {
            throw new Error(response?.data?.error?.message)
        }
    } catch (error) {
        throw error
    }
}

export { profileImage, updateProfile, getProfile, profileVeriicationCode, changePassword, deleteProfile, getLanguageSuggestions, };
