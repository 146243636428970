import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import OnboardingSwitch from "./OnboardingSwitch";
import { requiredMessage } from "../../utils";
import { emailRegex } from "../../utils/regex";
import { userOnboardDetails } from "../../services/userService";
import { toast } from "react-toastify";
import logom from "../../assets/svg/logoMain.svg"

const experience = [
  { title: "None", value: "NONE" },
  { title: "Beginner", value: "BEGINNER" },
  { title: "Intermediate", value: "INTERMEDIATE" },
  { title: "Pro", value: "PRO" },
];

const plan = [
  { title: "For Work", value: "WORK" },
  { title: "For Personal Use", value: "PERSONAL" },
  { title: "For Education", value: "EDUCATION" },
];

const Onboarding = ({ show, handleCloseOnboarding }) => {
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [emailError, setEmailError] = useState("");
  const [orgError, setOrgError] = useState("");

  const onSelectExperience = (experience) => {
    setSelectedExperience(experience);
  };

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const submitOnboarding = async () => {
    if (selectedExperience && selectedPlan) {
      const payload = {
        experience: selectedExperience,
        planOfUse: selectedPlan,
        organizationName: organization,
        organizationEmail: email,
      };
      const response = await userOnboardDetails(payload);
      if (response.isUpdated) {
        handleCloseOnboarding?.();
      }
    } else {
      toast.warning("Please select these mandatory fields");
    }
  };

  const onCancel = () => {
    if (hasNext) {
      setHasNext(false);
      return;
    }
    handleCloseOnboarding?.();
  };

  const onNext = () => {
    if (selectedPlan === plan[0].value) {
      setHasNext(true);
      return;
    }
    submitOnboarding();
  };

  const onSubmit = () => {
    let isEmailValid = false;
    let isOrgValid = false;

    if (!email) {
      setEmailError(requiredMessage("Email Address"));
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("");
      isEmailValid = true;
    }

    if (!organization) {
      setOrgError(requiredMessage("Organization Name"));
    } else {
      setOrgError("");
      isOrgValid = true;
    }

    if (isEmailValid && isOrgValid) {
      submitOnboarding();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.onboarding}>
      <img src={logom} alt="qx-logo" className={styles.mainLogoImage} />

      <h2 className={styles.onboarding_logo}>Text2Code</h2>
      <div className={styles.onboarding_container}>
        {!hasNext && (
          <>
            <div className={styles.onboarding_container_content}>
              <p className={styles.onboarding_container_question}>
                What level of software creation experience do you have?
                <span className={styles.onboarding_container_mandatory}>*</span>
              </p>
              <OnboardingSwitch
                options={experience}
                defaultOption={selectedExperience}
                onSelectOption={(option) => onSelectExperience(option)}
              />
            </div>
            <div className={styles.onboarding_container_content}>
              <p className={styles.onboarding_container_question}>
                How do you plan to use?
                <span className={styles.onboarding_container_mandatory}>*</span>
              </p>
              <OnboardingSwitch
                options={plan}
                defaultOption={selectedPlan}
                onSelectOption={(option) => onSelectPlan(option)}
              />
            </div>
          </>
        )}
        {hasNext && (
          <>
            <div className={styles.onboarding_container_content}>
              <p className={styles.onboarding_container_question}>
                Enter Your Official e-Mail Id?
                <span className={styles.onboarding_container_mandatory}>*</span>
              </p>
              <input
                className={styles.onboarding_container_input}
                placeholder="Write your e-Mail Id.."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <span className={styles.onboarding_container_input_error}>
                  {emailError}
                </span>
              )}
            </div>
            <div className={styles.onboarding_container_content}>
              <p className={styles.onboarding_container_question}>
                Enter Your Organization Name?
                <span className={styles.onboarding_container_mandatory}>*</span>
              </p>
              <input
                className={styles.onboarding_container_input}
                placeholder="Write your Organization Name.."
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
              {orgError && (
                <span className={styles.onboarding_container_input_error}>
                  {orgError}
                </span>
              )}
            </div>
          </>
        )}
        <div className={styles.onboarding_container_button_container}>
          {hasNext && <button
            className={styles.onboarding_container_button_container_btn}
            onClick={onCancel}
          >
            Go back
          </button>}
          <button
            className={styles.onboarding_container_button_container_btn_primary}
            onClick={() => {
              if (hasNext) {
                onSubmit();
              } else {
                onNext();
              }
            }}
          >
            {hasNext ? "Submit" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
