import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile, } from "../../services/profileService";

const initialState = {
    loading: false,
    userInfo: {},
    error: null
};

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.loading = true;
                state.error = null

            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
                state.error = null

            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

    },
});

export const getUser = createAsyncThunk(
    'profile/get',
    async () => {
        const data = await getProfile()
        return data?.userDetails

    }
)

export default profileSlice.reducer;
