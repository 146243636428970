import { Outlet } from "react-router-dom";
import useMediaQuery from "../hooks/useMediaQuery";
import { MOBILE_VIEW_MAX_WIDTH } from "../utils";

const SettingsOutlet = () => {
    const mobileView = useMediaQuery(MOBILE_VIEW_MAX_WIDTH);

    const height = mobileView ? 'calc(100% - 80px)' : '100%';

	return (
		<div
			style={{
				height: height,
				overflowY: 'auto',
			}}
		>
			<Outlet />
		</div>
	);
};

export default SettingsOutlet;
