import React, { useState } from "react";
// import styles from "./OtpVerification.module.scss";
import styles from '../../layouts/loginlayout/LoginLayout.module.scss'
import { useNavigate } from "react-router-dom";
import { emailRegex } from "../../utils/regex";
import LoginLayout from "../../layouts/loginlayout/LoginLayout";
import * as Yup from "yup"
import { combineClasses, requiredMessage } from "../../utils/index";
import { Form, Formik } from 'formik';
import { PATH } from "../../utils/constant";
import Button from "../../shared/button/Button";
import FormikControl from "../../components/formikcontrols/FormikControl";
import { userForgotPassword, userResendOtp } from "../../services/userService";
import { USER_FORGOT_PASSWORD, USER_RESET_PASSWORD } from "../../services/apiEndpoints";
import { toast } from "react-toastify";
import erronIcon from '../../assets/svg/error-icon.svg'

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [verify, setVerify] = useState("")
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [otpBtn, setOtpBtn] = useState(false);


  const initialValues = {
    email: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string().required(requiredMessage("Email ID")).matches(emailRegex, "Invalid email format!"),
  })

  const login = (values) => {

  }

  const handleSubmit = async (values) => {
    let payload = {
      emailId: values?.email
    }
    localStorage.setItem('email', values?.email)
    try {
      let res = await userForgotPassword(payload)
      if (res?.status == "PENDING") {
        setVerify("User is not verified")
        localStorage.setItem("userId", res?.userId)
        setOtpBtn(true)

        // toast.success("otp has been sent Sucessfully!!")
      } else {
        toast.success("otp has been sent Sucessfully!!")
        if (res?.userId) {
          localStorage.setItem("userId", res?.userId)
        }
        navigate("/verify-password")

      }

      // if (res?.status == 200) {
      //   if (res?.data?.success) {
      //     localStorage.setItem("email", values?.email)
      //     if (res?.data?.data?.status == "PENDING") {
      //       setVerify("User is not verified")
      //       toast.success("otp has been sent Sucessfully!!")

      //     }
      //     if (res?.data?.data?.status == "ACTIVE") {
      //       toast.success("otp has been sent Sucessfully!!")
      //       if (res?.data?.data?.userId) {
      //         localStorage.setItem("userId", res?.data?.data?.userId)
      //       }
      //       navigate("/verify-password")
      //     }
      //   } else {
      //     toast.error(res?.data?.error?.message)
      //   }
      // }

    } catch (err) {
      // toast.error(err?.message)
      setShowError(true)
      setErrorMessage(err?.message)
    }
    // navigate(PATH.publicRoutes.OTP_VERIFICATION.path, { state: { email: values.email } })

  };
  async function handleVerify() {
    setOtpBtn(false)
    let user_id = localStorage.getItem("userId");
    let payload = {
      emailId: localStorage.getItem("email"),
    };
    try {
      let res = await userResendOtp(payload, {
        "x-user-id": user_id
      });
      if (res?.userDetails) {
        toast.success("otp sent successfully!");
        navigate("/otp-verification");
      } else {
        toast?.error("Something went wrong!")
      }
    } catch (err) {
      console.log(err)
      toast.error(err?.message);
    }
  }
  return (
    <LoginLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => {
          return (
            <div className={styles.login_sec}>
              {/* <h2>Text2Code</h2> */}
              <div className={styles.mainDiv}>
                <p className={styles.heading}>Forgot Password</p>
                <p className={styles.subHeading}>Enter your email id to generate OTP</p>
                {
                  showError && <div className="error-message-div">
                    <span>
                      <img src={erronIcon} />
                      {errorMessage}</span>
                  </div>
                }
                {
                  verify && (
                    <div className="error-message-div">
                      <span>
                        <img src={erronIcon} />

                        user is not verified
                        <span
                          onClick={handleVerify}
                        ><u> Please verify!</u></span>
                      </span>
                    </div>
                  )
                }
                <Form className={styles.form} autoComplete="off">
                  <FormikControl
                    name="email"
                    control="input"
                    label="Email ID"
                    placeholder="Your Email"
                    isRequired={true}
                    setShowError={setShowError}
                  />

                  <Button
                    type="submit"
                    className={`${styles.button} ${otpBtn && styles.disa} `}
                    disabled={otpBtn}
                  >
                    Generate OTP
                  </Button>
                  <p className={styles.bottomText}>
                    Already have an account?  <span
                      className="link-text"
                      onClick={() => navigate(PATH.publicRoutes.LOGIN.path)}
                    >
                      Sign in
                    </span>
                  </p>
                </Form>
              </div>
            </div>
          )
        }}
      </Formik>
    </LoginLayout>
  );
};

export default ForgotPassword;