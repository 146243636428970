import { Modal } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "../../pages/settings/chathistory/Chathistory.module.scss";
import { deleteHistoryById } from "../../store/slices/historySlice";
import CROSS from "../../assets/png/cross.png";
import { toast } from "react-toastify";
const DeleteHistoryModal = ({ conversationId, delOpen, setDelOpen }) => {
  const dispatch = useDispatch();
  function handleDelete() {
    dispatch(deleteHistoryById(conversationId));
    toast.success("Chat deleted Successfully!");
    setDelOpen(false);
  }
  return (
    <>
      <Modal open={delOpen}>
        <div className="w-100 h-100  d-flex justify-content-center align-items-center">
          <div className={styles?.delModal}>
            <div className={styles?.delheader}>
              <p>Delete Chat History</p>
              {/* <button onClick={() => setDelOpen(false)}>Cancel</button> */}
              <img
                src={CROSS}
                className={styles?.cross}
                alt="delete_icon"
                onClick={() => setDelOpen(false)}
              />
            </div>
            <div className={styles?.innermodal}>
              <h5>Are you sure you want to delete this chat?</h5>
              <div className={styles?.btngrp}>
                <button
                  className={styles?.delbtn2}
                  onClick={() => setDelOpen(false)}
                >
                  Cancel
                </button>
                <button className={styles?.delbtn} onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteHistoryModal;
