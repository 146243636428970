import commonRequest from "./main";

const baseURL = process.env.REACT_APP_CODE_BASE_URL;
const apiKey = process.env.REACT_APP_CODE_API_KEY;

const setHeaders = (baseUrl, apiKey, header = {}) => ({
  baseURL: baseUrl,
  headers: {
    "x-api-key": apiKey,
    ...header,
  },
});

const getAllSharedLinks = async () => {
  try {
    const response = await commonRequest.get(
      `/share/conversations`,
      setHeaders(baseURL, apiKey)
    );
    if (response.data) {
      return response;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const getSharedConversation = async (conversationId) => {
  try {
    const response = await commonRequest.get(
      `/share/conversations/${conversationId}`,
      setHeaders(baseURL, apiKey)
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};
const copyConversationLink = async (payload,conversationId) => {
  try {
    const response = await commonRequest.post(
      `/share/conversations/${conversationId}`,payload,
      setHeaders(baseURL, apiKey)
    );
    if (response.data.data) {
      return response;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const UpdateAndCopyConversationLink = async (payload,conversationId,shareConversationId) => {
  try {
    const response = await commonRequest.patch(
      `/share/conversations/${conversationId}/${shareConversationId}`,payload,
      setHeaders(baseURL, apiKey)
    );
    if (response.data.data) {
      return response;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const continueConversation = async (payload,conversationId) => {
  try {
    const response = await commonRequest.post(
      `/share/conversation/copy`,payload,
      setHeaders(baseURL, apiKey)
    );
    if (response.data.data) {
      return response;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

const deleteSharedConversationLink = async (conversationId,shareConversationId) => {
  try {
    const response = await commonRequest.delete(
      `/share/conversations/${conversationId}/${shareConversationId}`,
      setHeaders(baseURL, apiKey)
    );
    if (response.data.data) {
      return response;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export { getAllSharedLinks,deleteSharedConversationLink,continueConversation, getSharedConversation,copyConversationLink,UpdateAndCopyConversationLink };
