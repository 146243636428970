import React, { useState, useEffect } from "react";
import styles from "./ShareChatModal.module.scss";
import Modal from "@mui/material/Modal";
import { IoMdClose } from "react-icons/io";
import {
  UpdateAndCopyConversationLink,
  copyConversationLink,
  deleteSharedConversationLink,
  getSharedConversation,
} from "../../services/shareChatService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
import profileIcon from "../../assets/png/user.png";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  MdClose,
  MdOutlineContentCopy,
  MdOutlineDone,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import useMediaQuery from "../../hooks/useMediaQuery";
import SocialShareChatModal from "./SocialShareChatModal";
import DeleteShareConversationModal from "./DeleteShareConversationModal";
import { CiCircleInfo } from "react-icons/ci";
import styles2 from "../../components/conversation/Conversation.module.scss";

// import styles2 from '../../pages/settings/chathistory/Chathistory.module.scss';
import {
  oneLight,
  materialLight,
  solarizedlight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { IoCopyOutline } from "react-icons/io5";

const ShareChatModal = ({ open, handleClose, shareChatId }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [array, setArray] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [currentAnswerIndexMap, setCurrentAnswerIndexMap] = useState({});
  const [queryCopied, setQueryCopied] = useState({});
  const [copiedMap, setCopiedMap] = useState({});
  const [del, setDel] = useState(false);
  const [publicLinkLoading, setPublicLinkLoading] = useState(false);
  const [publicLink, setPublicLink] = useState("");
  const [publicLinkTitle, setPublicLinkTitle] = useState("");
  const [shareConvId, setShareConvId] = useState(null);
  const [opens, setOpens] = useState(false);
  const [imageUrl, setImageUrl] = useState(
    localStorage.getItem("profilePhoto")
  );

  const isMobile = useMediaQuery(743);

  const baseUrl = window.location.origin;

  const getConversation = async (cID) => {
    setArray(() => []);
    setSpinner(() => true);
    setActiveStep(() => 1);

    const conversations = await getSharedConversation(cID);

    if (conversations) {
      setArray(conversations?.conversation?.chats);
      setShareConvId(conversations?.shareConversationId);
      setSpinner(() => false);
    } else {
      toast.error("Something went wrong");
      setSpinner(() => false);
    }
  };

  useEffect(() => {
    if (open && shareChatId) {
      getConversation(shareChatId);
    }
  }, [shareChatId, open]);


  const handleCopyQuery = (response) => {
    setQueryCopied((prev) => ({
      ...prev,
      [response]: true,
    }));

    setTimeout(() => {
      setQueryCopied((prev) => ({
        ...prev,
        [response]: false,
      }));
    }, 2500);
  };

  const handleAnswerCopy = (response) => {
    setCopiedMap((prevCopiedMap) => ({
      ...prevCopiedMap,
      [response]: true,
    }));
    setTimeout(() => {
      setCopiedMap((prevCopiedMap) => ({
        ...prevCopiedMap,
        [response]: false,
      }));
    }, 2500);
  };

  const handleIncrement = (itemIndex) => {
    setCurrentAnswerIndexMap((prevMap) => {
      const prevIndex = prevMap[itemIndex] || 0;
      const newIndex = Math.min(
        prevIndex + 1,
        array[itemIndex]?.answers.length - 1
      );
      return { ...prevMap, [itemIndex]: newIndex };
    });
  };

  const handleDecrement = (itemIndex) => {
    setCurrentAnswerIndexMap((prevMap) => {
      const prevIndex = prevMap[itemIndex] || 0;
      const newIndex = Math.max(prevIndex - 1, 0);
      return { ...prevMap, [itemIndex]: newIndex };
    });
  };
  const [copiedCodeMap, setCopiedCodeMap] = useState({});

  const handleCopyCode = (response, index) => {
    setCopiedCodeMap((prevCopiedCodeMap) => ({
      ...prevCopiedCodeMap,
      [`${response}_${index}`]: true,
    }));
    setTimeout(() => {
      setCopiedCodeMap((prevCopiedCodeMap) => ({
        ...prevCopiedCodeMap,
        [`${response}_${index}`]: false,
      }));
    }, 2500);
  };
  const TypingData = (item) => {
    return (
      <div className={styles.message}>
        {item.split("```").map((part, index) => {
          if (index % 2 === 0) {
            return <p key={index}>{part}</p>;
          } else {
            const [language, ...codeLines] = part.trim().split("\n");
            const code = codeLines.join("\n");
            return (
              <div key={index} className={styles.typing}>
                <div className={styles.codeheader}>
                  <div className={styles.headerItems}>
                    <p className={styles.language}>{language}</p>
                    <CopyToClipboard
                      text={code}
                      onCopy={() => handleCopyCode(item._id, index)}
                    >
                      {copiedCodeMap[`${item._id}_${index}`] ? (
                        <i>
                          <MdOutlineDone size={30} />
                        </i>
                      ) : (
                        <i>
                          <IoCopyOutline size={30} />
                        </i>
                      )}
                    </CopyToClipboard>
                  </div>
                  <SyntaxHighlighter
                    language={language}
                    style={oneLight}
                    customStyle={{ borderRadius: "30px" }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const handleSharing = async (url, title) => {
    const shareDetails = {
      url: url,
      title: title.slice(0, 35),
      text: title.slice(0, 35),
    };

    if (navigator.share) {
      try {
        await navigator.share(shareDetails).then(() => {
          // toast.success("Content was shared successfully")
          handleClose();
        });
      } catch (error) {
        handleClose();
        // toast.error(`Oops! Something went wrong`);
      }
    } else {
      // fallback code
      toast.error("Web share is currently not supported on this browser");
    }
  };

  const handleCreatePublicLink = async () => {
    setPublicLinkLoading(() => true);
    let payload = {};

    const response = await copyConversationLink(payload, shareChatId);

    if (response.status === 200) {
      setPublicLinkLoading(() => false);

      const sharedConvId =
        response?.data?.data?.sharedConversation?.sharedConversationId;

      const title = response?.data?.data?.sharedConversation?.conversation
        ?.conversation
        ? response?.data?.data?.sharedConversation?.conversation?.conversation
        : response?.data?.data?.sharedConversation?.conversation?.chats[0]
          ?.query;

      setPublicLink(`${baseUrl}/share/${sharedConvId}`);
      setPublicLinkTitle(title);
      setActiveStep((prev) => prev + 1);

      if (isMobile) {
        handleSharing(`${baseUrl}/share/${sharedConvId}`, title);
      }
    } else {
      setPublicLinkLoading(() => false);
      toast.error("Something went wrong!");
    }
  };

  const handleUpdatePublicLink = async () => {
    setPublicLinkLoading(() => true);
    let payload = {};

    const response = await UpdateAndCopyConversationLink(
      payload,
      shareChatId,
      shareConvId
    );

    if (response.status === 200) {
      const sharedConvId =
        response?.data?.data?.conversation?.sharedConversationId;

      const title =
        response?.data?.data?.conversation?.conversation?.chats[0]?.query;

      setPublicLink(`${baseUrl}/share/${sharedConvId}`);
      setPublicLinkTitle(title);
      setActiveStep((prev) => prev + 1);

      if (isMobile) {
        handleSharing(`${baseUrl}/share/${sharedConvId}`, title);
      }
    } else {
      setPublicLinkLoading(() => false);
      toast.error("Something went wrong!");
    }
  };

  const handleDeleteSharedLink = async () => {
    const response = await deleteSharedConversationLink(
      shareChatId,
      shareConvId
    );

    if (response.status === 200) {
      // toast.success("")
      setOpens(false);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleClickOpen = () => {
    setDel(true);
    // handleClose()
  };

  const handleDelete = async () => {
    const response = await deleteSharedConversationLink(
      shareChatId,
      shareConvId
    );

    if (response.status === 200) {
      // toast.success("")
      setDel(false);
      handleClose();
    } else {
      toast.error("Something went wrong!");
    }
  };

  const renderQuestion = (item) => {
    return (
      <div className={styles.message}>
        {item?.split("```").map((part, index) => {
          if (index % 2 === 0) {
            return <p key={index}>{part}</p>;
          } else {
            const code = part.trim();
            return (
              <div key={index}>
                <div>
                  <SyntaxHighlighter
                    style={oneLight}
                    customStyle={{ borderRadius: "30px" }}
                  >
                    {code}
                  </SyntaxHighlighter>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <>
      <Modal
        open={open}
        //   onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal_content}>
          <div className={styles.modal_content_top}>
            <p className={styles.heading}>Share Chat</p>

            <span className={styles.close_modal} onClick={handleClose}>
              <IoMdClose size={28} />
            </span>
          </div>

          <div className={styles.modal_content_bottom}>
            {activeStep === 1 ? (
              <>
                {shareChatId && (
                  <div className={styles.shareChat_step1}>
                    {!shareConvId ? (
                      <p className={styles.top_text}>
                        Messages you send after creating your link won't be
                        shared. Anyone with the URL will be able to view the
                        shared chat.
                      </p>
                    ) : (
                      <p className={styles.top_text}>
                        You have shared this chat&nbsp;
                        <Link
                          to={`${baseUrl}/share/${shareConvId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          before
                        </Link>
                        . If you want to update the shared chat content,&nbsp;
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          onClick={handleClickOpen}
                        >
                          delete this link
                        </span>
                        &nbsp;and create a new shared link.
                      </p>
                    )}

                    <div className={styles.chatcopy_sec}>
                      <div className={styles.chat_container}>
                        {spinner ? (
                          <div className={styles.loader_container}>
                            <span>
                              <CircularProgress color="inherit" size={25} />
                            </span>
                          </div>
                        ) : (
                          <div className={styles.mapping_conversations}>
                            <>
                              {array &&
                                array?.map((item, index) => {
                                  const currentAnswerIndex =
                                    currentAnswerIndexMap[index] || 0;

                                  return (
                                    <div key={item?.chatId}>
                                      <div
                                        name="question"
                                        className={styles?.chatMain}
                                      >
                                        <div className={`${styles?.question}`}>
                                          {renderQuestion(item.query)}
                                          <div className={styles.questionImage}>
                                            <img
                                              src={localStorage?.getItem("profilePhoto") || profileIcon}
                                              alt="chatImg" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles?.answer}>
                                        <div className={styles.answerImage}>
                                          <img
                                            src="https://res.cloudinary.com/db4uhziiy/image/upload/v1709710717/logoBlack_jgj2jg.png"
                                            alt="chatImg"
                                          />
                                        </div>

                                        <>
                                          {item.answers.map(
                                            (answer, answerIndex) => (
                                              <React.Fragment key={answerIndex}>
                                                <div
                                                  className={styles.answerText}
                                                >
                                                  {currentAnswerIndex ===
                                                    answerIndex &&
                                                    TypingData(answer.answer)}
                                                </div>
                                              </React.Fragment>
                                            )
                                          )}
                                        </>
                                      </div>

                                      <div className={styles.answerBottom}>
                                        {item.answers.length > 1 && (
                                          <>
                                            <div
                                              className={styles.switchAnswer}
                                            >
                                              <button
                                                style={{
                                                  opacity: currentAnswerIndex
                                                    ? 1
                                                    : 0.6,
                                                }}
                                                className={
                                                  styles.switchAnswerButton
                                                }
                                                onClick={() =>
                                                  handleDecrement(index)
                                                }
                                              >
                                                <MdOutlineKeyboardArrowLeft
                                                  size={20}
                                                />{" "}
                                                Prev
                                              </button>
                                              <span
                                                style={{
                                                  color: "gray",
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {currentAnswerIndex + 1}/
                                                {item?.answers.length}
                                              </span>
                                              <button
                                                style={{
                                                  opacity:
                                                    currentAnswerIndex ===
                                                      item.answers.length - 1
                                                      ? 0.6
                                                      : 1,
                                                }}
                                                className={
                                                  styles.switchAnswerButton
                                                }
                                                onClick={() =>
                                                  handleIncrement(index)
                                                }
                                              >
                                                Next
                                                <MdOutlineKeyboardArrowRight
                                                  size={20}
                                                />
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.buttons_wrapper}>
                      {shareConvId ? (
                        <>
                          <button
                            className={`${styles.update_btn} ${styles.cancel_btn}`}
                            onClick={handleClose}
                          >
                            Cancel
                          </button>

                          <button
                            className={styles.update_btn}
                            onClick={handleUpdatePublicLink}
                          >
                            Update public link
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className={`${styles.update_btn} ${styles.cancel_btn}`}
                            onClick={handleClose}
                          >
                            Cancel
                          </button>

                          <button
                            className={styles.update_btn}
                            onClick={handleCreatePublicLink}
                          >
                            Create public link
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <SocialShareChatModal
                publicLink={publicLink}
                publicLinkTitle={publicLinkTitle}
              />
            )}
          </div>
          {/* {
          <DeleteShareConversationModal />
        } */}
        </div>
      </Modal>
      <DeleteShareConversationModal
        del={del}
        setDel={setDel}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ShareChatModal;
