import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./slices/editorSlice";
import conversationReducer from "./slices/conversationSlice"
import historyReducer from "./slices/historySlice"
import profileReducer from "./slices/profileSlice"
import appReducer from "./slices/appSlice";

const store = configureStore({
  reducer: {
    language: languageReducer,
    conversation: conversationReducer,
    history: historyReducer,
    profile: profileReducer,
    app: appReducer,
  },
});

export default store;
