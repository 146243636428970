import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import chatIcon from "../../assets/svg/chat-item.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePushPin, MdDriveFileRenameOutline } from "react-icons/md";
import { BiShareAlt } from "react-icons/bi";
import { BiArchiveIn } from "react-icons/bi";
import { TbPinnedFilled } from "react-icons/tb";
import {
  archiveChat,
  pinnedChat,
  setConvoId,
} from "../../store/slices/historySlice";
import Tooltip from '@mui/material/Tooltip';
import { setConversationId } from "../../store/slices/conversationSlice";
import { RiDeleteBin5Line } from "react-icons/ri";
import Popover from "@mui/material/Popover";
import DeleteHistoryModal from "../modals/DeleteHistoryModal";
import RenameModal from "../modals/RenameModal";
import { toast } from "react-toastify";
import ShareChatModal from "../modals/ShareChatModal";
import pinIcon from "../../assets/svg/pinWhite.svg";
import unPinIcon from "../../assets/svg/unpinWhite.svg"
import archiveIcon from "../../assets/svg/archiveGrey.svg"
import deleteIcon from "../../assets/svg/deleteRed.svg";
import renameIcon from "../../assets/svg/editGrey.svg"
const ChatHistoryItem = ({ chat, styles, pin }) => {
  const [isChatHovered, setIsChatHovered] = useState("");
  const [menuOpen, setMenuOpen] = useState(null);
  const [del, setDel] = useState(false);
  const [rename, setRename] = useState(false);
  const [shareChat, setShareChat] = useState(null);
  const dispatch = useDispatch();

  const open = Boolean(menuOpen);

  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setMenuOpen(null);
  };

  const handleOpenMenu = (event) => {
    setMenuOpen(event.currentTarget);
    //alert(event.currentTarget);
  };

  const handleShareClick = (id) => {
    setMenuOpen(null);
    setShareChat(id);
  };

  return (
    <div

      key={chat?.conversationId}
      className={styles?.inner_history}
      style={{
        backgroundColor: isChatHovered === chat.conversationId ? "#323232" : "",
        transition: 'all 0.2s ease-in-out'
      }}
      onMouseEnter={() => {
        setMenuOpen(() => null);
        setIsChatHovered(chat.conversationId);
      }}
      onMouseLeave={() => setIsChatHovered("")}
    >
      <Link
        to={`/conversation/${chat?.conversationId}`}
        className={styles.inner_history_left}
        style={{
          textDecoration: "none",
          color: "white",
        }}
      >
        <span style={{ paddingRight: "8px" }}>
          <img src={chatIcon} alt="" />
        </span>

        {chat?.conversation?.length > 15
          ? <Tooltip title={chat?.conversation} placement="bottom"
            PopperProps={{
              sx: {
                '&[data-popper-reference-hidden]': {
                  visibility: 'hidden',
                  'pointer-events': 'none'
                }
              }
            }}


          >{chat?.conversation?.slice(0, 15) + "..."}</Tooltip>
          : <Tooltip title={chat?.conversation} placement="bottom"
            PopperProps={{
              sx: {
                '&[data-popper-reference-hidden]': {
                  visibility: 'hidden',
                  'pointer-events': 'none'
                }
              }
            }}>{chat?.conversation}</Tooltip>}
      </Link>

      {isChatHovered === chat.conversationId && (
        <>
          <span
            className={styles.threeDots}
            style={{ right: "30px" }}
            onClick={() => {
              dispatch(
                pinnedChat({
                  chat: chat,
                  payload: {
                    data: {
                      isPinned: pin,
                    },
                  },
                })
              );
              toast?.success(
                pin ? "Chat Pinned Successfully" : "Chat Unpin Successfully"
              );
            }}
          >
            {pin ? (
              <img src={pinIcon} alt="pin" style={{width: "15px", height: "15px"}}/>
            ) : (
              <img src={unPinIcon} alt="unpin" style={{width: "15px", height: "15px"}}/>
            )}
          </span>

          <span
            className={styles.threeDots}
            aria-describedby={id}
            variant="contained"
            onClick={handleOpenMenu}
          >
            <BsThreeDotsVertical size={16} />
          </span>
        </>
      )}

      {menuOpen && isChatHovered === chat.conversationId ? (
        <Popover
          id={id}
          open={open}
          anchorEl={menuOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <div className="menu_wrapper_content">
            <p
              className="menu_wrapper_text"
              onClick={() => handleShareClick(chat.conversationId)}
            >
              <span className="icon_wrapper">
                <BiShareAlt size={20} color="#9A9A99" />
              </span>
              Share Chat
            </p>
            <p
              className="menu_wrapper_text"
              onClick={() => {
                dispatch(
                  archiveChat({
                    id: chat?.conversationId,
                    payload: {
                      data: {
                        isArchived: true,
                      },
                    },
                  })
                );
                toast.success("Archived Succesfully");
                handleClose();
              }}
            >
              <span className="icon_wrapper">
                {/* <BiArchiveIn size={20} color="#9A9A99" /> */}
                <img src={archiveIcon} alt="arcive" style={{width: "15px", height: "15px"}}/>
              </span>
              Archive Chat
            </p>
            <p
              className="menu_wrapper_text"
              onClick={() => {
                setRename(true);
                handleClose();
              }}
            >
              <span className="icon_wrapper">
                {/* <MdDriveFileRenameOutline size={20} color="#9A9A99" /> */}
                <img src={renameIcon} alt="rename" style={{width: "15px", height: "15px"}}/>
              </span>
              Rename Chat
            </p>
            <p
              className="menu_wrapper_text"
              style={{ color: "#EC0003" }}
              onClick={() => {
                setDel(true);
                handleClose();
              }}
            >
              <span className="icon_wrapper">
                {/* <RiDeleteBin5Line size={20} color="#EC0003" /> */}
                <img src={deleteIcon} alt="delete" style={{width: "15px", height: "15px"}}/>
              </span>
              Delete Chat
            </p>
          </div>
        </Popover>
      ) : null}
      {del && (
        <DeleteHistoryModal
          delOpen={del}
          setDelOpen={setDel}
          conversationId={chat?.conversationId}
          handleClose={handleClose}
        />
      )}
      {rename && (
        <RenameModal
          convo={chat?.conversation}
          open={rename}
          setModalOpen={setRename}
          conversationId={chat?.conversationId}
          handleClose={handleClose}
        />
      )}

      {shareChat && (
        <ShareChatModal
          open={shareChat}
          handleClose={() => setShareChat(false)}
          shareChatId={shareChat}
        />
      )}
    </div>
  );
};

export default ChatHistoryItem;