import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	topNavTitle: '',
};

const getTitle = (pathname) => {
	if (pathname.includes('/setting')) {
		if (pathname.includes('/profile')) {
			return 'Profile';
		} else if (pathname.includes('/account')) {
			return 'Account';
		} else if (pathname.includes('/chat-history')) {
			return 'Chat History';
		} else if (pathname.includes('/feedback')) {
			return 'Feedback';
		} else if (pathname.includes('/shared-links')) {
			return 'Shared Links';
		} else if (pathname.includes('/download-vscode')) {
			return 'Download VS Code';
		} else {
			return '';
		}
	} else {
		return '';
	}
}

const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setTopNavTitle: (state, action) => {
			state.topNavTitle = getTitle(action.payload);
		}
	},
});

export const { setTopNavTitle } = appSlice.actions;

export default appSlice.reducer;
