import React, { useState, useEffect } from "react";
import styles from "../../layouts/homelayout/LeftSidebar.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getHistoryById, setHistoryId } from "../../store/slices/historySlice";
import { useSelector, useDispatch } from "react-redux";
import {
  newChatHandler,
  storeChats,
} from "../../store/slices/conversationSlice";
import settingImg from "../../assets/svg/newsetting.svg";
import upArrow from "../../assets/svg/uparrow.svg";
import FeatureButton from "../../shared/button/FeatureButton";
import generateCode from "../../assets/svg/generate-code.svg";
import optimizeCode from "../../assets/svg/optimize-code.svg";
import debugCode from "../../assets/svg/debug-code.svg";
import documentCode from "../../assets/svg/document-code.svg";
import convertCode from "../../assets/svg/convert-code.svg";
import testCaseCode from "../../assets/svg/test-case-code.svg";
import chatHistoryIcon from "../../assets/svg/chat-history-icon.svg";
import explainCodeIcon from "../../assets/svg/explain-code-new.svg";
import commentsIcon from "../../assets/svg/comments.svg";
import collapse from "../../assets/svg/collapse-toggle.svg";
import ChatHistoryItem from "./ChatHistoryItem";
import FollowUsModal from "../modals/FollowUsModal";
import { MdOutlinePersonPin } from "react-icons/md";
import { LightTooltip } from "../../utils";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Zoom from "@mui/material/Zoom";
import SkeletonLoader from "../../assets/SkeletonLoader";
import logom from "../../assets/svg/logoMain.svg";

import followUp from "../../assets/svg/follow-up.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  setActiveFeature,
  setOpenTranslateModal,
} from "../../store/slices/editorSlice";

const AppSidebar = ({
  settingsSidebar,
  handleGenerateFeature,
  toggleHistory,
  setToggleHistory,
  setToggleSidebar,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { history, historyid, convoId, loading, initialLoading } = useSelector(
    (state) => state.history
  );

  const { activeLanguage } = useSelector((state) => state.language);

  function toTitleCase(str) {
    return str
      ?.toLowerCase()
      ?.replace(/_/g, " ")
      ?.replace(/\b\w/g, (c) => c?.toUpperCase());
  }
  // console.log('activeLanguage', activeLanguage)

  const [activeTab, setActiveTab] = useState("");

  // console.log('activeTab', activeTab)

  const [followUs, setFollowUs] = useState(false);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }));

  useEffect(() => {
    if (historyid && historyid.chats && historyid.chats.length !== 0) {
      dispatch(storeChats(historyid.chats));
    }
  }, [historyid]);

  useEffect(() => {
    if (convoId) {
      dispatch(getHistoryById(convoId));
    }
  }, [convoId]);

  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate("/login");
  // };

  // useEffect(() => {
  //   // console.log('called')
  //   if (activeLanguage.featureType === "GENERATE_TEST_CASES") {
  //     setActiveTab("Write Test Cases");
  //   } else {
  //     setActiveTab(toTitleCase(activeLanguage?.featureType));
  //   }
  // }, [activeLanguage?.featureType]);

  const handleFollowUs = () => {
    setFollowUs(true);
  };

  const handleOpenConvertCode = () => {
    const activeTab = activeLanguage.tabs.find((tab) => tab.isActive);

    if (activeTab?.content !== "") {
      dispatch(setOpenTranslateModal(true));
    }
  };

  const handleNewChat = () => {
    dispatch(newChatHandler());
    dispatch(setHistoryId());
    navigate("/home");
  };

  return (
    <div
      style={{
        transform: !settingsSidebar ? "translateX(0)" : "translateX(-260px)",
        whiteSpace: "nowrap",
      }}
      className={styles.leftsidebar}
    >
      <div className={styles.leftsidebar__top}>
        <div className={styles.logo_wrapper}>
          <div
            onClick={handleNewChat}
            className={styles.left_align}
            // style={{ display: toggleSidebar ? "none" : "block" }}
          >
            <Link
              to="/"
              onClick={() =>
                dispatch(setActiveFeature(activeLanguage?.featureType))
              }
            >
              <img src={logom} alt="qx-logo" className={styles.mainLogoImage} />
            </Link>

            <Link
              to="/"
              onClick={() =>
                dispatch(setActiveFeature(activeLanguage?.featureType))
              }
              className={styles.sidebar_logo}
            >
              <span className={styles.sidebar_logo_text}>Text2Code</span>
            </Link>
            {/* <br />
            <Link to="/" className={styles.plus_plans}>
              <span> Upgrade To Plus </span>
            </Link> */}
          </div>
          <div>
            <LightTooltip
              title="Close Sidebar"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <div
                onClick={() => setToggleSidebar(true)}
                style={{ cursor: "pointer" }}
              >
                <img
                  className={styles.collapse_icon}
                  src={collapse}
                  alt="collapse"
                />
              </div>
            </LightTooltip>
          </div>
        </div>

        {/* <div className={styles.profile_div}>
          <div className={styles.profile_div_image}>
            <img src={profileIcon} alt="" />
          </div>

          <div className={styles.profile_div_username}>
            <span className={styles.firstname}>
              {localStorage.getItem("firstName")}&nbsp;
              {localStorage.getItem("lastName")}
            </span>

            <span className={styles.credits}>
              Credits <b className={styles.credits_bold}> 5000 </b>/4000
            </span>
          </div>
        </div> */}

        <div className={styles.features_wrapper}>
          <div className={styles.button_wrapper}>
            <>
              <>
                {!location.pathname.includes("/home") && (
                  <FeatureButton
                    title="Generate Code"
                    icon={generateCode}
                    fromSettings={false}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    clickHandler={handleNewChat}
                  />
                )}
                <FeatureButton
                  title="Optimize Code"
                  icon={optimizeCode}
                  fromSettings={false}
                  clickHandler={() =>
                    handleGenerateFeature("OPTIMIZE_CODE", true)
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <FeatureButton
                  title="Debug Code"
                  icon={debugCode}
                  fromSettings={false}
                  clickHandler={() => handleGenerateFeature("DEBUG_CODE", true)}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <FeatureButton
                  title="Document Code"
                  icon={documentCode}
                  fromSettings={false}
                  clickHandler={() =>
                    handleGenerateFeature("DOCUMENT_CODE", true)
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                <FeatureButton
                  title="Write Test Cases"
                  icon={testCaseCode}
                  fromSettings={false}
                  clickHandler={() =>
                    handleGenerateFeature("GENERATE_TEST_CASES", true)
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                <FeatureButton
                  title="Convert Code"
                  icon={convertCode}
                  fromSettings={false}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  clickHandler={handleOpenConvertCode}
                />

                <FeatureButton
                  title="Explain Code"
                  icon={explainCodeIcon}
                  fromSettings={false}
                  clickHandler={() =>
                    handleGenerateFeature("EXPLAIN_CODE", true)
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                <FeatureButton
                  title="Add Comments To Code"
                  icon={commentsIcon}
                  fromSettings={false}
                  clickHandler={() =>
                    handleGenerateFeature("ADD_COMMENTS_TO_CODE", true)
                  }
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </>
            </>
          </div>
        </div>
      </div>

      <div className={styles.leftsidebar__bottom}>
        {initialLoading ? (
          <SkeletonLoader skeletonType="ChatHistorySidebar" />
        ) : (
          <div className={styles.chat_history}>
            <div
              className={styles.chat_history_header}
              onClick={() => setToggleHistory(!toggleHistory)}
            >
              <p className={styles.chat_history_text}>
                <span>
                  <img src={chatHistoryIcon} alt="Chat history" />
                </span>
                Chat History
              </p>
              <img
                style={{
                  transform: toggleHistory ? "rotate(180deg)" : "rotate(0)",
                  transition: "all 0.3s ease-in-out",
                }}
                src={upArrow}
                alt="arrow"
              />
            </div>

            <div
              style={{ maxHeight: toggleHistory ? "50vh" : "120px" }}
              className={styles.chat_history_content}
            >
              {history && history.length > 0 ? (
                <>
                  {history?.map((his, index) => {
                    return (
                      <div key={index} className={styles?.history_duration}>
                        <div className={styles.history_dur}>
                          {his?.duration}
                        </div>
                        <div className={styles?.history_name_container}>
                          {his?.conversations
                            ?.filter((fil) => fil?.conversation?.length > 0)
                            ?.map((e) => {
                              return (
                                <React.Fragment key={e?.conversationId}>
                                  <ChatHistoryItem
                                    chat={e}
                                    styles={styles}
                                    pin={
                                      his?.duration === "Pinned" ? false : true
                                    }
                                  />
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <p className={styles.no_chat_history}> No chat history </p>
                </>
              )}
            </div>
          </div>
        )}

        <div className={styles.settings_wrapper}>
          {initialLoading ? (
            Array(4)
              .fill(0)
              .map((item, index) => (
                <div style={{ opacity: "0.5" }} key={index}>
                  <SkeletonTheme baseColor="#9A9A99">
                    <Skeleton
                      enableAnimation={false}
                      height={"30px"}
                      width={"30px"}
                      borderRadius={0}
                    />
                  </SkeletonTheme>
                </div>
              ))
          ) : (
            <>
              <LightTooltip
                title="Settings"
                placement="right-start"
                TransitionComponent={Zoom}
              >
                <div
                  className={styles.settings_wrapper_item}
                  onClick={() => navigate("setting/profile")}
                >
                  <img src={settingImg} alt="setting" />
                </div>
              </LightTooltip>

              <LightTooltip
                title="Follow Us"
                placement="right-start"
                TransitionComponent={Zoom}
              >
                <div
                  className={styles.settings_wrapper_item}
                  onClick={handleFollowUs}
                >
                  <img src={followUp} alt="follow-us" />
                </div>
              </LightTooltip>
            </>
          )}
        </div>
      </div>

      {followUs && (
        <FollowUsModal followUs={followUs} setFollowUs={setFollowUs} />
      )}
    </div>
  );
};

export default AppSidebar;
