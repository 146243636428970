import { Modal } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import styles from "../../pages/settings/chathistory/Chathistory.module.scss";
import { deleteHistoryById } from "../../store/slices/historySlice";
import CROSS from "../../assets/png/cross.png";
import { toast } from "react-toastify";
import { deleteSharedConversationLink, getAllSharedLinks } from "../../services/shareChatService";
const DeleteLinkModal = ({ conversationId, shareConversationId, delOpen, setDelOpen, setDeleteLoading, setNotFound, setSharedLinks }) => {
    const dispatch = useDispatch();
    const getAllConversations = async () => {
        // setIsLoading(true);
        setNotFound(false);
        const response = await getAllSharedLinks();
        if (response.status === 200) {
            if (response?.data?.data === null) {
                setNotFound(true);
                // setIsLoading(false);
                return;
            } else {
                setNotFound(false);
                setSharedLinks(response?.data?.data?.response);
                // setIsLoading(false);
            }
            //   setLoadingApi(false);
        } else {
            //   setIsLoading(false);
            toast.error("Something went wrong!");
        }
    };
    async function handleDelete() {
        // dispatch(deleteHistoryById(conversationId));
        // toast.success("Chat deleted Successfully!");
        const response = await deleteSharedConversationLink(conversationId, shareConversationId);

        if (response.status === 200) {
            setDeleteLoading(shareConversationId);
            getAllConversations();
            toast.success("Link deleted Successfully!")
        } else {
            toast.error("Something went wrong!");
            setDeleteLoading("");
        }
        setDelOpen(false);
    }
    return (
        <>
            <Modal open={delOpen}>
                <div className="w-100 h-100  d-flex justify-content-center align-items-center">
                    <div className={styles?.delModal}>
                        <div className={styles?.delheader}>
                            <p>Delete Shared Links</p>
                            {/* <button onClick={() => setDelOpen(false)}>Cancel</button> */}
                            <img
                                src={CROSS}
                                className={styles?.cross}
                                alt="delete_icon"
                                onClick={() => setDelOpen(false)}
                            />
                        </div>
                        <div className={styles?.innermodal}>
                            <h5 className="text-center">The link will be deleted for all users it has been shared with. Are you sure you want to continue?</h5>
                            <div className={styles?.btngrp}>
                                <button
                                    className={styles?.delbtn2}
                                    onClick={() => setDelOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button className={styles?.delbtn} onClick={handleDelete}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeleteLinkModal;
