import React, { Suspense, memo, useEffect, useState } from "react";
import styles from "./HomeLayout.module.scss";
import { Outlet } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import { useDispatch, useSelector } from "react-redux";
import WelcomeModal from "../../components/modals/WelcomeModal";
import Topbar from "../../components/Topbar/Topbar";
import {
  fetchLanguage,
  setActiveTabContent,
  setActiveTabAnswer,
  setActiveValue,
  setExtension,
  setIsPreview,
  setOpenTranslateModal,
  setPreviewAnswer,
  setFeatureType,
  setActiveLanguage,
  setActiveTabNameNew,
} from "../../store/slices/editorSlice";
import PromptInput from "../../components/conversation/PromptInput";
import TopBar from "../../components/Topbar/Topbar";
import { useLocation, useNavigate } from "react-router-dom";
import ConvertCodeModal from "../../components/modals/ConvertCodeModal";
import { addFeature } from "../../services/editorService";
import { toast } from "react-toastify";
import ProgressModal from "../../components/modals/ProgressModal";
import Onboarding from "../../components/onboarding/Onboarding";
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_LANDSCAPE_WIDTH, MOBILE_VIEW_MAX_WIDTH } from "../../utils";
import TopNav from "../../components/TopNav/TopNav";
import MobileSidebar from "../../components/sidebar/MobileSidebar";
import useMediaQueryLandscape from "../../hooks/useMediaQueryLandscape";
import BottomTabs from "../../components/CodeEditor/BottomTabs/BottomTabs";

const HomeLayout = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const mobileView = useMediaQuery(MOBILE_VIEW_MAX_WIDTH);
  const mobileLandscape = useMediaQueryLandscape(
    `(max-width: ${MOBILE_LANDSCAPE_WIDTH}px) and (orientation: landscape)`
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(false);

  const [showOnboarding, setShowOnboarding] = useState(false);

  const { openTranslateModal, activeLanguage, targetedLanguage, languages } =
    useSelector((state) => state.language);

  let matchLang = activeLanguage?.tabs?.find((tab) => tab?.isActive)?.lang
    ?.langName;
  let matchedLanguage = languages?.find(
    (language) => language?.language === matchLang
  );

  const handleGenerateFeature = async (feature, hasTargetLang) => {
    setProgress(() => true);
    dispatch(setFeatureType(""));
    dispatch(setIsPreview(false));

    if (location.pathname === "/home") {
      setProgress(() => false);
      return;
    }
    if (location.pathname === "/conversation") {
      setProgress(() => false);
      return;
    }

    const getActiveTab = activeLanguage?.tabs.find((item) => item.isActive);
    if (activeLanguage.tabs.length < 1) {
      setProgress(() => false);
      toast.error("No code detected");
      return;
    }

    if (getActiveTab) {
      if (getActiveTab.content === "") {
        setProgress(() => false);
        toast.error("No code detected");
        return;
      } else {
        try {
          const payload = {
            data: {
              feature: feature,
              query: "",
              sourceCode: feature !== "GENERATE_CODE" && getActiveTab.content,
              sourceLanguage: {
                id: matchedLanguage.languageId,
                name: matchedLanguage.language,
              },
              targetLanguage: {
                id: hasTargetLang ? "" : targetedLanguage?.languageId,
                name: hasTargetLang ? "" : targetedLanguage?.language,
              },
              platform: "WEB",
            },
          };

          const response = await addFeature(payload);

          if (response.response) {
            const feature = response?.response?.featureInteraction?.feature;

            if (feature) {
              dispatch(setFeatureType(feature));

              dispatch(
                setActiveTabAnswer({
                  answer: response?.response?.featureInteraction?.answer,
                  feature: feature,
                })
              );

              // dispatch(setActiveTabNameNew({name:feature}))

              dispatch(setIsPreview(true));
            }

            setProgress(() => false);
          }
        } catch (error) {
          setProgress(() => false);
          dispatch(setIsPreview(false));
          console.error("Error occurred:", error);
          toast.error("something went wrong");
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("newUser")) {
      setShowOnboarding(true);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchLanguage());
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
  };
  // const showTopbar = location.pathname !== "/home";
  const showTopbar =
    location.pathname.includes("/conversation") ||
    location.pathname.includes("/code-editor") ||
    location.pathname.includes("/home");

  // const showTopbar = location.pathname !== '/home' && location.pathname !== '/profile';

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
    localStorage.removeItem("newUser");
    setIsModalOpen(true);
  };

  const handleToggleSidebar = (explicitlyHide = false) => {
    if (explicitlyHide) {
      setShowSidebar(false);
      return;
    }
    setShowSidebar((show) => !show);
  };

  useEffect(() => {
    if (!mobileView) {
      if (location.pathname === "/mobile-settings") {
        // if the user explicitly tries to access the
        // mobile-settings path in desktop then redirect
        // to settings profile page
        navigate("/setting/profile");
      }
    }
  }, [mobileView, location]);

  return (
    <div className={mobileView ? styles.layout_mobile : styles.layout}>
      {mobileView && (
        <>
          <TopNav
            open={showSidebar}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div
            style={{
              height: "calc(100% - 80px)",
              position: "fixed",
              zIndex: 100,
              top: "80px",
              left: showSidebar ? "0" : "-100%",
              transition: "left 0.4s ease-in-out",
            }}
          >
            <MobileSidebar
              handleCloseSidebar={handleToggleSidebar}
              handleGenerateFeature={handleGenerateFeature}
            />
          </div>

          {location.pathname === "/code-editor" && (
            <div className={styles.code_editor_bottom_tabs}>
              <BottomTabs handleGenerateFeature={handleGenerateFeature} />
            </div>
          )}
        </>
      )}
      {mobileLandscape && (
        <>
          <TopNav
            open={showSidebar}
            handleToggleSidebar={handleToggleSidebar}
          />
          <div
            style={{
              height: "calc(100% - 80px)",
              position: "fixed",
              zIndex: 100,
              top: "80px",
              left: showSidebar ? "0" : "-100%",
              transition: "left 0.4s ease-in-out",
            }}
          >
            <MobileSidebar
              handleCloseSidebar={handleToggleSidebar}
              handleGenerateFeature={handleGenerateFeature}
            />
          </div>
        </>
      )}

      {!mobileView && !mobileLandscape && (
        <LeftSideBar handleGenerateFeature={handleGenerateFeature} />
      )}

      <div
        className={`${
          mobileView
            ? styles.rightSideContainer_mobile
            : styles.rightSideContainer
        } ${showTopbar ? styles.extra : ""} ${
          mobileView && styles.extra_mobile
        }`}
      >
        {!mobileView && showTopbar && <TopBar />}

        <div
          className={`${
            mobileView ? styles.outletDiv_mobile : styles.outletDiv
          } ${location.pathname === "/code-editor" ? styles.extras : ""} ${
            location.pathname === "/home" ? styles.home_outlet : ""
          } ${
            location.pathname !== "/home" &&
            location.pathname !== "/code-editor"
              ? styles.profile_sec
              : ""
          } ${
            location.pathname === "/mobile-settings"
              ? styles.mobile_settings_sec
              : ""
          }`}
        >
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
      </div>

      {isModalOpen && (
        <WelcomeModal open={isModalOpen} handleClose={handleClose} />
      )}

      {showOnboarding && (
        <Onboarding
          show={showOnboarding}
          handleCloseOnboarding={handleCloseOnboarding}
        />
      )}

      {openTranslateModal && (
        <ConvertCodeModal
          open={openTranslateModal}
          handleClose={() => dispatch(setOpenTranslateModal(false))}
          handleGenerateFeature={handleGenerateFeature}
        />
      )}

      {progress && <ProgressModal />}
    </div>
  );
};

export default memo(HomeLayout);
