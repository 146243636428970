import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import styles from "./ShareChatModal.module.scss";
import socialLinkedIn from "../../assets/svg/social_linkedin.svg";
import socialFacebook from "../../assets/svg/social_facebook.svg";
import socialTwitter from "../../assets/svg/social_twittter.svg";
import socialReddit from "../../assets/svg/social_reddit.svg";
import { CiCircleInfo } from "react-icons/ci";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineContentCopy } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
const SocialShareChatModal = ({ publicLink, publicLinkTitle }) => {
  const [queryCopied, setQueryCopied] = useState({});
  const isMobile = useMediaQuery(743);

  const shorten = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substr(0, maxLength) + "...";
    }
    return text;
  };

  const handleCopyQuery = () => {
    toast.success("Link copied successfully");
  };

  return (
    <>
      <div className={styles.shareChat_step2}>
        <div className={styles.link_text_box}>
          <a
            href={publicLink}
            target="_blank"
            rel="noreferrer"
            className={styles.share_public_link}
          >
            {/* {shorten(publicLink, 40)} */}
            {publicLink}
          </a>
          <CopyToClipboard
            options={{ format: "text/plain" }}
            text={publicLink}
            onCopy={handleCopyQuery}
          >
            <span
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Copy public link"
              className={styles.copy_icon_wrapper}
            >
              <MdOutlineContentCopy color={"white"} size={20} />
            </span>
          </CopyToClipboard>
        </div>
        <div className={styles.link_message}>
          <span
            style={{
              cursor: "default",
              display: "flex",
              alignItems: "center",
              paddingTop: "2px",
            }}
          >
            <CiCircleInfo />
          </span>
          <p>
            If you share with a third-party service, its privacy policies &
            terms apply.
          </p>
        </div>
        <div className={styles.social_share_bottom}>
          {isMobile ? null : (
            <div className={styles.social_link_wrapper}>
              <div className={styles.social_link_item}>
                <a
                  className={styles.social_link_linkedin}
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${publicLink}&title=${shorten(
                    publicLinkTitle,
                    50
                  )}&summary=${shorten(publicLinkTitle, 60)}&source=`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={socialLinkedIn} alt="Share to LinkedIn" />
                </a>
                <span className={styles.social_link_title}>LinkedIn</span>
              </div>

              <div className={styles.social_link_item}>
                <a
                  className="social_link_linkedin"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${publicLink}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={socialFacebook} alt="Share to Facebook" />
                </a>
                <span className={styles.social_link_title}>Facebook </span>
              </div>

              <div className={styles.social_link_item}>
                <a
                  className="social_link_linkedin"
                  href={`https://twitter.com/intent/tweet?url=${publicLink}&text=${shorten(
                    publicLinkTitle,
                    50
                  )}`}
                  title="Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={socialTwitter} alt="Share to X" />
                </a>
                <span className={styles.social_link_title}>X</span>
              </div>

              <div className={styles.social_link_item}>
                <a
                  className="social_link_linkedin"
                  href={`http://www.reddit.com/submit?url=${publicLink}&title=${publicLinkTitle}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={socialReddit} alt="Share to Reddit" />
                </a>
                <span className={styles.social_link_title}>Reddit</span>
              </div>
            </div>
          )}
        </div>
        {!isMobile && (
          <Tooltip
            id="my-tooltip"
            place="bottom"
            style={{ fontSize: "12px", backgroundColor: "#000" }}
            render={({ content }) => <span>{content}</span>}
          />
        )}
      </div>
    </>
  );
};

export default SocialShareChatModal;
