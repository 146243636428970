import React, { useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import styles from "./Password.module.scss";
import ErrorText from "../errortext/ErrorText";
import { ReactComponent as Eye } from "../../../assets/svg/eye.svg";
import { ReactComponent as EyeSlash } from "../../../assets/svg/eye-slash.svg";
import password from '../../../assets/svg/password.svg'
// import TextError from '../shared/TextError'
// import { TextField } from '@mui/material'

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const { label, name, icon,setShowError, placeholder, isRequired, ...rest } = props;

  const { errors, touched } = useFormikContext();

  const hasError = touched[name] && errors[name];

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleInputChange = (e) => {
      setShowError(false);
  };
  return (
    <div className={styles.inputControl}>
      {/* <label className={styles.label} htmlFor={name}>
        {label}{" "}
        {isRequired ? <span className={styles.requiredMark}>*</span> : ""}
      </label> */}
      <Field name={name} {...rest}>
        {({ field }) => {
          return (
            <>
              <input
                {...field}
                id={name}
                className={`${styles.inputField} ${hasError ? styles.error : ""}`}
                placeholder={placeholder}
                type={showPassword ? "text" : "password"}
                onChange={(e) => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
              {showPassword ? (
                <Eye onClick={toggleShowPassword} className={styles.eyeIcon} />
               
              ) : (
                <EyeSlash
                onClick={toggleShowPassword}
                className={styles.eyeIcon}
              />
              )}
            </>
          );
        }}
      </Field>
      <img src={password} alt="password" />
      <ErrorMessage name={name} component={ErrorText} />
    </div>
  );
};

export default Password;
