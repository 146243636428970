import React, { useEffect } from "react";
import { useLocation } from "react-router";
// import Loader from "../loader/loader";

const Linkedin = () => {
    const location = useLocation();

    useEffect(() => {
        const urlString = location?.search;
        const urlParams = new URLSearchParams(urlString);

        // Get the values of code and state
        const code = urlParams.get("code");
        const state = urlParams.get("state");
        // dispatch(setLinkedin({ code, state }))
        window.opener.postMessage({ code, state }, "*");

        window.close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {/* <Loader /> */}
            loader....
        </div>
    );
};

export default Linkedin;
