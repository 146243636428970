import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { archiveConversation, conversationHistory, conversationHistoryById, deleteConversationById, getArchiveConversation, pinnedConversation, renameConversation } from "../../services/coversationService";



const initialState = {
    history: [],
    historyid: {},
    convoId: null,
    error: null,
    loading: false,
    initialLoading: false,
    archive: [],
    tab: "Chat History"
};

const historySlice = createSlice({
    name: "history",
    initialState,
    reducers: {
        setHistory: (state, action) => {
            state.history = []
        },
        setHistoryId: (state, action) => {
            state.historyid = {}
        },
        setConvoId: (state, action) => {
            state.convoId = action.payload
        },
        setTabChange: (state, action) => {
            state.tab = action.payload
        },
        addNewDataToToday: (state, action) => {
            const newData = action?.payload;
            const todayIndex = state?.history?.findIndex(
                (item) => item?.duration === "Today"
            );
            const pinnedIndex = state?.history?.findIndex(
                (item) => item?.duration === "Pinned"
            );
            if (Array.isArray(state?.history) && state.history.length > 0) {
                if (todayIndex !== -1) {
                    state?.history[todayIndex]?.conversations?.unshift(newData);
                } else {
                    if (pinnedIndex !== -1) {
                        state.history?.splice(pinnedIndex + 1, 0, {
                            duration: "Today",
                            conversations: [newData],
                        });
                    } else {
                        state.history?.unshift({
                            duration: "Today",
                            conversations: [newData],
                        });
                    }
                }
            } else {
                // If there are no conversations at all, add new data with 'Today' duration
                state.history = [{ duration: "Today", conversations: [newData] }];
            }
        },
        clearHistory: (state, action) => {
            state.archive = []
            state.convoId = null
            state.error = null
            state.history = []
            state.historyid = {}
            state.loading = false
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(gethistory.pending, (state) => {
                console.log('we are in get history herer')
                state.loading = true;

                state.initialLoading = true; // Set initialLoading to true only if history is empty

                state.error = null

            })
            .addCase(gethistory.fulfilled, (state, action) => {
                state.loading = false;
                if (state.initialLoading) {
                    state.initialLoading = false; // Set initialLoading to false after the first initial API call
                }
                state.history = action.payload;
                state.error = null

            })
            .addCase(gethistory.rejected, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                state.error = action.error.message;
            })
            .addCase(getHistoryById.pending, (state, action) => {
                state.loading = true;
                state.error = null

            }).addCase(getHistoryById.fulfilled, (state, action) => {
                state.loading = false;
                state.historyid = action.payload;
                state.error = null

            })
            .addCase(getHistoryById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(deleteHistoryById.pending, (state, action) => {
                state.loading = true;
                state.error = null

            })
            // .addCase(deleteHistoryById.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.archive = state.archive.map((arc) => {
            //         if (arc.conversationId === action.payload) {
            //             return undefined;
            //         }
            //         return arc;
            //     }).filter(Boolean);
            //     state.history = state.history.map((conversation) => {
            //         if (conversation.duration === "Pinned") {
            //             const updatedConversations = conversation.conversations.filter(convo => convo.conversationId !== action.payload);

            //             // If the conversations array becomes empty, remove the pinned conversation
            //             if (updatedConversations.length === 0) {
            //                 return undefined;
            //             }

            //             return {
            //                 ...conversation,
            //                 conversations: updatedConversations
            //             };
            //         }
            //         return conversation;
            //     }).filter(Boolean);
            //     state.history = state.history.map((conversation) => {
            //         return {
            //             ...conversation,
            //             conversations: conversation.conversations.map((convo) => {
            //                 if (convo.conversationId === action.payload) {
            //                     // Remove the conversation from the inner conversations array
            //                     return undefined;
            //                 }
            //                 return convo;
            //             }).filter(Boolean) // Filter out the undefined values
            //         };
            //     });
            //     state.error = null

            // })
            .addCase(deleteHistoryById.fulfilled, (state, action) => {
                state.loading = false;
                state.archive = state.archive.map((arc) => {
                    if (arc.conversationId === action.payload) {
                        return undefined;
                    }
                    return arc;
                }).filter(Boolean);
                // state.history = state.history.map((conversation) => {
                //     if (conversation.duration === "Pinned") {
                //         const updatedConversations = conversation.conversations.filter(convo => convo.conversationId !== action.payload);

                //         // If the conversations array becomes empty, remove the pinned conversation
                //         if (updatedConversations.length === 0) {
                //             return undefined;
                //         }

                //         return {
                //             ...conversation,
                //             conversations: updatedConversations
                //         };
                //     }
                //     return conversation;
                // }).filter(Boolean);
                // state.history = state.history.map((conversation) => {
                //     return {
                //         ...conversation,
                //         conversations: conversation.conversations.map((convo) => {
                //             if (convo.conversationId === action.payload) {
                //                 // Remove the conversation from the inner conversations array
                //                 return undefined;
                //             }
                //             return convo;
                //         }).filter(Boolean) // Filter out the undefined values
                //     };
                // });
                state.history = state.history.map(conversation => {
                    // Check if the conversation duration is "Pinned"
                    if (conversation.duration === "Pinned") {
                        // Filter out the deleted conversation from the conversations array
                        const updatedConversations = conversation.conversations.filter(convo => convo.conversationId !== action.payload);

                        // If there are no conversations left, mark the entire conversation as undefined
                        if (updatedConversations.length === 0) {
                            return undefined;
                        }

                        // Otherwise, return the conversation with updated conversations array
                        return {
                            ...conversation,
                            conversations: updatedConversations
                        };
                    } else {
                        // For other durations, filter out the deleted conversation from conversations array
                        const updatedConversations = conversation.conversations.filter(convo => convo.conversationId !== action.payload);

                        // If there are no conversations left, mark the entire conversation as undefined
                        if (updatedConversations.length === 0) {
                            return undefined;
                        }

                        // Otherwise, return the conversation with updated conversations array
                        return {
                            ...conversation,
                            conversations: updatedConversations
                        };
                    }
                }).filter(Boolean);
                state.error = null

            })
            .addCase(deleteHistoryById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(renameChat.pending, (state, action) => {
                state.loading = true;
                state.error = null

            }).addCase(renameChat.fulfilled, (state, action) => {
                state.loading = false;
                state.initialLoading = false; // Set initialLoading to false after the first initial API call

                state.error = null
            })
            .addCase(renameChat.rejected, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                state.error = action.error.message;
            }).addCase(getarchive.pending, (state, action) => {
                state.loading = true;
                state.error = null

            }).addCase(getarchive.fulfilled, (state, action) => {
                state.loading = false;
                state.archive = action.payload;
                state.error = null
            })
            .addCase(getarchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }).addCase(archiveChat.pending, (state, action) => {
                state.loading = true;
                state.error = null

            }).addCase(archiveChat.fulfilled, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                // state.archive = state.archive.map((arc) => {
                //     if (arc.conversationId === action.payload) {
                //         return undefined;
                //     }
                //     return arc;
                // }).filter(Boolean);

                // Filter out the archived conversation from the archive array
                state.archive = state.archive.filter(arc => arc.conversationId !== action.payload);

                // Find the conversation to archive from the history array
                const conversationToArchive = state.history.find(conversation => conversation.conversations.some(convo => convo.conversationId === action.payload));

                if (conversationToArchive) {
                    // Add the conversation to the archive array
                    state.archive.push(conversationToArchive.conversations.find(convo => convo.conversationId === action.payload));

                    // Remove the archived conversation from the history array
                    conversationToArchive.conversations = conversationToArchive.conversations.filter(convo => convo.conversationId !== action.payload);

                    // If the conversations array becomes empty after archiving, remove the conversation
                    if (conversationToArchive.conversations.length === 0) {
                        state.history = state.history.filter(conversation => conversation !== conversationToArchive);
                    }
                }
                ///////
                state.history = state.history.map((conversation) => {
                    const updatedConversations = conversation.conversations
                        .map((convo) => {
                            if (convo.conversationId === action.payload) {
                                return undefined;
                            }
                            return convo;
                        })
                        .filter(Boolean);

                    // Check if there are conversations remaining
                    const duration = updatedConversations.length > 0 ? conversation.duration : null;

                    return {
                        ...conversation,
                        conversations: updatedConversations,
                        duration: duration // Set duration based on remaining conversations
                    };
                });
                state.error = null
            })
            .addCase(archiveChat.rejected, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                state.error = action.error.message;
            }).addCase(pinnedChat.pending, (state, action) => {
                state.loading = true;
                state.error = null

            })
            // .addCase(pinnedChat.fulfilled, (state, action) => {
            //     state.loading = false;

            //     const newChat = action.payload;

            //     // Find the index of the pinned conversation
            //     const pinnedIndex = state.history.findIndex(conversation => conversation.duration === "Pinned");

            //     // Remove the pinned conversation from other duration categories if it exists
            //     state.history.forEach((conversation, index) => {
            //         if (index !== pinnedIndex && conversation.conversations.some(chat => chat.conversationId === newChat.conversationId)) {
            //             conversation.conversations = conversation.conversations.filter(chat => chat.conversationId !== newChat.conversationId);
            //             // If the duration becomes empty, remove the conversation from the array
            //             if (conversation.conversations.length === 0) {
            //                 state.history.splice(index, 1);
            //             }
            //         }
            //     });

            //     // If a pinned conversation exists, push the new chat into its conversations array
            //     if (pinnedIndex !== -1) {
            //         state.history[pinnedIndex].conversations.push(newChat);
            //     } else {
            //         // If no pinned conversation exists, create a new one with the new chat and add it to the beginning of the array
            //         state.history.unshift({
            //             duration: "Pinned",
            //             conversations: [newChat],
            //             priority: 0 // You may need to adjust the priority value as needed
            //         });
            //     }
            //     state.error = null
            // })

            // .addCase(pinnedChat.fulfilled, (state, action) => {
            //     state.loading = false;
            //     const newChat = action.payload;

            //     // Find the index of the pinned conversation
            //     const pinnedIndex = state.history.findIndex(conversation => conversation.duration === "Pinned");

            //     // If a pinned conversation exists, push the new chat into its conversations array
            //     if (pinnedIndex !== -1) {
            //         const pinnedConversation = state.history[pinnedIndex];
            //         // Check if the conversation is already pinned
            //         const conversationIndex = pinnedConversation.conversations.findIndex(conversation => conversation.conversationId === newChat.conversationId);
            //         // If the conversation is already pinned, remove it
            //         if (conversationIndex !== -1) {
            //             pinnedConversation.conversations.splice(conversationIndex, 1);
            //             // If there are no more conversations in the pinned section, remove the pinned section itself
            //             if (pinnedConversation.conversations.length === 0) {
            //                 state.history.splice(pinnedIndex, 1);
            //             }
            //         } else {
            //             // If the conversation is not already pinned, add it to the pinned section
            //             pinnedConversation.conversations.push(newChat);
            //         }
            //     } else {
            //         // If no pinned conversation exists, create a new one with the new chat and add it to the beginning of the array
            //         state.history.unshift({
            //             duration: "Pinned",
            //             conversations: [newChat],
            //             priority: 0 // You may need to adjust the priority value as needed
            //         });
            //     }

            //     state.error = null;
            // })
            /////  
            .addCase(pinnedChat.fulfilled, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                const newChat = action.payload;

                // Find the index of the pinned conversation
                const pinnedIndex = state.history.findIndex(conversation => conversation.duration === "Pinned");

                // Remove the conversation from other duration categories
                state.history.forEach((conversation, index) => {
                    if (index !== pinnedIndex && conversation.conversations.some(chat => chat.conversationId === newChat.conversationId)) {
                        conversation.conversations = conversation.conversations.filter(chat => chat.conversationId !== newChat.conversationId);
                        // If the duration becomes empty, remove the conversation from the array
                        if (conversation.conversations.length === 0) {
                            state.history.splice(index, 1);
                        }
                    }
                });

                // If a pinned conversation exists, push the new chat into its conversations array
                if (pinnedIndex !== -1) {
                    const pinnedConversation = state.history[pinnedIndex];
                    const conversationIndex = pinnedConversation.conversations.findIndex(conversation => conversation.conversationId === newChat.conversationId);
                    // If the conversation is already pinned, remove it
                    if (conversationIndex !== -1) {
                        pinnedConversation.conversations.splice(conversationIndex, 1);
                    }
                    // Add the new chat to the pinned section
                    pinnedConversation.conversations.push(newChat);
                } else {
                    // If no pinned conversation exists, create a new one with the new chat and add it to the beginning of the array
                    state.history.unshift({
                        duration: "Pinned",
                        conversations: [newChat],
                        priority: 0 // You may need to adjust the priority value as needed
                    });
                }

                state.error = null;
            })


            .addCase(pinnedChat.rejected, (state, action) => {
                state.loading = false;
                state.initialLoading = false;
                state.error = action.error.message;
            })
    },
});
export const gethistory = createAsyncThunk(
    'history/get',
    async (thunkAPI) => {
        const data = await conversationHistory()
        return data?.conversations

    }
)
export const getHistoryById = createAsyncThunk(
    'history/get/id',
    async (thunkAPI) => {
        const data = await conversationHistoryById(thunkAPI)
        return data?.conversation

    }
)
export const deleteHistoryById = createAsyncThunk(
    'conversation/delete/id',
    async (id, thunkAPI) => {
        const data = await deleteConversationById(id)
        // thunkAPI.dispatch(gethistory());r
        return id

        // return data?.conversation

    }
)
export const renameChat = createAsyncThunk(
    'rename/patch/id',
    async (datas, thunkAPI) => {
        const { id, payload } = datas
        const data = await renameConversation(id, payload)
        thunkAPI.dispatch(gethistory());

        // return data?.conversation

    }
)
export const getarchive = createAsyncThunk(
    'archive/get',
    async (thunkAPI) => {
        const data = await getArchiveConversation()
        return data?.conversations
    }
)
export const archiveChat = createAsyncThunk(
    'archive/put/id',
    async (datas, thunkAPI) => {
        const { id, payload } = datas
        const data = await archiveConversation(id, payload)
        if (!payload.data.isArchived) {
            thunkAPI.dispatch(gethistory());
        }
        return id

        // return data?.conversation

    }
)
export const pinnedChat = createAsyncThunk(
    'pin/post/id',
    async (datas, thunkAPI) => {
        const { chat, payload } = datas
        const data = await pinnedConversation(chat.conversationId, payload)
        if (!payload?.data?.isPinned) {
            thunkAPI.dispatch(gethistory());
        }
        return chat

        // return data?.conversation

    }
)



export const { addNewDataToToday, setConvoId, setTabChange, setHistory, clearHistory, setHistoryId } = historySlice.actions;

export default historySlice.reducer;