import React from "react";
import styles from "../../pages/settings/chathistory/Chathistory.module.scss";
import Modal from "@mui/material/Modal";
import CROSS from "../../assets/png/cross.png";

const DeleteShareConversationModal = ({ del, setDel, handleDelete }) => {
  return (
    <>
      <Modal open={del}>
        <div className="w-100 h-100  d-flex justify-content-center align-items-center">
          <div className={styles?.delModal}>
            <div className={styles?.delheader}>
              <p>Delete Share Chat Link</p>
              <img
                src={CROSS}
                className={styles?.cross}
                alt="delete_icon"
                onClick={() => setDel(false)}
              />
            </div>
            <div className={styles?.innermodal}>
              <h5>Are you sure you want to delete link?</h5>
              <div className={styles?.btngrp}>
                <button
                  className={styles?.delbtn2}
                  onClick={() => setDel(false)}
                >
                  Cancel
                </button>
                <button className={styles?.delbtn} onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteShareConversationModal;
