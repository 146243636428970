import FeatureButton from "../../../shared/button/FeatureButton";
import styles from "./BottomTabs.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newChatHandler } from "../../../store/slices/conversationSlice";
import { setHistoryId } from "../../../store/slices/historySlice";
import generateCode from "../../../assets/svg/generate-code.svg";
import optimizeCode from "../../../assets/svg/optimize-code.svg";
import debugCode from "../../../assets/svg/debug-code.svg";
import documentCode from "../../../assets/svg/document-code.svg";
import convertCode from "../../../assets/svg/convert-code.svg";
import testCaseCode from "../../../assets/svg/test-case-code.svg";
import explainCodeIcon from "../../../assets/svg/explain-code-new.svg";
import commentsIcon from "../../../assets/svg/comments.svg";
import { setOpenTranslateModal } from "../../../store/slices/editorSlice";

const BottomTabs = ({ handleGenerateFeature }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeLanguage } = useSelector((state) => state.language);

  const handleNewChat = () => {
    dispatch(newChatHandler());
    dispatch(setHistoryId());
    navigate("/home");
  };

  const handleOpenConvertCode = () => {
    const activeTab = activeLanguage.tabs.find((tab) => tab.isActive);

    if (activeTab?.content !== "") {
      dispatch(setOpenTranslateModal(true));
    }
  };

  return (
    <div className={styles.bottom_tabs}>
      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Generate Code"
          icon={generateCode}
          fromSettings={false}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
          clickHandler={handleNewChat}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Optimize Code"
          icon={optimizeCode}
          fromSettings={false}
          clickHandler={() => handleGenerateFeature("OPTIMIZE_CODE", true)}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Debug Code"
          icon={debugCode}
          fromSettings={false}
          clickHandler={() => handleGenerateFeature("DEBUG_CODE", true)}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Document Code"
          icon={documentCode}
          fromSettings={false}
          clickHandler={() => handleGenerateFeature("DOCUMENT_CODE", true)}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Write Test Cases"
          icon={testCaseCode}
          fromSettings={false}
          clickHandler={() =>
            handleGenerateFeature("GENERATE_TEST_CASES", true)
          }
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Convert Code"
          icon={convertCode}
          fromSettings={false}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
          clickHandler={handleOpenConvertCode}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Explain Code"
          icon={explainCodeIcon}
          fromSettings={false}
          clickHandler={() => handleGenerateFeature("EXPLAIN_CODE", true)}
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>

      <div className={styles.bottom_tabs_item}>
        <FeatureButton
          title="Add Comments To Code"
          icon={commentsIcon}
          fromSettings={false}
          clickHandler={() =>
            handleGenerateFeature("ADD_COMMENTS_TO_CODE", true)
          }
          // activeTab={activeTab}
          // setActiveTab={setActiveTab}
        />
      </div>
    </div>
  );
};

export default BottomTabs;
